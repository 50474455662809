import React from 'react'
import LeftBar from './LeftBar'
// import CentreBar from './CentreBar'
import RightSideBar from './RightSideBar'
import newicon from './img/newicon.gif'
import { useNavigate, Link } from 'react-router-dom'
import Header from './Header'
import Marque from './Marque'
import Footer from '../Registration/Footer'
function Syllabous() {
    const navigate = useNavigate()
    return (
        <div>
            <Header />
            <Marque />
            <section className="contentsection mt-2" id="skipcontent">
                <div className="container-fluid">
                    <div className="" style={{width:'100%',margin:'auto'}}>

                        {/* <!-- LEFT SIDEBAR START HERE --> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <LeftBar />
                        </div> */}
                        {/* </div> <!-- LEFT SIDEBAR END HERE --> */}
                        <>
                            <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12" style={{margin:'auto'}}>
                                <div className="mb-4">
                                    <div className="mb-4 pagecontentpara">
                                    <h5 className="pageheading" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'black',textAlign:'center' }}>AICEE - 2025 Syllabus</h5>
                                        <div className="list-group " >
                                            <div>
                                            <a
                                                href='https://aicee.s3.ap-south-1.amazonaws.com/Eligibility+Criteria+for+Engineering.pdf'
                                                target="_blank"
                                                className="list-group-item list-group-item-action flex items-center"
                                            >
                                                <span className='flex'><span className='text-black font-bold'><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Syllabus for AICEE 2025 Engineering (BE / B-Tech) Program </span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            </div>
                                            <a
                                                href='https://aicee.s3.ap-south-1.amazonaws.com/Eligibility+Criteria+for+12th+Level+PCM+Programs.pdf'
                                                target="_blank"
                                                className="list-group-item list-group-item-action"
                                            >
                                               <span className='flex'><span className='text-black font-bold'><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Syllabus for AICEE 2025 PCM (Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology ) , B.Pharmacy , Agriculture ( Bsc. Agriculture / B-Tech Agriculture ) , Bsc Datascience) Program</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            <a
                                                href='https://aicee.s3.ap-south-1.amazonaws.com/Eligibility+Criteria+for+12th+Level+PCB+Programs.pdf'
                                                target="_blank"
                                                className="list-group-item list-group-item-action"
                                            >
                                                <span className='flex'><span className='text-black font-bold'><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Syllabus for AICEE 2025 PCB (B.Pharmacy , Bsc.Nursing , Bsc.Agriculture , Bio-Tech ) Program</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            <a
                                                href='https://aicee.s3.ap-south-1.amazonaws.com/Eligibility+Criteria+Other+12th+level++other+programs.pdf'
                                                target="_blank"
                                                className="flex list-group-item"
                                            >
                                                <span className='flex'><span className='text-black font-bold'><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Syllabus for AICEE 2025 Other (BBA , BCA , B.Design , LLB (BA / B.Com / B.sc / BBA / BCA) , Mass Communication & Journalism) Program</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            <a
                                                href='https://aicee.s3.ap-south-1.amazonaws.com/Eligibility_Criteria_for_MBA.pdf'
                                                target="_blank"
                                                className="flex list-group-item"
                                            >
                                                <span className='flex'><span className='text-black font-bold'><i class="fas fa-file-pdf text-red-600 text-xl"></i>&nbsp;Syllabus for AICEE 2025 MBA / PGDM Programs</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* INTRO SECTION END HERE */}
                        </>



                        {/* <!-- RIGHT SIDEBAR START HERE --> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <RightSideBar />
                        </div> */}
                        {/* <!-- RIGHT SIDEBAR END HERE --> */}

                    </div>
                </div>
            </section>

            {/* <Footer /> */}
        </div>
    )
}

export default Syllabous

