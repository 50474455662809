import React from 'react'
import Contacts from '../Pages/Dashboard/Contact';
import SideBar from '../Pages/Dashboard/SideBar';
import UpperNavbar from '../Pages/Dashboard/UpperNavbar';
import '../Pages/Dashboard/Dashboard.css'
import { MDBContainer, MDBCard, MDBRow, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
function Contact() {
  return (
    <div className='paymentPage'>
    <div style={{  height: '100vh', overflow: 'scroll initial', width: '100%' }}>
        <div>
        <UpperNavbar />
            
        </div>
        <div className='flex' style={{ height: '90vh' }}>
        <SideBar />
            <div className='mt-2' style={{ backgroundColor: '#f1f3fa', width: '100%', height: '90vh', overflow: 'scroll' }}>
                       
                <Contacts/>
            </div>
        </div>
    </div>
</div>
  )
}

export default Contact