import React from 'react'
import HeaderImg from '../../Images/header.png'
function Header() {
    return (
        <div style={{width:'100%'}} className='bg-white'>
            <div className='RegistrationCard50 border-none grid sm:flex items-center' style={{backgroundColor:'#04124f',padding:'0',width:'100%',borderRadius:'none',borderTopLeftRadius:'none'}}>
                <center className='w-[100px] sm:w-[90px] m-auto'><img className='fluid p-2 ' src='./logo192.png' /></center>
                <div className='RegistrationCard5 text-white pl-[1px] pr-[1px]'><p className='text-white text-[20px] '>ALL india combined Entrance examination - 2025</p>
                    
                </div>
            </div>
            {/* <img className='fluid' src={HeaderImg} alt="" /> */}
        </div>
    )
}

export default Header