import React, { useState } from 'react'

function Preview({ data }) {
  const [studentData, setStudentData] = useState(data)
  const [optSmModal, setOptSmModal] = useState(true);
  const toggleShow = () => setOptSmModal(!optSmModal);
  return (
    <div>
      <>
        <h4 className=' font-bold text-center md-pt-4 pb-4' style={{ color: '#0e2e2e' }}>
          ALL INDIA COMBINED ENTRANCE EXAMINATION - 2023
        </h4>
        <div style={{ backgroundColor: '#0e2e2e' }} className='text-white pl-4 pt-2 pb-2 rounded-md'>1. Applicant Details &nbsp;  <i className='fas fa-user-edit'></i></div>
        <form className="row g-3 text-black mt-2" disabled style={{ fontWeight: 'bold', color: 'black' }} >
          <div className="col-md-6 text-black">
            <label for="inputEmail4" className="form-label text-black font-bold">Name Of Candidate</label><span className='text-red-600 text-xl'>*</span>
            <input disabled style={{ outline: 'none' }} type="text" value={studentData.studentName} className="form-control shadow-none text-black cursor-no-drop" id="inputEmail4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black">Mobile No</label><span className='text-red-600 text-xl'>*</span>
            <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" value={studentData.mobileNo} id="inputPassword4" />
          </div>
          <div className="col-6">
            <label for="inputAddress" className="form-label text-black">Email ID</label><span className='text-red-600 text-xl'>*</span>
            <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress" value={studentData.emailId} placeholder="1234 Main St" />
          </div>
          <div className="col-6">
            <label for="inputAddress2" className="form-label text-black">Gender</label><span className='text-red-600 text-xl'>*</span>
            <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress2" value={studentData.gender} placeholder="Apartment, studio, or floor" />
          </div>
        </form>
        <div style={{ backgroundColor: '#0e2e2e' }} className='text-white pl-4 pt-2 pb-2 rounded-md mt-5'>2. Personal Details &nbsp;  <i className='fas fa-user-alt'></i></div>
        <form className="row g-3 shadow-none mt-2 " disabled>
          <div className="col-md-6 ">
            <label for="inputEmail4" className="form-label text-black text-bold font-bold">DOB</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled style={{ outline: 'none' }} type="date" value={studentData.dob} className=" cursor-not-allowed shadow-none form-control text-black" id="inputEmail4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Father's Name</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled type="text" className=" cursor-not-allowed form-control text-black  shadow-none" value={studentData.guardianName} id="inputPassword4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Mother's Name</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled type="text" className=" cursor-not-allowed form-control text-black  shadow-none" value={studentData.motherName} id="inputPassword4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Guardian's Phone Number</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled type="text" className=" cursor-not-allowed form-control text-black  shadow-none" value={studentData.guardianNumber} id="inputPassword4" />
          </div>
          <div className="col-md-4">
            <label for="inputCity" className="form-label text-black text-bold font-bold">Alternate Phone number</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled type="number" className=" cursor-not-allowed form-control text-black shadow-none" value={studentData.alternateNo} id="inputCity" />
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Category</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select disabled id="inputState" className=" cursor-not-allowed form-select text-black shadow-none" value={studentData.category} >
              <option value='' selected>--Select--</option>
              <option value='SC'>SC</option>
              <option value='ST'>ST</option>
              <option value='OBC'>OBC</option>
              <option value='General'>General</option>
            </select>
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Religion</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select disabled id="inputState" className=" cursor-not-allowed form-select text-black shadow-none" value={studentData.religion} >
              <option value='' selected>--Select--</option>
              <option value='Hindu'>Hindu</option>
              <option value='Muslim'>Muslim</option>
              <option value='Sikh'>Sikh</option>
              <option value='Any Other'>Any Other</option>
            </select>
          </div>

          <div className="col-md-5">
            <label for="inputState" className="form-label text-black text-bold font-bold">Whether belong to economically weaker section <span className='text-red-600 text-xl font-bold'>*</span></label>
            <select disabled id="inputState" className=" cursor-not-allowed form-select text-black shadow-none" value={studentData.ews} >
              <option value='' selected>--Select--</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
            </select>
          </div>
        </form>
        <div style={{ backgroundColor: '#0e2e2e' }} className='text-white pl-4 pt-2 pb-2 rounded-md mt-4'>3. Address Details &nbsp;  <i className='fas fa-map-marker-alt'></i></div>
        <form className="row g-3 mt-1">
          <div className="col-md-12 ">
            <label for="inputEmail4" className="form-label text-black font-bold">Address Line 1</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input style={{ outline: 'none' }} type="text" value={studentData.addressLine1} disabled className=" cursor-not-allowed form-control shadow-none text-black" id="inputEmail4" />
          </div>
          <div className="col-md-12">
            <label for="inputPassword4" className="form-label text-black font-bold">Address Line 2</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" disabled className=" cursor-not-allowed form-control shadow-none text-black" value={studentData.addressLine2} id="inputPassword4" />
          </div>
          <div className="col-md-4">
            <label for="inputCity" className="form-label text-black font-bold">Country</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" disabled className=" cursor-not-allowed form-control shadow-none text-black" value={studentData.country} id="inputCity" />
          </div>
          <div className="col-md-4">
            <label for="inputCity" className="form-label text-black font-bold">City</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" disabled className=" cursor-not-allowed form-control shadow-none text-black" value={studentData.city} id="inputCity" />
          </div>
          <div className="col-md-4">
            <label for="inputCity" className="form-label text-black font-bold">State</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" disabled className=" cursor-not-allowed form-control shadow-none text-black" value={studentData.state} id="inputCity" />
          </div>
          <div className="col-md-4">
            <label for="inputCity" className="form-label text-black font-bold">Pincode</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="number" disabled className=" cursor-not-allowed form-control shadow-none text-black" value={studentData.pincode} id="inputCity" />
          </div>
        </form>
        <div style={{ backgroundColor: '#0e2e2e' }} className='text-white pl-4 pt-2 pb-2 rounded-md mt-4'>4. Examination Details &nbsp;  <i className='fas fa-pen-nib'></i></div>
        {
          studentData.examinationMode === "Centre Based Examination" ?
            <>
              <form className="row g-3 text-black mt-2" disabled style={{ fontWeight: 'bold', color: 'black' }} >
                <div className="col-md-6 text-black">
                  <label for="inputEmail4" className="form-label text-black font-bold">Program Intrested</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled style={{ outline: 'none' }} type="text" value="MBA/PGDM" className="form-control shadow-none text-black cursor-no-drop" id="inputEmail4" />
                </div>
                <div className="col-md-6">
                  <label for="inputPassword4" className="form-label text-black">Examination Mode</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" value={studentData.examinationMode} id="inputPassword4" />
                </div>
                <div className="col-6">
                  <label for="inputAddress" className="form-label text-black">Exam Date</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress" value="29th Jan , 2023" placeholder="1234 Main St" />
                </div>
                <div className="col-6">
                  <label for="inputAddress" className="form-label text-black">Exam State</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress" value={studentData.examState} placeholder="1234 Main St" />
                </div>
                <div className="col-6">
                  <label for="inputAddress" className="form-label text-black">Exam City</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress" value={studentData.examCity} placeholder="1234 Main St" />
                </div>

              </form>

            </> :
            <>
              <form className="row g-3 text-black mt-2" disabled style={{ fontWeight: 'bold', color: 'black' }} >
                <div className="col-md-6 text-black">
                  <label for="inputEmail4" className="form-label text-black font-bold">Program Intrested</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled style={{ outline: 'none' }} type="text" value="MBA/PGDM" className="form-control shadow-none text-black cursor-no-drop" id="inputEmail4" />
                </div>
                <div className="col-md-6">
                  <label for="inputPassword4" className="form-label text-black">Examination Mode</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" value={studentData.examinationMode} id="inputPassword4" />
                </div>
                <div className="col-6">
                  <label for="inputAddress" className="form-label text-black">Exam Scheduled</label><span className='text-red-600 text-xl'>*</span>
                  <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress" value={studentData.scheduleExam} placeholder="1234 Main St" />
                </div>

              </form>
            </>
        }

        <div style={{ backgroundColor: '#0e2e2e' }} className='text-white pl-4 pt-2 pb-2 rounded-md mt-4'>5. Education Details &nbsp;  <i className='fas fa-file-alt'></i></div>
        <table style={{ overflow: 'hidden', width: '100%' }} className=" text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-4">
                Qualification
              </th>
              <th scope="col" className="py-3 px-4 text-center">
                Board of examination
              </th>
              <th scope="col" className="py-3 px-4 text-center">
                Obtained Marks
              </th>
              <th scope="col" className="py-3 px-4 text-center">
                Total Marks
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                10th<span className='text-red-600 text-xl font-bold'>*</span>
              </th>
              <td className="py-4 px-6 text-center">
                {studentData.tenthBoard}
              </td>
              <td className="py-4 px-6 text-center">
                {studentData.tenthObtained}
              </td>
              <td className="py-4 px-4 text-center">
                {studentData.tenthTotal}
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                12th<span className='text-red-600 text-xl font-bold'>*</span>
              </th>
              <td className="py-4 px-6 text-center">
                {studentData.twelveBoard}
              </td>
              <td className="py-4 px-6 text-center">
                {studentData.twelveObtained}
              </td>
              <td className="py-4 px-4 text-center">
                {studentData.twelveTotal}
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Graduation
              </th>
              <td className="py-4 px-6 text-center">
                {studentData.gradBoard}
              </td>
              <td className="py-4 px-6 text-center">
                {studentData.gradObtained}
              </td>
              <td className="py-4 px-4 text-center">
                {studentData.gradTotal}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='flex justify-around mt-2'>
          <div><label htmlFor="Photo" className='text-center text-black text-xl font-semibold'>Photo</label><img src={studentData.photo} style={{ width: '10rem' }} alt="" /></div>
          <div><label htmlFor="Photo" className='text-center text-black text-xl font-semibold'>Signature</label><img src={studentData.signature} style={{ width: '10rem' }} alt="" /></div>
        </div>
        <div className='mt-2'><input checked className='form-check-input' type="checkbox" />&nbsp; &nbsp;<p style={{ textAlign: 'justify' }}>I do hereby declare that All the Information provided by me here are true and correct to the best of my knowledge, I do Further undertake that in case any of the Information provided found to be Incorrect at any stage. My Application can be cancelled, I also Agree to Abide by the rules of the Institution. In case of Indiscipline or Disobedience of Rules, I shall be Liable for Expelation from the Institute, the Organisation has the right to use Information for communication purposes.</p></div>
      </>
    </div>
  )
}

export default Preview