import React, { useState, useEffect } from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import UpperNavbar from '../../Dashboard/UpperNavbar';
import { useNavigate } from 'react-router-dom'
import { Widget } from '@uploadcare/react-widget'
import Preview from './Preview';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';
function Step5() {
  const navigate = useNavigate()
  const [photo, setPhoto] = useState('')
  const [signature, setSignature] = useState('')
  const [updatedList, setUpdatedList] = useState(false)
  const [studentData, setStudentData] = useState({})
  const [tenthBoard, setTenthBoard] = useState('')
  const [twelveBoard, setTwelveBoard] = useState('')
  const [gradBoard, setGradBoard] = useState('')
  const [tenthTotal, setTenthTotal] = useState('')
  const [twelveTotal, setTwelveTotal] = useState('')
  const [gradTotal, setGradTotal] = useState('')
  const [tenthObtained, setTenthObtained] = useState('')
  const [twelveObtained, setTwelveObtained] = useState('')
  const [gradObtained, setGradObtained] = useState('')
  const [checked, setChecked] = useState(false)
  const [preview, setPreview] = useState(false)
  const [optSmModal, setOptSmModal] = useState(false);
  const toggleShow = () => setOptSmModal(!optSmModal);
  // console.log(checked)

  //        USER AUTHENTICATION            //
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
        if (data.tenthBoard) {
          setPhoto(data.photo)
          setSignature(data.signature)
          // setGradBoard(data.gradBoard)
          setTenthBoard(data.tenthBoard)
          setTwelveBoard(data.twelveBoard)
          setTenthObtained(data.tenthObtained)
          setTwelveObtained(data.twelveObtained)
          // setGradObtained(data.gradObtained)
          setTenthTotal(data.tenthTotal)
          setTwelveTotal(data.twelveTotal)
          // setGradTotal(data.gradTotal)
        }
        else {

        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  ///       EDUCATION DETAILS
  async function saveEducationDetails(event) {
    event.preventDefault()
    if(twelveBoard){
      if (!tenthBoard || !twelveBoard || !tenthTotal || !twelveTotal || !tenthObtained || !twelveObtained || !photo || !signature) {
        alert('Please Fill all Details')
      }
      else {
  
  
        const response = await fetch('/education-details-ug', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: studentData._id,
            tenthBoard: tenthBoard,
            tenthObtained: tenthObtained,
            tenthTotal: tenthTotal,
            twelveBoard: twelveBoard,
            twelveObtained: twelveObtained,
            twelveTotal: twelveTotal,
            photo,
            signature
          })
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data)
            if (data.message == "changes successfully") {
              alert('save successfully')
              userAuthenticationData()
              // setCentredModal(false)
              // navigate('/fillApplication-step6')
            }
            else {
              alert('Error')
              // setCentredModal(false)
            }
          })
      }
    }
    else{
      if (!tenthBoard || !tenthTotal  || !tenthObtained || !photo || !signature) {
        alert('Please Fill all Details')
      }
      else {
  
  
        const response = await fetch('/education-details-ug-appearing', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: studentData._id,
            tenthBoard: tenthBoard,
            tenthObtained: tenthObtained,
            tenthTotal: tenthTotal,
            photo,
            signature
          })
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data)
            if (data.message == "changes successfully") {
              alert('save successfully')
              userAuthenticationData()
              // setCentredModal(false)
              // navigate('/fillApplication-step6')
            }
            else {
              alert('Error')
              // setCentredModal(false)
            }
          })
      }
    }


  }
  //       UPDATE PHOTO AND SIGNATURE OF USER
  async function updatePhotoSignature() {
    // event.preventDefault()

    const response = await fetch('/document-update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        photo,
        signature
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          alert('changes successfully')
          // setCentredModal(false)
          // navigate('/fillApplication-step6')
        }
        else {
          alert('Error')
          // setCentredModal(false)
        }
      })
  }
  useEffect(() => {
    userAuthenticationData()
  }, [])
  //   DOCUMENT UPLOAD THROUGH UPLOADCARE.Com

  // PHOTO
  const uploadFileChange = (info) => {
    if (info.size <= 100000) {
      setPhoto(info.cdnUrl)
    }
    else {
      alert('Please Upload a lesser image')
    }
  }
  const uploadFileSelect = (file) => {
    console.log(`file changed ${file}`)
    setUpdatedList(false)
    if (file) {
      file.progress((info) => console.log("file progress: ", info.progress))
      file.done((info) => setUpdatedList(true))
    }
  }
  // SIGNATURE
  const uploadFileChange2 = (info) => {


    if (info.size <= 50000) {
      setSignature(info.cdnUrl)
    }
    else {
      alert('Please Upload a lesser image')
    }
  }
  const uploadFileSelect2 = (file) => {
    console.log(`file changed ${file}`)
    setUpdatedList(false)
    if (file) {
      file.progress((info) => console.log("file progress: ", info.progress))
      file.done((info) => setUpdatedList(true))
    }
  }

  return (
    <div className='paymentPage'>
      <UpperNavbar />
      <h2 className='applicationCSS4 text-center pt-2 text-black font-bold'>UG Application Form 2023 (Engineering)</h2>
      <div className='applicationCSS3'>
        <img className='fluid applicationCSS2' style={{ margin: 'auto' }} src="https://ucarecdn.com/90cfcddb-69e6-4c92-9f35-efc371050bd7/step5.png" alt="" />
      </div>








      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form applicationCSS'>
        <form className="row g-3">
          {/* <div className="col-md-6 ">
            <label htmlFor="inputEmail4" className="form-label text-black font-bold">Photo <span className='text-sm'> ( should be less than 30kb )</span></label><span className='text-red-600 text-xl font-bold'>*</span>
            <br />
            <div className='bg-gray-400 text-black'>
              <Widget

                className='text-black'
                publicKey="b0a09355a6bf471e30e8"
                id="file"
                crop='1:1'
                clearable={true}
                onChange={(info) => uploadFileChange(info)}
                onFileSelect={(file) => { uploadFileSelect(file) }}
              />
            </div>
            <div>
              <img style={{ width: '10rem', margin: 'auto', marginTop: '1rem' }} src={photo} alt="" />
            </div>

          </div> */}
          {/* <div className="col-md-6">
            <label htmlFor="inputPassword4" className="form-label text-black font-bold">Signature <span className='text-sm'> ( should be less than 30kb )</span></label><span className='text-red-600 text-xl font-bold'>*</span>
            <br />
            <div className='bg-gray-400 text-black'>
              <Widget
                className='text-black'
                publicKey="b0a09355a6bf471e30e8"
                id="file"
                crop='5:2'
                clearable={true}
                onChange={(info) => uploadFileChange2(info)}
                onFileSelect={(file) => { uploadFileSelect2(file) }}
              />
            </div>
            <div>
              <img style={{ width: '10rem', margin: 'auto', marginTop: '1rem' }} src={signature} alt="" />
            </div>
          </div> */}
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">
                    Qualification
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Board of examination
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Obtained Marks
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Total Marks
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    10th<span className='text-red-600 text-xl font-bold'>*</span>
                  </th>
                  <td className="py-4 px-6 text-center">
                    <input value={tenthBoard} onChange={(e) => setTenthBoard(e.target.value)} placeholder="eg:-CBSE,ICSE" className='border' style={{ outline: 'none' }} type="text" />
                  </td>
                  <td className="py-4 px-6 text-center">
                    <input value={tenthObtained} onChange={(e) => setTenthObtained(e.target.value)} className='border' style={{ outline: 'none' }} type="text" />
                  </td>
                  <td className="py-4 px-6 text-center">
                    <input value={tenthTotal} onChange={(e) => setTenthTotal(e.target.value)} className='border' style={{ outline: 'none' }} type="text" />
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    12th<span className=''>(Optional if You are appearing in 2023)</span>
                  </th>
                  <td className="py-4 px-6 text-center">
                    <input value={twelveBoard} onChange={(e) => setTwelveBoard(e.target.value)} placeholder="eg:-CBSE,ICSE" className='border' style={{ outline: 'none' }} type="text" />
                  </td>
                  <td className="py-4 px-6 text-center">
                    <input value={twelveObtained} onChange={(e) => setTwelveObtained(e.target.value)} className='border' style={{ outline: 'none' }} type="text" />
                  </td>
                  <td className="py-4 px-6 text-center">
                    <input value={twelveTotal} onChange={(e) => setTwelveTotal(e.target.value)} className='border' style={{ outline: 'none' }} type="text" />
                  </td>
                </tr>

              </tbody>
            </table>
            <div className='flex gap-4 mt-4' style={{ width: '95%', margin: 'auto' }}>
              <div className="col-md-6 ">
                <label htmlFor="inputEmail4" className="form-label text-black font-bold">Photo <span className='text-sm'> ( should not be greater than 100kb )</span></label><span className='text-red-600 text-xl font-bold'>*</span>
                <br />
                <div className='bg-gray-400 text-black'>
                  <Widget

                    className='text-black'
                    publicKey="b0a09355a6bf471e30e8"
                    id="file"
                    crop='1:1'
                    clearable={true}
                    onChange={(info) => uploadFileChange(info)}
                    onFileSelect={(file) => { uploadFileSelect(file) }}
                    tabs="file" tabsCss=".source-file { background: #1877F2;color:black; }"
                  />
                </div>
                <div>
                  <img style={{ width: '10rem', margin: 'auto', marginTop: '1rem' }} src={photo} alt="" />
                </div>

              </div>
              <div className="col-md-6">
                <label htmlFor="inputPassword4" className="form-label text-black font-bold">Signature <span className='text-sm'> ( should not be greater than 50kb  )</span></label><span className='text-red-600 text-xl font-bold'>*</span>
                <br />
                <div className='bg-gray-400 text-black'>
                  <Widget
                    className='text-black'
                    publicKey="b0a09355a6bf471e30e8"
                    id="file"
                    crop='5:2'
                    clearable={true}
                    onChange={(info) => uploadFileChange2(info)}
                    onFileSelect={(file) => { uploadFileSelect2(file) }}
                    tabs="file" tabsCss=".source-file { background: #1877F2;color:black; }"
                  />
                </div>
                <div>
                  <img style={{ width: '10rem', margin: 'auto', marginTop: '1rem' }} src={signature} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{width:'99%',margin:'auto',border:'1px solid black',marginTop:'1rem',backgroundColor:'#0e2e2e',borderRadius:'5px'}} className='text-white font-thin p-1'> */}
          {/* <p>hello woeld</p> */}
          {/* <i className='fa fa-info-circle text-xl'/>&nbsp; &nbsp;<span className=''>Important Instructions.</span>
            <li className='pl-10 text-sm'>At the time of transaction, if the amount is debited, kindly wait for an hour to confirm the payment status in same login page.</li>
            <li className='pl-10 text-sm'>If the amount is debited more than one time, it will be refunded to your account in 5 to 7 working days.</li>
            <li className='pl-10 text-sm'>Your Application Number Will always be Same as Order ID.</li>
            <li className='pl-10 text-sm'>Always Check Your Spam Box/Prmotional if you are not getting an email in Inbox.</li>
            <li className='pl-10 text-sm'>Once Application is submitted and payment is deducted. It will not be refunded in any circumstances.</li> */}
          <center className='mt-2'><input value={checked} onChange={(e) => { setChecked(!checked) }} className='' type="checkbox" />&nbsp; &nbsp;<span className='text-start' style={{ textAlign: 'start',color:'black',fontWeight:'600' }}>I do hereby declare that All the Information provided by me here are true and correct to the best of my knowledge, I do Further undertake that in case any of the Information provided found to be Incorrect at any stage. My Application can be cancelled, I also Agree to Abide by the rules of the Institution. In case of Indiscipline or Disobedience of Rules, I shall be Liable for Expelation from the Institute, the Organisation has the right to use Information for communication purposes.</span></center>
          {/* </div> */}
        </form>
        {/* //////                 PAYMENT PAGE START                          */}
        <form method="POST" name="customerData" action="/ccavRequestHandler">
          <table style={{ display: 'none' }} width="40%" height="100" border='1' align="center">
            <caption>
              <font size="4" color="blue"><b>Integration Kit</b></font>
            </caption>
          </table>
          <table width="40%" height="100" border='1' align="center" style={{ border: 'none' }}>
            <tr style={{ display: 'none' }}>
              <td>Parameter Name:</td>
              <td>Parameter Value:</td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td colspan="2">Compulsory information</td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Merchant Id</td>
              <td><input type="text" name="merchant_id" id="merchant_id" value="241419" /> </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Order Id</td>
              <td><input type="text" name="order_id" value={studentData.EnggApplicationNo} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Currency</td>
              <td><input type="text" name="currency" value="INR" /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Amount</td>
              <td><input type="text" name="amount" value="1300" /></td>
            </tr>


            <tr style={{ display: 'none' }}>
              <td>Billing Address:</td>
              <td><input type="text" name="billing_address"
                value={studentData.addressLine1} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing City:</td>
              <td><input type="text" name="billing_city" value={studentData.city} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing State:</td>
              <td><input type="text" name="billing_state" value={studentData.state} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Zip:</td>
              <td><input type="text" name="billing_zip" value={studentData.pincode} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Country:</td>
              <td><input type="text" name="billing_country" value={studentData.country} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Tel:</td>
              <td><input type="text" name="billing_tel" value={studentData.mobileNo} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Redirect URL</td>
              <td><input type="text" name="redirect_url"
                value="https://aicee.in/ccavResponseHandlerEngg" />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Cancel URL</td>
              <td><input type="text" name="cancel_url"
                value="https://aicee.in/ccavResponseHandlerEngg" />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Language</td>
              <td><input type="text" name="language" id="language" value="EN" /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td colspan="2">Billing information(optional):</td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Name</td>
              <td><input type="text" name="billing_name" value={studentData.studentName} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Email:</td>
              <td><input type="text" name="billing_email"
                value={studentData.emailId} /></td>
            </tr>
            <tr>
              <td>
                <center className='grid gap-1 sm:flex'>
                  <div><button onClick={() => { navigate('/online-engg-4-fillApplication') }} className=" btn btn-primary">Back</button></div>
                  <div><p onClick={saveEducationDetails} className=" btn btn-success">Save</p></div>
                  <div>{studentData.signature ? <p className=" btn btn-primary" disabled={studentData.signature} onClick={toggleShow}>Preview</p> : <></>}</div>
                  <div style={{ textAlign: "center" }}>
                    <button
                      disabled={checked == false || !studentData.signature}
                      type="submit"
                      style={{ width: '24rem' }} className="btn btn-primary"
                    >
                      Final Submit & Proceed for Application Fee
                    </button>
                  </div>
                </center>
              </td>
            </tr>
          </table>
        </form>
        {/* //////                 PAYMENT PAGE END                          */}
        <div className="col-12 flex justify-between flex-row-reverse  " style={{ marginTop: '5%' }}>
          {/* <button onClick={saveEducationDetails} className=" btn btn-primary">Save & Continue</button> */}
          {/* <button onClick={() => { navigate('/fillApplication-step4') }} className=" btn btn-primary">Back</button> */}
        </div>
      </div>
      {/* {
        preview ? <> */}
      <MDBModal show={optSmModal} tabIndex='-1' setShow={setOptSmModal}>
        <MDBModalDialog size='lg'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className='text-black font-bold'>Preview Of Your Application</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {
                studentData.photo ?
                  <>
                    <Preview data={studentData} />
                  </>
                  :
                  <>
                  </>
              }
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* </> :
          <>

          </>
      } */}
    </div>
  )
}

export default Step5



