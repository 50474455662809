import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
function ScheduledYourExam() {
  const navigate = useNavigate()
  const [studentData, setStudentData] = useState({})
  const [engg,setEngg] = useState('')
  const [pcm,setPcm] = useState('')
  const [pcb,setPcb] = useState('')
  const [other,setOther] = useState('')
  const [mba,setMba] = useState('')
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  async function mbaSubmit() {
    // event.preventDefault()

    const response = await fetch('/exam-scheduled-change-mba', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        examScheduled:mba
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          alert('Exam Scheduled has been Changed Successfully')
          

          navigate('/student-dashboard')
        }
        else {
       
        }
      })
  }
  async function enggSubmit() {
    // event.preventDefault()

    const response = await fetch('/exam-scheduled-change-engg', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        examScheduled:engg
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          alert('Exam Scheduled has been Changed Successfully')
          

          navigate('/student-dashboard')
        }
        else {
       
        }
      })
  }
  async function pcmSubmit() {
    // event.preventDefault()

    const response = await fetch('/exam-scheduled-change-pcm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        examScheduled:pcm
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          alert('Exam Scheduled has been Changed Successfully')
          

          navigate('/student-dashboard')
        }
        else {
       
        }
      })
  }
  async function pcbSubmit() {
    // event.preventDefault()

    const response = await fetch('/exam-scheduled-change-pcb', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        examScheduled:pcb
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          alert('Exam Scheduled has been Changed Successfully')
          

          navigate('/student-dashboard')
        }
        else {
       
        }
      })
  }
  async function otherSubmit() {
    // event.preventDefault()

    const response = await fetch('/exam-scheduled-change-other', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        examScheduled:other
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          alert('Exam Scheduled has been Changed Successfully')
          

          navigate('/student-dashboard')
        }
        else {
       
        }
      })
  }

  useEffect(() => { 
    userAuthenticationData()
  }, [])
  return (
    <div>
      {
        studentData && <>
          {
            studentData.MBACompleted && <>
              {
                studentData.examinationMode === "Home Based Examination" &&
                <>
                  <div class="w-full md:w-1/3 px-3 mb-3 md:mb-0" style={{ marginTop: '2rem' }}>
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                      Change Exam Slot of MBA / PGDM Application
                    </label>
                    <div class="relative">
                      <select value={mba} onChange={(e)=>{setMba(e.target.value)}} class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                        <option>--- Select -----</option>
                        <option value="03/02/2023 - 10AM to 12 PM">03/02/2023 - 10AM to 12 PM</option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                      </div>
                    </div>
                  </div>
                  <button onClick={()=>{mbaSubmit()}} className='text-white p-2 ml-6' style={{ backgroundColor: '#0e2e2e' }}>Lock Your Schedule</button>
                </>
              }
            </>

          }
        </>
      }
      {
        studentData && <>
          {
            studentData.PCMCompleted && <>
              {
                studentData.PcmexaminationMode === "Home Based Examination" &&
                <>
                  <div class="w-full md:w-1/3 px-3 mb-3 md:mb-0" style={{ marginTop: '2rem' }}>
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                      Change Exam Slot of PCM Application
                    </label>
                    <div class="relative">
                      <select value={pcm} onChange={(e)=>{setPcm(e.target.value)}} class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                        <option>--- Select -----</option>
                        <option value="04/02/2023 - 4PM to 6 PM">04/02/2023 - 4PM to 6 PM</option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                      </div>
                    </div>
                  </div>
                  <button onClick={()=>{pcmSubmit()}} className='text-white p-2' style={{ backgroundColor: '#0e2e2e', margin: '-2rem 1rem 1rem 1rem' }}>Lock Your Schedule</button>
                </>
              }
            </>

          }
        </>
      }
      {
        studentData && <>
          {
            studentData.PCBCompleted && <>
              {
                studentData.PcbexaminationMode === "Home Based Examination" &&
                <>
                  <div class="w-full md:w-1/3 px-3 mb-3 md:mb-0" style={{ marginTop: '2rem' }}>
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                      Change Exam Slot of PCB Application
                    </label>
                    <div class="relative">
                      <select value={pcb} onChange={(e)=>{setPcb(e.target.value)}} class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                        <option>--- Select -----</option>
                        <option value="04/02/2023 - 1PM to 3 PM">04/02/2023 - 1PM to 3 PM</option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                      </div>
                    </div>
                  </div>
                  <button onClick={()=>{pcbSubmit()}} className='text-white p-2' style={{ backgroundColor: '#0e2e2e', margin: '-2rem 1rem 1rem 1rem' }}>Lock Your Schedule</button>
                </>
              }
            </>

          }
        </>
      }
      {
        studentData && <>
          {
            studentData.EnggCompleted && <>
              {
                studentData.EnggexaminationMode === "Home Based Examination" &&
                <>
                  <div class="w-full md:w-1/3 px-3 mb-3 md:mb-0" style={{ marginTop: '2rem' }}>
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                      Change Exam Slot of Engineering Application
                    </label>
                    <div class="relative">
                      <select value={engg} onChange={(e)=>{setEngg(e.target.value)}} class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                        <option>--- Select -----</option>
                        <option value="05/02/2023 - 10AM to 12PM">05/02/2023 - 10AM to 12PM</option>
                        <option value="05/02/2023 - 1PM to 3 PM">05/02/2023 - 1PM to 3 PM</option>
                        <option value="05/02/2023 - 4PM to 6 PM">05/02/2023 - 4PM to 6 PM</option>

                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                      </div>
                    </div>
                  </div>
                  <button onClick={()=>{enggSubmit()}} className='text-white p-2' style={{ backgroundColor: '#0e2e2e', margin: '-2rem 1rem 1rem 1rem' }}>Lock Your Schedule</button>
                </>
              }
            </>

          }
        </>
      }
      {
        studentData && <>
          {
            studentData.OtherCompleted && <>
              {
                studentData.OtherexaminationMode === "Home Based Examination" &&
                <>
                  <div class="w-full md:w-1/3 px-3 mb-3 md:mb-0 mt-2" style={{ marginTop: '2rem' }}>
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                      Change Exam Slot of Other UG Application
                    </label>
                    <div class="relative">
                      <select value={other} onChange={(e)=>{setOther(e.target.value)}} class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                        <option>--- Select -----</option>
                        <option value="03/02/2023 - 1PM to 3 PM">03/02/2023 - 1PM to 3 PM</option>

                        <option value="03/02/2023 - 4PM to 6 PM">03/02/2023 - 4PM to 6 PM</option>

                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                      </div>
                    </div>
                  </div>
                  <button onClick={()=>{otherSubmit()}} className='text-white p-2' style={{ backgroundColor: '#0e2e2e', margin: '-2rem 1rem 1rem 1rem' }}>Lock Your Schedule</button>
                </>
              }
            </>

          }
        </>
      }
    </div>
  )
}

export default ScheduledYourExam