import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
const LoginForm = () => {
    const [email , setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    async function loginUser(event) {
        event.preventDefault()
        if (!email || !password) {
            alert('invalid credentials')
        }
        else {
            const response = await fetch('/refund/online-student/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailId: email,
                    password: password
                })
            })
            const data = await response.json()
            if (data.message === 'this email id does not exist') {
                alert('Email id does not exist')
            }
            else if (data.message === 'please enter correct password') {
                alert('please enter correct password')
            }
            else {
                navigate('/refund-request')
            }

            //   console.log(data)
        }

    }
  return (
    <LoginCSS>
       <body>
       <div class="container h-100">
		<div class="d-flex justify-content-center h-100">
			<div class="user_card">
                <div className='login_header'>
                    Refund Claim 
                </div>
				<div class="d-flex justify-content-center form_container">
					<form>
						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" name="" class="form-control input_user" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="username"/>
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-key"></i></span>
							</div>
							<input type="password" name="" class="form-control input_pass" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="password"/>
						</div>
						
							<div class="d-flex justify-content-center mt-3 login_container">
				 	<button onClick={loginUser} type="button" name="button" class="btn login_btn">Login</button>
				   </div>
					</form>
				</div>
		
				
			</div>
		</div>
	</div>
       </body>
    </LoginCSS>
  )
}

const LoginCSS = styled.main`

body {
			margin: 0;
			padding: 0;
			height: 100%;
			background: #464545 !important;
			
            padding: 6rem 0;
            height: 100vh;
		}
		.user_card {
			height: 300px;
			width: 350px;
			margin-top: auto;
			margin-bottom: auto;
			background: #e35d26;
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 10px;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			-moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			border-radius: 5px;

		}
        .login_header{
            text-align: center;
            color: white;
            font-size: 16px;
            font-weight: 500;
        }
		.brand_logo_container {
			position: absolute;
			height: 170px;
			width: 170px;
			top: -75px;
			border-radius: 50%;
			background: #60a3bc;
			padding: 10px;
			text-align: center;
		}
		.brand_logo {
			height: 150px;
			width: 150px;
			border-radius: 50%;
			border: 2px solid white;
		}
		.form_container {
			margin-top: 50px;
		}
		.login_btn {
			width: 100%;
			background: #012060 !important;
			color: white !important;
		}
		.login_btn:focus {
			box-shadow: none !important;
			outline: 0px !important;
		}
		.login_container {
			padding: 0 2rem;
		}
		.input-group-text {
			background: #012060 !important;
			color: white !important;
			border: 0 !important;
			border-radius: 0.25rem 0 0 0.25rem !important;
		}
		.input_user,
		.input_pass:focus {
			box-shadow: none !important;
			outline: 0px !important;
		}
		.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
			background-color: #c0392b !important;
		}
`

export default LoginForm