import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
function Footer() {

  return (
    <div style={{ backgroundColor: '#E35D26', color: 'white', fontSize: '12px', lineHeight: '1.8', fontWeight: '500', textAlign: 'center', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }} className='p-2'>
      {/* <span>This Examination is organized , managed by Omsai Educon Pvt.Ltd</span><br /> */}
      <div className='md:flex grid  items-center justify-between px-3 ' style={{display:'hidden'}}>
          <span>All rights reserved @ AICEE (All India Combined Entrance Examination) </span>
        <div>
        <a className='flex md:text-center items-center gap-2 text-white' target='__blank' href='https://www.instagram.com/aicee_indiaofficial?utm_source=qr&igsh=dmlhem5oZnZnMTJ5'>
          Follow us <FaInstagram className='text-white font-bold text-[20px] md:text-center' />

        </a>
        </div>
      </div>
    </div>
  )
}

export default Footer