import React from 'react'

function Profile({ data }) {
  // console.log(data)
  return (
    <div className='p-5 paymentPage'>
      {
        data && <form>
        <div className="row mb-3">
          <label for="inputEmail3" className="col-sm-2 col-form-label font-bold text-black">Name</label>
          <div className="col-sm-10">
            <input disabled type="email" className="form-control text-black font-bold bg-white" value={data.name} id="inputEmail3" />
          </div>
        </div>
        <div className="row mb-3">
          <label for="inputEmail3" className="col-sm-2 col-form-label font-bold text-black">Country</label>
          <div className="col-sm-10">
            <input disabled type="email" className="form-control text-black font-bold bg-white" value={data.country} id="inputEmail3" />
          </div>
        </div>
        <div className="row mb-3">
          <label for="inputEmail3" className="col-sm-2 col-form-label font-bold text-black">Gender</label>
          <div className="col-sm-10">
            <input disabled type="email" className="form-control text-black font-bold bg-white" value={data.gender} id="inputEmail3" />
          </div>
        </div>
        <div className="row mb-3">
          <label for="inputEmail3" className="col-sm-2 col-form-label font-bold text-black">Email</label>
          <div className="col-sm-10">
            <input disabled type="email" className="form-control text-black font-bold bg-white" value={data.email} id="inputEmail3" />
          </div>
        </div>
        <div className="row mb-3">
          <label for="inputEmail3" className="col-sm-2 col-form-label font-bold text-black">Mobile NO</label>
          <div className="col-sm-10">
            <input disabled type="email" className="form-control text-black font-bold bg-white" value={data.mobileNo} id="inputEmail3" />
          </div>
        </div>

      </form>
      }
    </div>
  )
}

export default Profile