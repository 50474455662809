import React from 'react'

const Preview = () => {
  return (
    <div><div class="d-flex align-items-center justify-content-center" style={{"height": "70vh"}}>
    <div class="p-2 m-2  text-white shadow rounded-2" style={{height:'150px',width:'900px',backgroundColor:'#f1f1f1'}}>
        {/* <div className='font-bold text-black'>Message:-</div> */}
        <div className='text-black mt-3 text-center'>
        <button type="button" class="btn text-white mb-4" style={{backgroundColor:'#042e2e'}}>Your Refund Claim has been successfully submitted.</button>
            <p style={{textAlign:'center'}}>Your Refund claim will be settled in your Bank account within 15-30 Working Days.</p>
            {/* <li className='text-justify mt-3'>
                Refund of Seat Confirmation fee will be settled in between 7th Aug to 15th Aug . [ Approx amount INR 20,000 /- ]
            </li> */}
        </div>
    </div>
</div></div>
  )
}

export default Preview