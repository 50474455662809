import React from 'react'
import AllApplications from '../Pages/Dashboard/AllApplication'
import SideBar from '../Pages/Dashboard/SideBar';
import UpperNavbar from '../Pages/Dashboard/UpperNavbar';
import '../Pages/Dashboard/Dashboard.css'
import { MDBContainer, MDBCard, MDBRow, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
function AllApplication() {
  return (
    <div className='paymentPage'>
    <div style={{  height: '100vh', overflow: 'scroll initial', width: '100%' }}>
        <div>
        <UpperNavbar />
        </div>
        <div className='flex' style={{height: '90vh' }}>
            <SideBar />
            
            <div style={{ backgroundColor: '#f1f3fa', width: '100%', height: '90vh', overflow: 'scroll' }}>
                        <h3 className='pt-3 pl-3 pr-3 text-black text-2xl' style={{ fontWeight: '500' }}>All Application Form (s)</h3>
                <hr />
                <AllApplications/>
            </div>
        </div>
    </div>
</div>
  )
}

export default AllApplication