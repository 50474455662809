import React from 'react'
import LeftBar from './LeftBar'
// import CentreBar from './CentreBar'
import RightSideBar from './RightSideBar'
import newicon from './img/newicon.gif'
import { useNavigate, Link } from 'react-router-dom'
import Header from './Header'
import Marque from './Marque'
import Footer from '../Registration/Footer'
// import newicon from './img/newicon.gif'
import SAMPLE_SET_1_BIOLOGY from '../../pdf/SAMPLE_SET_1_BIOLOGY.pdf'
import SAMPLE_SET_1_CHEMISTRY from '../../pdf/SAMPLE_SET_1_CHEMISTRY.pdf'
import SAMPLE_SET_1_MATHS from '../../pdf/SAMPLE_SET_1_MATHS.pdf'
import SAMPLE_SET_1_PHYSICS from '../../pdf/SAMPLE_SET_1_PHYSICS.pdf'
import Biology2 from '../../pdf/Biology2.pdf'
import Chemistry2 from '../../pdf/Chemistry2.pdf'
import General_Awareness2 from '../../pdf/General_Awareness2.pdf'
import Physics2 from '../../pdf/Physics2.pdf'

function SampleQuestion() {
    const navigate = useNavigate()
    return (
        <div>
            <Header />
            {/* <Marque /> */}
            <section className="contentsection mt-2" id="skipcontent">
                <div className="container-fluid">
                    <div className="" style={{width:'100%',margin:'auto'}}>

                        {/* <!-- LEFT SIDEBAR START HERE --> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <LeftBar />
                        </div> */}
                        {/* </div> <!-- LEFT SIDEBAR END HERE --> */}


                        <>
                            <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12" style={{margin:'auto'}}>
                                <div className="mb-4">
                                    <div className="mb-4 pagecontentpara">
                                    <h5 className="pageheading" style={{ fontSize: '1rem', fontWeight: 'bold', color: 'black',textAlign:'center' }}>Sample Question Papers</h5>
                                        <div className="list-group " >
                                            {/* <div> */}
                                            <a
                                                href={SAMPLE_SET_1_PHYSICS}
                                                target="_blank"
                                                className="list-group-item list-group-item-action flex items-center"
                                            >
                                                <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Physics Sample Paper Set-1</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            {/* </div> */}
                                            <a
                                                href={SAMPLE_SET_1_CHEMISTRY}
                                                target="_blank"
                                                className="list-group-item list-group-item-action"
                                            >
                                               <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Chemistry Sample Paper Set-1</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            <a
                                                href={SAMPLE_SET_1_MATHS}
                                                target="_blank"
                                                className="list-group-item list-group-item-action"
                                            >
                                                <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Maths Sample Paper Set-1</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            <a
                                                href={SAMPLE_SET_1_BIOLOGY}
                                                target="_blank"
                                                className="flex list-group-item"
                                            >
                                                <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Biology  Sample Paper Set-1</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            <a
                                                href={General_Awareness2}
                                                target="_blank"
                                                className="list-group-item list-group-item-action"
                                            >
                                                <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;General Awareness Sample Paper Set-1</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            <a
                                                href={Physics2}
                                                target="_blank"
                                                className="list-group-item list-group-item-action flex items-center"
                                            >
                                                <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Physics Sample Paper Set-2</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            {/* </div> */}
                                            <a
                                                href={Chemistry2}
                                                target="_blank"
                                                className="list-group-item list-group-item-action"
                                            >
                                               <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Chemistry Sample Paper Set-2</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            
                                            <a
                                                href={Biology2}
                                                target="_blank"
                                                className="flex list-group-item"
                                            >
                                                <span className='flex'><span><i class="fas fa-file-pdf text-red-600 text-xl"></i>.&nbsp;Biology  Sample Paper Set-2</span>
                                                <span><sup>
                                                    <img src={newicon} />
                                                </sup></span></span>
                                            </a>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* INTRO SECTION END HERE */}
                        </>



                        {/* <!-- RIGHT SIDEBAR START HERE --> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <RightSideBar />
                        </div> */}
                        {/* <!-- RIGHT SIDEBAR END HERE --> */}

                    </div>
                </div>
            </section>

            {/* <Footer /> */}
        </div>
    )
}

export default SampleQuestion

