const applicationTitle =
{
    mba: ["MBA / PGDM Application Form 2025"],
    engg: ["Engineering ( B-Tech / B.E )"],
    pcm: [

        "Bio –Technology ( Bsc.Biotechnology / B. Tech Biotechnology )",
        "B.Pharmacy",
        "Aeronautical / Aerospace Engineering",
        "Agriculture ( Bsc. Agriculture / B.Tech Agriculture )"

    ],
    pcb: [
        "B.Pharmacy",
        "Bsc.Nursing",
        "Bio –Technology",
        "Agriculture ( Bsc.Agriculture )", "BPT ( Bachelor of physiotherapy )"

    ],
    other: [
        "BBA / BCA",
        "B. Design",
        "BA LLB / BBA LLB / BCA LLB / B.COM LLB",
        "Mass Communication & Journalism ( BJMC )",
        "B.A / B.Com / B.Sc"
    ]
}
const applicationCategory =
{
    mba: ["MBA", "PGDM"],
    engg: ["Engineering ( B-Tech / B.E )"],
    pcm: [

        "Bsc.Biotechnology", "B. Tech Biotechnology",
        "B.Pharmacy",
        "Aeronautical", "Aerospace Engineering",
        "B.Tech Agriculture ", "Bsc. Agriculture"

    ],
    pcb: [
        "B.Pharmacy",
        "Bsc.Nursing",
        "Bio –Technology",
        "Agriculture ( Bsc.Agriculture )", "BPT ( Bachelor of physiotherapy )"

    ],
    other: [
        "BBA", "BCA",
        "B. Design",
        "BA LLB", "BBA LLB", "BCA LLB", "B.COM LLB",
        "Mass Communication & Journalism ( BJMC )",
        "B.A", "B.Com", "B.Sc"
    ]
}

module.exports = {applicationTitle,applicationCategory}
