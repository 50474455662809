import React from 'react'
import LeftBar from './LeftBar'
// import CentreBar from './CentreBar'
import RightSideBar from './RightSideBar'
import newicon from './img/newicon.gif'
import Header from './Header'
import {useNavigate,Link} from 'react-router-dom'
import Marque from './Marque'
import Footer from '../Registration/Footer'
function ContactUs() {
    const navigate = useNavigate()
    return (
        <div>
            <Header/>
            <Marque/>
            <section className="contentsection mt-2" id="skipcontent">
                <div className="container-fluid">
                    <div className="row">

                        {/* <!-- LEFT SIDEBAR START HERE --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <LeftBar/>
                        </div>
                        {/* </div> <!-- LEFT SIDEBAR END HERE --> */}


                        <>
  {/* INTRO SECTION START HERE */}
  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <div className="mb-4">
      <div className="mb-4 pagecontentpara">
        <h3 className="pageheading text-black">Contact Us</h3>
        <h4>
          <strong className='text-black'>ALL INDIA COMBINED ENTRANCE EXAMINATION</strong>
        </h4>
        <p className='text-black text-sm'>
          Yuvraj Palace, 1ST Floor
          <br />
          above ADITYA VISION, Kankarbagh
          <br />
          Patna, Bihar 800020
          <br />
          <strong>Contact No. :</strong> +91 7488661870
          <br />
          Helpdesk : For any transaction related queries/ inquiry, please
          contact at:{" "} info@aicee.in  ,   aicee.info@gmail.com

        </p>
      </div>
    </div>
    <div className="mt-2">
      <nav id="sidebar">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d69808.47074576572!2d85.09865722818651!3d25.5752901539857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed593abfece167%3A0xf7e1206b3b0dc0a!2sAICEE%20(ALL%20INDIA%20COMBINED%20ENTRANCE%20EXAMINATION)!5e0!3m2!1sen!2sin!4v1640247782287!5m2!1sen!2sin"
          width={600}
          height={300}
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        />
      </nav>
      <br />
    </div>
  </div>
  {/* INTRO SECTION END HERE */}
</>







                        {/* <!-- RIGHT SIDEBAR START HERE --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <RightSideBar/>
                        </div>
                        {/* <!-- RIGHT SIDEBAR END HERE --> */}
                        
                    </div>
                </div>
            </section>
        <Footer/>

        </div>
    )
}

export default ContactUs