import React, { useState, useEffect, useRef } from 'react'
// import UpperNavbar from '../../Dashboard/UpperNavbar';
import UpperNavbar from '../Dashboard/UpperNavbar';
import { useNavigate,useParams,Link } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import AcknowledgeMentPrint from './AcknowledgeMentPrint';

import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'
import Testing from './Testing';
function PaymentThankYou() {
  const navigate = useNavigate()
  const [studentData, setStudentData] = useState({})
  const [paymentDate, setPaymentDate] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [amount, setAmount] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [status, setStatus] = useState('')
  const [optSmModal, setOptSmModal] = useState(false);
  const {program} = useParams()
  const toggleShow1 = () => setOptSmModal(!optSmModal);
  //        USER AUTHENTICATION            //
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
        // if (data.paymentResponseMBA) {
        //   const payment = data.paymentResponseMBA[data.paymentResponseMBA.length - 1].paymentStatus
        //   const paymentTrim = payment.trim().split("&")
        //   const transDate = paymentTrim[40].trim().split('=')
        //   const paymentMode = paymentTrim[5].trim().split('=')
        //   const paymentAmount = paymentTrim[10].trim().split('=')
        //   const paymentTransaction = paymentTrim[1].trim().split('=')
        //   const orderStatus = paymentTrim[3].trim().split('=')
        //   setPaymentDate(transDate[1])
        //   setPaymentMethod(paymentMode[1])
        //   setAmount(paymentAmount[1])
        //   setTransactionId(paymentTransaction[1])
        //   setStatus(orderStatus[1])
        //   // console.log(paymentTrim)
        //   // console.log(transDate)



        // }
        // else {
        //   // console.log('no')
        // }

      })
      .catch(error => {
        console.log(error)
      })
  }

  ///   print functionallity
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    //   pageStyle: "@page { margin-top: 0px;  } ",
    content: () => componentRef.current,
  });

  //    MBA APPLICATION OPEN FUNCTIONALLITY
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
  useEffect(() => {
    userAuthenticationData()
  }, [])
  // console.log("studentSD",studentData[program].applicationNo)
  return (
    <>
      {studentData[program] && 
        <div className='paymentPage'>
      <div>
        <UpperNavbar data={studentData}/>
      </div>
      <div>
        <button onClick={() => { navigate('/student-dashboard') }} className='m-4 border border-b-slate-400 pl-3 pr-3 pt-2 pb-2 font-bold' style={{ borderRadius: '7px' }}>Back To Dashboard</button>
      </div>
      <div>
        <h1 className='text-center text-5xl text-green-700'>Thank You !</h1>
        <p className='text-center text-[20px] mt-[5px]'>Your application has been Submitted Successfully.</p>
        <p className='text-center text-[17px] mt-[-15px]'>Application No : <span className='font-bold'>{studentData[program] && studentData[program].applicationNo}</span></p>
        <p className='text-center text-[17px] mt-[-15px]'>Payment Transaction ID : <span className='font-bold'>{studentData[program].transId}</span></p>
        <div className='thanku'>
        <p 
        // onClick={toggleShow1}
       
         className='text-center text-sm  cursor-pointer'> <span><i className='fa fa-print text-green-700' /></span> <Link to={`/student-dashboard/${program}/form`} target='_blank' style={{textDecoration:'none'}}>
          Print / Download Application Form
         </Link> <span className='font-bold'></span></p>
        <p onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} className='text-center text-sm cursor-pointer text-blue-600'> <span><i className='fa fa-print text-green-700' /></span> Print / Download Payment Acknowledgement<span className='font-bold'></span></p>
        </div>
        <hr style={{width:'80%',margin:'auto'}}/>
        <div className="text-[12px] thankuDeclar">
          <p>
            For Further reference and any correspondence, please ensure that you mention your application number printed above for easy retrieval of your application form.
          </p>
          <p>
            This also serves as an acknowledgement that your application has been received by the instruction. You can print your completed application form and acknowledgement receipt for your own records.
          </p>
        </div>
        {/* <p onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} className='text-center text-sm pt-4 cursor-pointer'> <span><i className='fa fa-print'/></span> Print / Download Application Form<span className='font-bold'></span></p> */}
      </div>
      {/* // modal start // */}
      <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
        <MDBModalDialog size='fullscreen-xxl-down'>
          <MDBModalContent>
            <MDBModalBody>
              <div style={{ width: '700px', margin: 'auto' }} className='border border-black' ref={componentRef}>
                <div className='m-5 border border-black'>
                  <div className='m-4'>
                    <img style={{ width: '11rem', margin: 'auto' }} src="https://ucarecdn.com/310f986c-7286-41bf-9bfd-79673ec9eff1/AICEE2023LOGO4.png" alt="" />
                  </div>
                  <div className='text-center text-black'>
                    <h5 className='font-bold' style={{fontWeight:'bold'}}>All India Combined Entrance Examintaions</h5>
                  </div>
                  <div className='text-center text-black font-bold mt-[-10px]'>
                    <h5 className='font-bold' style={{ marginTop: '1.3rem', textDecoration: 'underLine',fontSize:'17px' }}>Electronic Receipt</h5>
                  </div>
                  <div className='text-center text-black font-bold '>
                    <h6 className='' style={{ marginTop: '1rem' }}>Application No : <span className='font-bold'>{studentData[program].applicationNo}</span></h6>
                  </div>
                  <div className='text-start pl-3 text-black mt-1' style={{ fontSize: '0.9rem' ,lineHeight:'15px',textAlign:'justify'}}>
                    <p className='' style={{ marginTop: '1.8rem' }}>To  <span className='font-bold'>{studentData.name}</span></p>
                    <p className='font-bold' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Online Application successfully Submitted. </p>
                    <p className='text-black ' style={{ marginTop: '-0.8rem', fontSize: '0.8rem',color:'black' }}>Amount Received towards <span className='font-bold'> {program == 'mba' ? "PG Application Form 2025" : "UG Application Form 2025"}</span> </p>
                    <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Date of Receipts : <span className='font-bold'>{studentData[program].payDate}</span> </p>
                    <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Payment Type : <span className='font-bold'>Online</span> </p>
                    <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Application Fee : <span className='font-bold'>{studentData[program].amount}</span> </p>
                    <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem', marginBottom: '1rem' }}>Transaction Id : <span className='font-bold'>{studentData[program].transId}</span> </p>
                  <div className='w-[100%] m-auto text-center' style={{width:'100%', margin:'auto',lineHeight:'4px',fontSize:'13px'}}>
                  <p className='font-bold'>
                  &#9745;	 I, accept that fees paid is non refundable.
                  </p>
                  <p>
                    This is system generated receipt and does not require signature.
                  </p>
                  </div>
                  </div>
                </div>
              </div>
              <center className='pb-5 mt-2'>
                <MDBBtn onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} color='success'>Close</MDBBtn>
                <MDBBtn className='ml-3' color='primary' onClick={handlePrint}>Print</MDBBtn>
              </center>
            </MDBModalBody>
            {/* <MDBModalFooter> */}
            {/* </MDBModalFooter> */}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* // modal end // */}
      <MDBModal show={optSmModal} tabIndex='-1' setShow={setOptSmModal}>
        <MDBModalDialog size='fullscreen '>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle className='text-black font-bold'>Preview Of Your Application</MDBModalTitle> */}
              <MDBBtn className='btn-close' color='none' onClick={toggleShow1}></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>

              {/* <Testing studentData={studentData} /> */}
              {/* <AcknowledgeMentPrint studentData={studentData} /> */}

            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
      }
    </>
  )
}

export default PaymentThankYou