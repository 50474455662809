// import { useState } from "react";
// import Chart_fill from './assets/Chart_fill.png'
// import Chat from './assets/Chat.png'
// import User from './assets/User.png'
// import Calendar from './assets/Calendar.png'
// import Search from './assets/Search.png'
// import Chart from './assets/Chart.png'
// import Folder from './assets/Folder.png'
// import Setting from './assets/Setting.png'
// import control from './assets/control.png'
// import logo from './assets/logo.png'

// const Trial = () => {
//   const [open, setOpen] = useState(true);
//   const Menus = [
//     { title: "Dashboard", src: Chart_fill },
//     { title: "Inbox", src: Chat },
//     { title: "Accounts", src: User, gap: true },
//     { title: "Schedule ", src: Calendar },
//     { title: "Search", src: Search },
//     { title: "Analytics", src: Chart },
//     { title: "Files ", src: Folder, gap: true },
//     { title: "Setting", src: Setting },
//   ];

//   return (
//     <div className="flex">
//       <div
//         className={` ${
//           open ? "w-72" : "w-20 "
//         }  bg-black  h-screen p-5   pt-8 relative duration-300`}
//       >
//         <img
//           src={control}
//           className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
//            border-2 rounded-full  ${!open && "rotate-180"}`}
//           onClick={() => setOpen(!open)}
//         />
//         <div className="flex gap-x-4 items-center">
//           <img
//             src={logo}
//             className={`cursor-pointer duration-500 ${
//               open && "rotate-[360deg]"
//             }`}
//           />
//           <h1
//             className={`text-white origin-left font-medium text-xl duration-200 ${
//               !open && "scale-0"
//             }`}
//           >
//             Designer
//           </h1>
//         </div>
//         <ul className="pt-6">
//           {Menus.map((Menu, index) => (
//             <li
//               key={index}
//               className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
//               ${Menu.gap ? "mt-9" : "mt-2"} ${
//                 index === 0 && "bg-light-white"
//               } `}
//             >
//               <img src={Menu.src} />
//               <span className={`${!open && "hidden"} origin-left duration-200`}>
//                 {Menu.title}
//               </span>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div className="h-screen flex-1 p-7">
//         <h1 className="text-2xl font-semibold ">Home Page</h1>
//       </div>
//     </div>
//   );
// };
// export default Trial;





import React from 'react'

function Trial() {
  return (
    <div>
     
       
        <div class="bg-cover h-full" 
        style={{backgroundImage: "url(https://lmimirror3pvr.azureedge.net/static/media/18461/3c152e9a-731d-45db-a205-5da7abe818b1/training-load-960x540.jpg)",height:'100vh'}}
        >
          
          
          <div className='grid grid-rows-1 grid-flow-col text-white text-2xl text-center pt-3 '>
            <div>
              FIT INDIA CLUB
            </div>
            <div className='grid grid-rows-1 grid-flow-col gap-0'>
              <div>Yoga</div>
              <div>Dance</div>
              <div>Gym</div>
            </div>
            <div><input type="text" /></div>
          </div>
          
          </div>
     
    </div>

  )
}

export default Trial