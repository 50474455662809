import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
function UpperNavbar({data}) {
    console.log("ddadd",data)
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [imgUrl, setImgUrl] = useState('')

    console.log("imgUrl",imgUrl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(()=>{
        if(data){
            if(data.educationDetails){
                if(data.educationDetails.photo){
                        console.log('photo', data.educationDetails.photo)
                    setImgUrl(data.educationDetails.photo)
                }
                else{
                    setImgUrl('https://aicee.s3.ap-south-1.amazonaws.com/aicee-dummy-avatar-dashboard.png')
                }
            }else{
                setImgUrl('https://aicee.s3.ap-south-1.amazonaws.com/aicee-dummy-avatar-dashboard.png')
            }
        }
        
    },[data,imgUrl])
    return (
        <div className='upperNav items-center flex flex-row-reverse justify-between' style={{ height: '3.2rem', backgroundColor: 'white', boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}>
            <div className='items-center pl-5 pr-5' style={{ marginRight: '2rem', borderLeft: '1px solid #E5E5E5', borderRight: '1px solid #E5E5E5', height: '100%' }}>
                <Button
                    className='upperNav2 mt-0 text-black'
                    style={{ textTransform: 'capitalize',alignItems:'center',display:'flex',gap:'5px' }}
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <span className=''>Welcome {data && data.name}</span>
                    <img className='w-[40px] border border-gray-700 h-[40px] rounded-[50%] p-[3px] '  src={imgUrl} alt="" />
                    
                </Button>
                <Menu
                    className='pl-5'
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem className='pl-5' onClick={() => { navigate('/student-dashboard') }}>Dashboard</MenuItem>
                    <MenuItem className='pl-5' onClick={() => { navigate('/my-profile') }}>Profile</MenuItem>
                    <MenuItem className='pl-5' onClick={() => { navigate('/my-profile') }}>Change Password</MenuItem>
                    <MenuItem className='pl-5' onClick={() => { navigate('/logout') }}>Logout</MenuItem>
                </Menu>
            </div>
            <div className='flex items-center'>
                <img style={{ width: '55%', padding: '0.5rem 1rem' }} src="https://ucarecdn.com/c048ae5b-1a85-481d-9ba1-0dfb344701af/AICEE2023LOGOPNG.png" alt="" />
            </div>
        </div>
    )
}

export default UpperNavbar