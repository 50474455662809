import React, { useState, useEffect, useRef } from 'react'
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, } from 'mdb-react-ui-kit'
import UpperNavbar from '../Dashboard/UpperNavbar'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
function Step6() {
  const navigate = useNavigate()

  const [studentData, setStudentData] = useState({})

  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  //   START MODAL
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);

  //    END MODAL

  //    SUBMIT APPLICATION OF MBA / PGDM
  ///       SAVE APPLICATION NO
  async function submitApplicationMba() {
    // event.preventDefault()
    const response = await fetch('/submit-application-mba', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message === "changes successfully") {
          // alert('changes successfully')
          // setCentredModal(false)
          navigate('/student-dashboard')
        }
        else {
          alert('Error')
          // setCentredModal(false)
        }
      })
  }
  useEffect(() => {
    userAuthenticationData()
  }, [])

  ///   print functionallity
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: "@page { margin-top: 50px;  } ",
    content: () => componentRef.current,
  });
  return (
    <div>
      <UpperNavbar />
      <h2 className='text-center pt-2 text-black font-bold'>APPLICATION 2025</h2>
      <div className='flex  justify-around mt-3' style={{ width: '90%', margin: 'auto' }}>
        <div style={{ width: '16%' }} className=' text-green-700 font-bold text-center text-4xl '><MDBIcon fas icon="user-edit" /><br /><span style={{ fontSize: '1rem', color: 'black' }}>Application Details</span></div>
        <div style={{ width: '16%' }} className='text-green-700 font-bold text-center  text-4xl '><MDBIcon fas icon="user-alt" /><br /><span style={{ fontSize: '1rem', color: 'black' }}>Personal Details</span></div>
        <div style={{ width: '16%' }} className='text-green-700 font-bold text-center  text-4xl '><MDBIcon fas icon="map-marker-alt" /><br /><span style={{ fontSize: '1rem', color: 'black' }}>Address Details</span></div>
        <div style={{ width: '16%' }} className='text-green-700 font-bold text-center  text-4xl '><MDBIcon fas icon="pen-nib" /><br /><span style={{ fontSize: '1rem', color: 'black' }}>Examination Details</span></div>
        <div style={{ width: '16%' }} className='text-green-700 font-bold text-center  text-4xl '><MDBIcon className='' fas icon="file" /><br /><span style={{ fontSize: '1rem', color: 'black' }}>Education Details</span></div>
        <div style={{ width: '16%' }} className='text-green-700 text-center  text-4xl '><MDBIcon fas icon="id-card" /><br /><span style={{ fontSize: '1rem', color: 'black', fontWeight: 'bold' }}>Payment</span></div>
      </div>








      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form' style={{ margin: '1rem 3rem' }}>
        <form className="row g-3 items-center">
          {/* <button style={{width:'50%',margin:'auto'}} type="button" className="text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-5 mb-8 ">Proceed for Payment</button> */}
          <div className="payment-form" >
            <form method="POST" name="customerData" action="/ccavRequestHandler">
              <table style={{ display: 'none' }} width="40%" height="100" border='1' align="center">
                <caption>
                  <font size="4" color="blue"><b>Integration Kit</b></font>
                </caption>
              </table>
              <table width="40%" height="100" border='1' align="center" style={{ border: 'none' }}>
                <tr style={{ display: 'none' }}>
                  <td>Parameter Name:</td>
                  <td>Parameter Value:</td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td colspan="2">Compulsory information</td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Merchant Id</td>
                  <td><input type="text" name="merchant_id" id="merchant_id" value="241419" /> </td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Order Id</td>
                  <td><input type="text" name="order_id" value={studentData.MBAApplicationNo} /></td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Currency</td>
                  <td><input type="text" name="currency" value="INR" /></td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Amount</td>
                  <td><input type="text" name="amount" value="2600" /></td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Redirect URL</td>
                  <td><input type="text" name="redirect_url"
                    value="https://counselling.aicee.in/ccavResponseHandlerMBA" />
                  </td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Cancel URL</td>
                  <td><input type="text" name="cancel_url"
                    value="https://counselling.aicee.in/ccavResponseHandlerMBA" />
                  </td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Language</td>
                  <td><input type="text" name="language" id="language" value="EN" /></td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td colspan="2">Billing information(optional):</td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Billing Name</td>
                  <td><input type="text" name="billing_name" value={studentData.studentName} /></td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td>Billing Email:</td>
                  <td><input type="text" name="billing_email"
                    value={studentData.email} /></td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    <center>
                      <div style={{ textAlign: "center" }}>
                        <button
                          // disabled={checked == false}
                          type="submit"
                          style={{ width: '100%', margin: 'auto' }} className="text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-5 mb-8 "

                        >
                          Proceed for Payment

                        </button>
                      </div>
                    </center>
                  </td>
                </tr>
              </table>
            </form>
          </div>


        </form>
        <div className="col-12 flex justify-between flex-row-start">
          {/* <button onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} type="submit" className="btn btn-primary">Save & Continue</button> */}
          <button onClick={() => { navigate('/fillApplication-step5') }} type="submit" className="btn btn-primary">Back</button>
        </div>
      </div>

      {/* // START  MODAL    // */}
      <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
        <MDBModalDialog size='fullscreen-xxl-down'>
          <MDBModalContent>
            <MDBModalBody>
              <div style={{ width: '700px', margin: 'auto',height:'900px' }} className='border border-black' ref={componentRef}>
                <div className='m-5 border border-black'>
                  <div className='m-4'>
                    <img style={{width:'11rem',margin:'auto'}} src="https://cdn.npfs.co/uploads/college/image/5dd62f069bf79311568257_logo.jpg" alt="" />
                  </div>
                  <div className='text-center text-black'>
                    <h5 >All India Combined Entrance Examintaion</h5>
                  </div>
                  <div className='text-center text-black font-bold '>
                    <h5 className='font-bold' style={{marginTop:'1.3rem',textDecoration:'underLine'}}>Electronic Receipt</h5>
                  </div>
                  <div className='text-center text-black font-bold '>
                    <h6 className='' style={{marginTop:'1.3rem'}}>Application No : <span className='font-bold'>{studentData.MBAApplicationNo}</span></h6>
                  </div>
                  <div className='text-start pl-3 text-black mt-1' style={{fontSize:'0.9rem'}}>
                    <p className='' style={{marginTop:'1.8rem'}}>To  <span className='font-bold'>{studentData.studentName}</span></p>
                    <p className='font-bold' style={{marginTop:'-0.8rem',fontSize:'0.8rem'}}>Online Form Submission Successful. </p>
                    <p className='' style={{marginTop:'-0.8rem',fontSize:'0.8rem'}}>Amount Received towards  <span className='font-bold'>PG Application Form 2023 <br />(MBA / PGDM)</span> </p>
                    <p className='' style={{marginTop:'-0.8rem',fontSize:'0.8rem'}}>Date of Receipts : <span className='font-bold'>28-09-2022</span> </p>
                    <p className='' style={{marginTop:'-0.8rem',fontSize:'0.8rem'}}>Payment Type : <span className='font-bold'>Online</span> </p>
                    <p className='' style={{marginTop:'-0.8rem',fontSize:'0.8rem'}}>Application Fee : <span className='font-bold'>1400.00</span> </p>
                    <p className='' style={{marginTop:'-0.8rem',fontSize:'0.8rem',marginBottom:'3rem'}}>Transaction Id : <span className='font-bold'>456454545446545</span> </p>
                  </div>
                </div>
              </div>
              <center className='pb-5 mt-2'>
                <MDBBtn color='success' onClick={()=>{navigate('/fillApplication-step1')}}>Edit</MDBBtn>
                <MDBBtn className='ml-3' color='primary' onClick={()=>{submitApplicationMba()}}>Submit</MDBBtn>
              </center>
            </MDBModalBody>
            {/* <MDBModalFooter> */}
            {/* </MDBModalFooter> */}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* // END  MODAL    // */}
    </div>
  )
}

export default Step6



