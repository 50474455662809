import React from 'react'

const ApplicationDetails = () => {
  return (
    <div className='mt-3'><form className="row g-3">
      <div className="col-md-4">
        <label htmlFor="inputEmail4" className="form-label">
          Name Of Candidate
        </label>
        <input type="email" className="form-control" id="inputEmail4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputEmail4" className="form-label">
        Mobile No
        </label>
        <input type="email" className="form-control" id="inputEmail4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Email ID
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Gender
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        DOB
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Father's Name
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Mother's Name
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Category
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-12">
        <label htmlFor="inputPassword4" className="form-label">
        Address Line 1
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-12">
        <label htmlFor="inputPassword4" className="form-label">
        Address Line 2
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Country
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        City
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        State
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Pincode
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputPassword4" className="form-label">
        Country
        </label>
        <input type="text" className="form-control" id="inputPassword4" />
      </div>
      
     


    </form>
    </div>
  )
}

export default ApplicationDetails