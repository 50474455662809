import React, { useState, useEffect } from 'react'
import h_images from './h_images/newicon.gif'
import {Link} from 'react-router-dom'
function Marque() {
  const [makeydata, setMarkeyData] = useState([])
  console.log(makeydata)



  const markeyData = async () => {
    fetch('/markey-data')
      .then(response => response.json())
      .then(data => {
        setMarkeyData(data)


      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    markeyData()
  }, [])
  return (
    <div>
      <section className="marqueesection">

        <marquee behavior="" direction="" className="marqueestyle flex"
        >

          <div className='flex gap-6'>

            {/* <button className="marqueetext" style={{ display: 'flex' }}>
              <a href="" target='blank' style={{ textTransform: 'none', color: 'black' }}>
                Few Students who applied for AICEE 2022 Refund ( more than one times ) Will get there refund in between 7th to 12th Oct, 2022 (After verification).</a>
              <img src={h_images} />
            </button>
            <button className="marqueetext" style={{ display: 'flex' }}>
              <a href="" target='blank' style={{ textTransform: 'none', color: 'black' }}>
                Application for AICEE 2023 Will start from 10th Nov, 2022.</a>
              <img src={h_images} />
            </button> */}
            {
              makeydata.length >= 1 ? <>
                {
                  makeydata.map((e, i) => {
                    
                    return (
                      <>
                        <button className="marqueetext" style={{ display: 'flex' }}>
                          <a href={e.markeyUrl} target='_blank' style={{ textTransform: 'none', color: 'black' }}>
                            {e.markeyName}
                          </a>
                          <img src={h_images} />
                        </button>
                      </>
                    )
                  })
                }
              </> : null
            }
          </div>


        </marquee>


      </section>
    </div>
  )
}

export default Marque