import React,{useState} from "react";

function Otp() {
    //open modal
    const [showModal, setShowModal] = useState(true);
    return (
        <div>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                           
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                               
                                <div>
                                    <div className="text-center text-white p-2 font-semibold " style={{ backgroundColor: '#04124f' }}>Verify OTP</div>
                                    <div className="pt-3 pl-4 pr-8">
                                        <label htmlFor="" className="text-black font-semibold">Email OTP</label><br />
                                        <div className="flex justify-between">
                                            <input className="border-dashed border-2 border-black ... outline-none" type="text" />
                                            <button className="pl-3 pr-3 text-white text-sm rounded-lg" style={{ backgroundColor: '#04124f' }}>Verify</button>
                                        </div>
                                    </div>
                                    <div className="pt-3 pl-4 pr-8">
                                        <label htmlFor="" className="text-black font-semibold">Mobile OTP</label><br />
                                        <div className="flex justify-between">
                                            <input className="border-dashed border-2 border-black ... outline-none" type="text" />
                                            <button className="pl-3 pr-3 text-white text-sm rounded-lg" style={{ backgroundColor: '#04124f' }}>Verify</button>
                                        </div>
                                    </div>
                                    <center><button className="pt-2 pb-2 pl-6 pr-6 text-white text-sm rounded-lg mt-4" style={{ backgroundColor: '#04124f' }}>Submit</button></center>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </div>
    )
}

export default Otp


{/* <div>
<div className="text-center text-white p-2 font-semibold " style={{ backgroundColor: '#04124f' }}>Verify OTP</div>
<div className="pt-3 pl-4 pr-8">
    <label htmlFor="" className="text-black font-semibold">Email OTP</label><br />
    <div className="flex justify-between">
        <input className="border-dashed border-2 border-black ... outline-none" type="text" />
        <button className="pl-3 pr-3 text-white text-sm rounded-lg" style={{ backgroundColor: '#04124f' }}>Verify</button>
    </div>
</div>
<div className="pt-3 pl-4 pr-8">
    <label htmlFor="" className="text-black font-semibold">Mobile OTP</label><br />
    <div className="flex justify-between">
        <input className="border-dashed border-2 border-black ... outline-none" type="text" />
        <button className="pl-3 pr-3 text-white text-sm rounded-lg" style={{ backgroundColor: '#04124f' }}>Verify</button>
    </div>
</div>
<center><button className="pt-2 pb-2 pl-6 pr-6 text-white text-sm rounded-lg mt-4" style={{ backgroundColor: '#04124f' }}>Submit</button></center>
</div> */}