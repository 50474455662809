import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
const RefundForm = (props) => {
    const onSubmit = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("pdf", file);
        // Make an API call to upload the file
        fetch(`/refund/paymentSlip/${props.data._id}`, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the API
                // alert('success')
                alert("Document Has been Uploaded Successfully")

                console.log(data);
            })
            .catch((error) => {
                // Handle errors from the API call
                alert('Something went wrong')
                console.error(error);
            });
    }
    const onSubmit2 = async (e) => {

        // alert('helo')
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("pdf", file);
        // Make an API call to upload the file
        fetch(`/refund/passbook/${props.data._id}`, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                alert("Document Has been Uploaded Successfully")
                // Handle the response from the API
                // alert('success')

                console.log(data);
            })
            .catch((error) => {
                // Handle errors from the API call
                alert('something went wrong')
                console.error(error);
            });
    }


    const [applicationNo, setApplicationNo] = useState('')
    const [studentName, setStudentName] = useState('')
    const [refundOf, setrefundOf] = useState('')
    const [accoundHolderName, setaccoundHolderName] = useState('')
    const [accountNo, setaccountNo] = useState('')
    const [confAccountNo, setconfAccountNo] = useState('')
    const [bankName, setbankName] = useState('')
    const [ifscCode, setifscCode] = useState('')


    const finalSubmit = async (e) => {
        e.preventDefault()
        axios.post(`/refund/student/${props.data._id}`, {
            applicationNo, studentName, refundOf, accoundHolderName, accountNo, confAccountNo, bankName, ifscCode
        })
            .then(function (response) {
                console.log(response.data.message)
                alert(response.data.message)
                props.onClick()
                // alert('College Choices saved successfully')
            })
            .catch(function (error) {
                alert(error.response.data.message)
                // alert(error.response.data.message)
            });
    }
    // const {applicationNo,studentName,refundOf,accoundHolderName,accountNo,confAccountNo,bankName,ifscCode} = req.body

    return (
        <Form>
            <div className="container">
                <form action="#" onSubmit={finalSubmit}>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Application No: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            type="number"
                            className="form-control"
                            id="fname"
                            placeholder="Enter Your Application No"
                            name="fname"
                            value={applicationNo}
                            onChange={(e) => { setApplicationNo(e.target.value) }}
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Name of the Student: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="Enter Name of The Student"
                            name="fname"
                            value={studentName}
                            onChange={(e) => { setStudentName(e.target.value) }}
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Refund of: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <select class="form-select" aria-label="Default select example" value={refundOf}
                            onChange={(e) => { setrefundOf(e.target.value) }}>
                            <option selected>------Select---- <i className='fa fa-angle-down text-black-500'/></option>
                            <option value="Counselling Fee">Counselling Fee</option>
                            <option value="Seat Confirmation Fee">Seat Confirmation Fee</option>

                        </select>
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Payment Slip: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            type="file"
                            className="form-control"
                            id="fname"
                            placeholder="Enter First Name"
                            name="fname"
                            onChange={(e) => { onSubmit(e) }}
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Account Holder Name: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            value={accoundHolderName}
                            onChange={(e) => { setaccoundHolderName(e.target.value) }}
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="Account Holder Name"
                            name="fname"
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Account No: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            value={accountNo}
                            onChange={(e) => { setaccountNo(e.target.value) }}
                            type="number"
                            className="form-control"
                            id="fname"
                            placeholder="Account No"
                            name="fname"
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Confirm Account No: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            value={confAccountNo}
                            onChange={(e) => { setconfAccountNo(e.target.value) }}
                            type="number"
                            className="form-control"
                            id="fname"
                            placeholder="Confirm Account No"
                            name="fname"
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Bank Name: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            value={bankName}
                            onChange={(e) => { setbankName(e.target.value) }}
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="Bank Name"
                            name="fname"
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>IFSC Code: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            value={ifscCode}
                            onChange={(e) => { setifscCode(e.target.value) }}
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="IFSC Code"
                            name="fname"
                        />
                    </div>
                    <div className="form-group sm:flex sm:mt-3">
                        <label htmlFor="fname " className='sm:w-[1000px]'>Image of Cancelled Cheque / 1st Page of Passbook: <span className='text-red-500 font-bold text-2xl'>*</span></label>
                        <input
                            type="file"
                            className="form-control"
                            height={50}
                            id="fname"
                            placeholder="Enter First Name"
                            name="fname"
                            onChange={(e) => { onSubmit2(e) }}
                        />
                    </div>
                    <div className="form-group  sm:mt-3">
                        <li>Don't Make any false Claims otherwise Refund will be restricted.</li>
                        <li>Dont't Apply twise Otherwise Claim will be rejected.</li>
                    </div>


                    <center><button type="submit" className="btn text-white mt-4" style={{ backgroundColor: '#042e2e' }}>
                        Submit
                    </button></center>
                </form>
            </div>
        </Form>
    )
}

const Form = styled.main`
    input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance : textfield; /* Firefox */
}
`

export default RefundForm