import React from 'react'
import logo from '../../Images/aicee2023logo.png'
const Login = () => {
    return (
        <div className="relative flex flex-col items-center justify-center h-screen overflow-hidden bg-gray-700">
            <div className="w-full p-6 bg-gray-800  border-gray-600 rounded-md shadow-md  lg:max-w-lg">
                <center>
                    {/* <img className="w-[100px]" src={logo} alt="" /> */}
                    <i className='fas fa-school' style={{color:'white',fontSize:'55px'}}></i>
                </center>
                <center style={{color:'white',fontSize:'20px'}} className='mt-2'>Institution Login</center>
                <form className="space-y-4">
                    <div>
                        <label className="label">
                            <span className="text-base label-text text-gray-200">Collge Code</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Collge Code"
                            className="w-full input input-bordered"
                            style={{outline:'none',padding:'0.5em'}}
                        />
                    </div>
                    <div>
                        <label className="label">
                            <span className="text-base label-text text-gray-200">Password</span>
                        </label>
                        <input
                            type="password"
                            placeholder="Enter Password"
                            className="w-full input input-bordered"
                            style={{outline:'none',padding:'0.5em'}}
                        />
                    </div>
                    <a
                        href="#"
                        className="text-xs text-gray-300 hover:underline hover:text-blue-600"
                    >
                        Forget Password?
                    </a>
                    <div>
                        <button className="btn btn-block" style={{backgroundColor:'white'}} onClick={()=>{alert('Invalid Credentials')}}>Login</button>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default Login