import React from 'react'

const DocumentDetails = () => {
    return (
        <div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 '>
            <div className='mt-4'>
                <a href="" target='_blank' className='bg-[#3d3939] p-3 rounded-xl text-white'>10th Marksheet Download</a>
            </div>
            <div className='mt-4'>
                <a href="" target='_blank' className='bg-[#3d3939] p-3 rounded-xl text-white'>12th Marksheet Download</a>
            </div>
            <div className='mt-4'>
                <a href="" target='_blank' className='bg-[#3d3939] p-3 rounded-xl text-white'>Rank Card</a>
            </div>
            <div className='mt-4'>
                <a href="" target='_blank' className='bg-[#3d3939] p-3 rounded-xl text-white'>Allotment Letter</a>
            </div>
            <div className='mt-4'>
                <a href="" target='_blank' className='bg-[#3d3939] p-3 rounded-xl text-white'>Photo</a>
            </div>
            <div className='mt-4'>
                <a href="" target='_blank' className='bg-[#3d3939] p-3 rounded-xl text-white'>Signature</a>
            </div>
            </div>
        </div>
    )
}

export default DocumentDetails