import React,{useState,useEffect} from 'react'
import { MDBContainer, MDBCard, MDBRow, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
const DashboardCard = ({data}) => {

    const [progress,setProgress] = useState(0)
    const [completed,setCompleted] = useState(0)
    const [mba,setMba] = useState()
    const [engg,setEngg] = useState()
    const [pcm,setPcm] = useState()
    const [pcb,setPCb] = useState()

    console.log("data.........", data)
    
    // useEffect(()=>{
    //     if(data){  
    //         if(data.mba){
    //             if(data.mba.payStatus == true){
    //                 setCompleted(completed)
    //             }
    //             else{
    //                 setProgress(progress+1)
    //             }
    //         }
    //     }
    //     else{

    //     }
    // },[data])
    return (
        <div>
            <MDBContainer className='mt-4'>
                <MDBRow>
                    <MDBCol size='md'>
                        <MDBCard style={{ padding: '0', backgroundColor: '#042e2e' }} className='w-100 text-white'>
                            <MDBCardBody className='flex justify-between items-center p-2 pl-4 pr-5'>
                                <div className='pl-4'>
                                    <MDBCardTitle className='text-2xl text-bold flex justify-between'>
                                        {/* {mba+engg+pcm+pcb+other} */}
                                       {data && data.application_progress ? data.application_progress : 0}
                                    </MDBCardTitle>
                                    <MDBCardText className='cursor-pointer'>

                                        Application In  Progress .

                                    </MDBCardText>
                                </div>
                                <div className='pr-2'>
                                    <span className='text-4xl'>
                                        <MDBIcon fas icon="hourglass-half" />
                                    </span>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard className='w-100' >
                            <MDBCardBody className='flex justify-between items-center p-2 pl-4 pr-5'>
                                <div className='pl-4'>
                                    <MDBCardTitle className='text-2xl text-bold flex justify-between'>
                                    {data && data.application_completed ? data.application_completed : 0}
                                        {/* {mbaCompleted+enggCompleted+pcmCompleted+pcbCompleted+otherCompleted} */}
                                    </MDBCardTitle>
                                    <MDBCardText className='cursor-pointer'>

                                        Application Completed .

                                    </MDBCardText>
                                </div>
                                <div className='pr-2'>
                                    <span className='text-4xl'>
                                        <MDBIcon fas icon="paste" />
                                    </span>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size='md'>
                        <MDBCard className='w-100'>
                            <MDBCardBody className='flex justify-between items-center p-2 pl-4 pr-5'>
                                <div className='pl-4'>
                                    <MDBCardTitle className='text-2xl text-bold flex justify-between'>No</MDBCardTitle>
                                    <MDBCardText className='cursor-pointer'>

                                        Query Raised .

                                    </MDBCardText>
                                </div>
                                <div className='pr-2'>
                                    <span className='text-4xl'>
                                        <MDBIcon far icon="comments" />
                                    </span>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    )
}

export default DashboardCard