import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import logo1 from './img/logo1.png'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
   
    MDBCollapse
} from 'mdb-react-ui-kit';
import styled from 'styled-components';
function Header() {
    const [showNavSecond, setShowNavSecond] = useState(true);

    return (
        <React.Fragment>
            <div>
                <div id="fb-root"></div>
                <section className="govtinfo">
                    <div className="container-fluid">
                        <div className="row  justify-content-between">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <ul className="list-unstyled list-inline">

                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <ul className="list-unstyled list-inline" style={{ float: 'right', paddingTop: '5px' }}>

                                    <li className="list-inline-item org_list">
                                        <a className="org_name" title="Skip to main content" href="#skipcontent">
                                            AICEE
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="headertop menusection2">
                    <div className="container-fluid">
                        <div className="row">
                            {/* lg:w-28 w-48 */}
                            <div className="col-lg-2 col-md-3 col-sm-3 col-xs-12 imgsec">
                                <center><Link to="/"><img className=' lg:mr-12 landingLogo' src={logo1} /></Link></center>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">

                                <h1 className="headerH1">
                                    ALL INDIA COMBINED ENTRANCE EXAMINATION
                                </h1>
                                <h4 className="headerH4" >AICEE - 2025</h4>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-3 col-xs-12">
                                <img src="" />
                            </div>
                        </div>
                    </div>
                </section>
                <HeaderCss>
                <MDBNavbar expand='lg' light className='menusection p-0 ' >
                    <MDBContainer fluid >

                        <MDBNavbarToggler
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => setShowNavSecond(!showNavSecond)}
                        >
                           
                            {/* <MDBIcon className='text-white p-2 borde font-thin' icon='bars' fas /> */}
                            {/* <span className="navbar-toggler-icon border text-white"></span>   */}
                            <i class="fas fa-bars text-2xl text-gray-300 border border-gray-400 pl-3 pr-3"></i>
                        </MDBNavbarToggler>
                        <MDBCollapse navbar show={showNavSecond}>
                       
                            <MDBNavbarNav className='' style={{ fontSize: '0.8rem' }}>
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <Link className='nav-link' to="/" target='_self'> Home </Link>
                                    </li>
                                </ul>
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <a className='nav-link' href='https://aicee.s3.ap-south-1.amazonaws.com/AICEE%20-%202025%20INFORMATION%20BROCHURE.pdf' target='_blank'> Information
                                            Brochure 2025 </a>
                                    </li>
                                </ul>
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <Link className='nav-link' to="/eligibility" >Eligibility
                                            For AICEE 2025</Link>
                                    </li>
                                </ul>
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <Link className='nav-link' to="/contact" target='_self'> Contact Us </Link>
                                    </li>
                                </ul>

                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
                </HeaderCss>
                {/* <section className="menusection">
                    <div className="container-fluid">
                        <nav className="navbar navbar-dark navbar-expand-md navmenu">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
                                onClick="myFunction2()">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" style={{ fontSize: '0.8rem',fontFamily:'Open Sans Condensed',fontWeight:'500' }} id="myDropdown2">
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <Link className='nav-link' to="/" target='_self'> Home </Link>
                                    </li>
                                </ul>
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <a className='nav-link' href="#" target='_blank'> Information
                                            Brochure 2023 </a>
                                    </li>
                                </ul>
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <Link className='nav-link' to="/eligibility" >Eligibility
                                            For AICEE 2023</Link>
                                    </li>
                                </ul>
                                <ul className="navbar-nav topmenulink">
                                    <li className="nav-item topmenulist" style={{ listStyle: 'none' }}>
                                        <Link className='nav-link' to="/contact" target='_self'> Contact Us </Link>
                                    </li>
                                </ul>

                            </div>
                        </nav>
                    </div>
                </section> */}
            </div>


        </React.Fragment>
    )
}

const HeaderCss = styled.main`
    *{
        visibility: visible;
    }
`

export default Header