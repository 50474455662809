import React,{useState,useEffect} from 'react'
import MyPaymentss from '../Pages/Dashboard/MyPayments';
import SideBar from '../Pages/Dashboard/SideBar';
import UpperNavbar from '../Pages/Dashboard/UpperNavbar';
import '../Pages/Dashboard/Dashboard.css'
import { MDBContainer, MDBCard, MDBRow, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import PaymentReceipt from './applicationStages/PaymentReceipt';
import PaymentTable from './applicationStages/PaymentTable';
function MyPayments() {
  const [data,setData] = useState()
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setData(data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(()=>{
    userAuthenticationData()
  },[])
  return (
    <div className='paymentPage'>
      <div style={{ height: '100vh', overflow: 'scroll initial', width: '100%' }}>
        <div>
          
          {data &&  <UpperNavbar data={data}/>}

        </div>
        <div className='flex' style={{ height: '90vh' }}>
          <SideBar />
          <div className='mt-2' style={{ backgroundColor: '#f1f3fa', width: '100%', height: '90vh', overflow: 'scroll' }}>
            <h3 className='pt-3 pl-3 pr-3 text-black text-2xl' style={{ fontWeight: '500' }}>My Payments</h3>
            <hr />
            {/* <MyPaymentss data={data}/> */}

            {/* MBA PGDM */}
            {data && <>
              {
              data.mba && <>
                {
                  data.mba.payStatus && <PaymentTable data={data} program="mba" title="MBA/PGDM Program"/>
                }
              </>
            }
            </>}
            
              {/* Engineering */}
              {data && <>
              {
              data.engg && <>
                {
                  data.engg.payStatus && <PaymentTable data={data} program="engg" title="Engineering Program"/>
                }
              </>
            }
            </>}
              {/* PCM */}
              {data && <>
              {
              data.pcm && <>
                {
                  data.pcm.payStatus && <PaymentTable data={data} program="pcm" title="PCM Program"/>
                }
              </>
            }
            </>}
              {/* PCB */}
              {data && <>
              {
              data.pcb && <>
                {
                  data.pcb.payStatus && <PaymentTable data={data} program="pcb" title="PCB Program"/>
                }
              </>
            }
            </>}
              {/* Other */}
              {data && <>
              {
              data.other && <>
                {
                  data.other.payStatus && <PaymentTable data={data} program="other" title="Other Program"/>
                }
              </>
            }
            </>}

            {/* <PaymentReceipt data={data} program="mba"/> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyPayments