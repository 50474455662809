import React, { useState, useEffect } from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'

import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function Step3({ data, setActiveStep, title, getUpdate }) {
  const navigate = useNavigate()
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [pincode, setPincode] = useState('')
  const [paddressLine1, setpAddressLine1] = useState('')
  const [paddressLine2, setpAddressLine2] = useState('')
  const [pcity, setpCity] = useState('')
  const [pstate, setpState] = useState('')
  const [ppincode, setpPincode] = useState('')
  // console.log(addressLine1)
  const [open, setOpen] = React.useState(false);


  //       UPDATE ADDRESS DETAILS OF USER
  async function updateAddress(event) {
    event.preventDefault()

    await fetch(`/api/v2/address-details/${data.personalDetails._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        addressLine1,
        addressLine2,
        city,
        state,
        pincode,
        paddressLine1,
        paddressLine2,
        pcity,
        pstate,
        ppincode

      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          setActiveStep(4)
          getUpdate()
        }
        else {
          alert(data.message)
        }

      })
  }
  const nextStep = () => {
    if (data.city) {
      setActiveStep(4)
    }
  }
  useEffect(() => {
    if (data.personalDetails) {
      setPincode(data.personalDetails.pincode)
      setAddressLine1(data.personalDetails.addressLine1)
      setAddressLine2(data.personalDetails.addressLine2)
      setState(data.personalDetails.state)
      setCity(data.personalDetails.city)
      setpPincode(data.personalDetails.ppincode)
      setpAddressLine1(data.personalDetails.paddressLine1)
      setpAddressLine2(data.personalDetails.paddressLine2)
      setpState(data.personalDetails.pstate)
      setpCity(data.personalDetails.pcity)
    }
  }, [data])
console.log(paddressLine1,
  paddressLine2,
  pcity,
  pstate,
  ppincode)
  const copyAddress = (isSame)=>{
    
   
      setpPincode(pincode)
      setpAddressLine1(addressLine1)
      setpAddressLine2(addressLine2)
      setpState(state)
      setpCity(city)
   
    
  }
  const DonotCopy = ()=>{
    
      setpPincode(" ")
      setpAddressLine1(" ")
      setpAddressLine2(" ")
      setpState(" ")
      setpCity(" ")
    
  }
  
  return (
    <div className='paymentPage'>

      <h2 className='applicationCSS4 text-center pt-2 text-black font-bold'>{title}</h2>
      <div className='applicationCSS3'>
        <img className='fluid applicationCSS2' style={{ margin: 'auto' }} src="https://ucarecdn.com/e853544e-4a83-4196-9e8d-4fe7520ca2f4/step3.png" alt="" />
      </div>








      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form applicationCSS' >
        <form className="row g-3">
          <div className="col-md-12 ">
            <p className='text-[18px] font-bold' style={{ textDecoration: 'underline' }}>Correspondence Address :- </p>
            <label for="inputEmail4" className="form-label text-black font-bold">Address Line 1</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Address Line 1' style={{ outline: 'none' }} type="text" value={addressLine1} onChange={(e) => { setAddressLine1(e.target.value) }} className="form-control shadow-none text-black" id="inputEmail4" />
          </div>
          <div className="col-md-12">
            <label for="inputPassword4" className="form-label text-black font-bold">Address Line 2</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Address Line 2' type="text" className="form-control shadow-none text-black" value={addressLine2} onChange={(e) => { setAddressLine2(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">Country</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled placeholder='Enter Your City' type="text" className="cursor-not-allowed form-control shadow-none text-black" value={data.country} onChange={(e) => { setCity(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">City</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Enter Your City' type="text" className="form-control shadow-none text-black" value={city} onChange={(e) => { setCity(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">State</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" placeholder='Enter your State' className="form-control shadow-none text-black" value={state} onChange={(e) => { setState(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">Pincode</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Enter Your Pincode' type="phone" className="form-control shadow-none text-black" value={pincode} onChange={(e) => { setPincode(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-12 ">
            <p className='text-[18px] font-bold' style={{ textDecoration: 'underline' }}>Permanent Address :- </p>
            <p className='flex mt-[-13px]'>same as Correspondence Address &nbsp; <div>
              <input onChange={(e)=>{copyAddress(e.target.value)}} type="radio" id="html" name="fav_language" value={true} />
              <label for="html">&nbsp;  Yes</label> &nbsp; 
              <input onChange={(e)=>{DonotCopy(e.target.value)}} type="radio" id="css" name="fav_language" value={false} />
              <label for="css"> &nbsp; No</label>
            </div></p>
            <label for="inputEmail4" className="form-label text-black font-bold">Address Line 1</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Address Line 1' style={{ outline: 'none' }} type="text" value={paddressLine1} onChange={(e) => { setpAddressLine1(e.target.value) }} className="form-control shadow-none text-black" id="inputEmail4" />
          </div>
          <div className="col-md-12">
            <label for="inputPassword4" className="form-label text-black font-bold">Address Line 2</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Address Line 2' type="text" className="form-control shadow-none text-black" value={paddressLine2} onChange={(e) => { setpAddressLine2(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">Country</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled placeholder='Enter Your City' type="text" className="cursor-not-allowed form-control shadow-none text-black" value={data.country} onChange={(e) => { setpCity(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">City</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Enter Your City' type="text" className="form-control shadow-none text-black" value={pcity} onChange={(e) => { setpCity(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">State</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" placeholder='Enter your State' className="form-control shadow-none text-black" value={pstate} onChange={(e) => { setpState(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">Pincode</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Enter Your Pincode' type="phone" className="form-control shadow-none text-black" value={ppincode} onChange={(e) => { setpPincode(e.target.value) }} id="inputCity" />
          </div>

        </form>
        <div className="col-12 flex justify-between flex-row-reverse mt-2">
          <button onClick={updateAddress} className="btn btn-primary">Save & Continue</button>
          <button onClick={() => { setActiveStep(2) }} className="btn btn-primary">Back</button>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Step3



