import React from 'react'
import Dashboards from '../Pages/Dashboard/Dashboard'
import UpperNavbar from '../Pages/Dashboard/UpperNavbar';
import '../Pages/Dashboard/Dashboard.css'
function Dashboard() {
  return (
    <div className='paymentPage'>
      <Dashboards/>
    </div>
  )
}

export default Dashboard