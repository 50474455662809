import React, { useState, useEffect } from 'react'
import PaymentReceipts from '../MultiStepForm/PaymentReceipts'
import PaymentReceiptsEngg from '../MultiStepForm/Engineering/PaymentReceipts'
import PaymentReceiptsPCM from '../MultiStepForm/PCM/PaymentReceipts'
import PaymentReceiptsPCB from '../MultiStepForm/PCB/PaymentReceipts'
import PaymentReceiptsOther from '../MultiStepForm/Other/PaymentReceipts'
import {MDBIcon} from 'mdb-react-ui-kit'
function MyPayments({data}) {
  const [studentData, setStudentData] = useState({})
  const [paymentDate, setPaymentDate] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [amount, setAmount] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [status, setStatus] = useState('')
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  // console.log(paymentDate)
  // console.log(studentData)
  //        USER AUTHENTICATION            //
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
        if (data.paymentResponseMBA) {
          const payment = data.paymentResponseMBA[data.paymentResponseMBA.length - 1].paymentStatus
          const paymentTrim = payment.trim().split("&")
          const transDate = paymentTrim[40].trim().split('=')
          const paymentMode = paymentTrim[5].trim().split('=')
          const paymentAmount = paymentTrim[10].trim().split('=')
          const paymentTransaction = paymentTrim[1].trim().split('=')
          const orderStatus = paymentTrim[3].trim().split('=')
          setPaymentDate(transDate[1])
          setPaymentMethod(paymentMode[1])
          setAmount(paymentAmount[1])
          setTransactionId(paymentTransaction[1])
          setStatus(orderStatus[1])
          // console.log(paymentTrim)
          // console.log(transDate)



        }
        else {
          // console.log('no')
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const [paymentDateEngg, setPaymentDateEngg] = useState('')
  const [paymentMethodEngg, setPaymentMethodEngg] = useState('')
  const [amountEngg, setAmountEngg] = useState('')
  const [transactionIdEngg, setTransactionIdEngg] = useState('')
  const [statusEngg, setStatusEngg] = useState('')
  const [fullscreenXlModalEngg, setFullscreenXlModalEngg] = useState(false);
  const userAuthenticationData2 = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        
        if (data.paymentResponseEngg) {
          const payment = data.paymentResponseEngg[data.paymentResponseEngg.length - 1].paymentStatus
          const paymentTrim = payment.trim().split("&")
          const transDate = paymentTrim[40].trim().split('=')
          const paymentMode = paymentTrim[5].trim().split('=')
          const paymentAmount = paymentTrim[10].trim().split('=')
          const paymentTransaction = paymentTrim[1].trim().split('=')
          const orderStatus = paymentTrim[3].trim().split('=')
          setPaymentDateEngg(transDate[1])
          setPaymentMethodEngg(paymentMode[1])
          setAmountEngg(paymentAmount[1])
          setTransactionIdEngg(paymentTransaction[1])
          setStatusEngg(orderStatus[1])
          // console.log(paymentTrim)
          // console.log(transDate)
        }
        else {
          // console.log('no')
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [paymentDatePCM, setPaymentDatePCM] = useState('')
  const [paymentMethodPCM, setPaymentMethodPCM] = useState('')
  const [amountPCM, setAmountPCM] = useState('')
  const [transactionIdPCM, setTransactionIdPCM] = useState('')
  const [statusPCM, setStatusPCM] = useState('')
  const [fullscreenXlModalPCM, setFullscreenXlModalPCM] = useState(false);
  // console.log(paymentDate)
  // console.log(studentData)
  //        USER AUTHENTICATION            //
  const userAuthenticationData3 = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        // setStudentData(data)
        if (data.paymentResponsePCM) {
          const payment = data.paymentResponsePCM[data.paymentResponsePCM.length - 1].paymentStatus
          const paymentTrim = payment.trim().split("&")
          const transDate = paymentTrim[40].trim().split('=')
          const paymentMode = paymentTrim[5].trim().split('=')
          const paymentAmount = paymentTrim[10].trim().split('=')
          const paymentTransaction = paymentTrim[1].trim().split('=')
          const orderStatus = paymentTrim[3].trim().split('=')
          setPaymentDatePCM(transDate[1])
          setPaymentMethodPCM(paymentMode[1])
          setAmountPCM(paymentAmount[1])
          setTransactionIdPCM(paymentTransaction[1])
          setStatusPCM(orderStatus[1])
          // console.log(paymentTrim)
          // console.log(transDate)



        }
        else {
          // console.log('no')
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const [paymentDatePCB, setPaymentDatePCB] = useState('')
  const [paymentMethodPCB, setPaymentMethodPCB] = useState('')
  const [amountPCB, setAmountPCB] = useState('')
  const [transactionIdPCB, setTransactionIdPCB] = useState('')
  const [statusPCB, setStatusPCB] = useState('')
  const [fullscreenXlModalPCB, setFullscreenXlModalPCB] = useState(false);
  // console.log(paymentDate)
  // console.log(studentData)
  //        USER AUTHENTICATION            //
  const userAuthenticationData4 = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        // setStudentData(data)
        if (data.paymentResponsePCB) {
          const payment = data.paymentResponsePCB[data.paymentResponsePCB.length - 1].paymentStatus
          const paymentTrim = payment.trim().split("&")
          const transDate = paymentTrim[40].trim().split('=')
          const paymentMode = paymentTrim[5].trim().split('=')
          const paymentAmount = paymentTrim[10].trim().split('=')
          const paymentTransaction = paymentTrim[1].trim().split('=')
          const orderStatus = paymentTrim[3].trim().split('=')
          setPaymentDatePCB(transDate[1])
          setPaymentMethodPCB(paymentMode[1])
          setAmountPCB(paymentAmount[1])
          setTransactionIdPCB(paymentTransaction[1])
          setStatusPCB(orderStatus[1])
          // console.log(paymentTrim)
          // console.log(transDate)



        }
        else {
          // console.log('no')
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const [paymentDateOther, setPaymentDateOther] = useState('')
  const [paymentMethodOther, setPaymentMethodOther] = useState('')
  const [amountOther, setAmountOther] = useState('')
  const [transactionIdOther, setTransactionIdOther] = useState('')
  const [statusOther, setStatusOther] = useState('')
  const [fullscreenXlModalOther, setFullscreenXlModalOther] = useState(false);
  // console.log(paymentDate)
  // console.log(studentData)
  //        USER AUTHENTICATION            //
  const userAuthenticationData5 = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        // setStudentData(data)
        if (data.paymentResponseOther) {
          const payment = data.paymentResponseOther[data.paymentResponseOther.length - 1].paymentStatus
          const paymentTrim = payment.trim().split("&")
          const transDate = paymentTrim[40].trim().split('=')
          const paymentMode = paymentTrim[5].trim().split('=')
          const paymentAmount = paymentTrim[10].trim().split('=')
          const paymentTransaction = paymentTrim[1].trim().split('=')
          const orderStatus = paymentTrim[3].trim().split('=')
          setPaymentDateOther(transDate[1])
          setPaymentMethodOther(paymentMode[1])
          setAmountOther(paymentAmount[1])
          setTransactionIdOther(paymentTransaction[1])
          setStatusOther(orderStatus[1])
          // console.log(paymentTrim)
          // console.log(transDate)



        }
        else {
          // console.log('no')
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  // useEffect(() => {
  //   userAuthenticationData()
  // }, [])
  // useEffect(() => {
  //   userAuthenticationData2()
  // }, [])
  // useEffect(() => {
  //   userAuthenticationData3()
  // }, [])
  // useEffect(() => {
  //   userAuthenticationData4()
  // }, [])
  // useEffect(() => {
  //   userAuthenticationData5()
  // }, [])

  return (
    <div className='text-center '>
      {
        paymentDate ? <>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-black font-extrabold uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6 text-center"style={{fontWeight:'800'}}>
                    Application Form
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Date
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Method
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Amount
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction ID
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction Status
                  </th>
                  {
                    status === "Success" ?
                    <th scope="col" className="py-3 px-6 text-center">
                    Payment Receipt
                  </th>
                  :
                  <></>
                  }
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 font-extrabold text-black">
                  <th scope="row" className="py-4 px-6 font-medium text-black font-extrabold whitespace-nowrap dark:text-white">
                    MBA / PGDM
                  </th>
                  <td className="py-4 px-6 text-center">
                    {paymentDate}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {paymentMethod}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {amount}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {transactionId}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {status}
                  </td>
                  {
                    status === "Success" ?
                    <td className="py-4 px-6 text-center">
                    <button onClick={()=>{setFullscreenXlModal(!fullscreenXlModal)}}><MDBIcon className='text-red-700 text-3xl' far icon="file-pdf" /> <br /> Download</button>
                  </td>
                  :
                  <></>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </> :
         null
      }
      {
        fullscreenXlModal ? <PaymentReceipts/>:<></>
      }




      {
        paymentDateEngg ? <>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs  uppercase text-black bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6 text-center">
                    Application Form
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Date
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Method
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Amount
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction ID
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction Status
                  </th>
                  {
                    statusEngg === "Success" ?
                    <th scope="col" className="py-3 px-6 text-center">
                    Payment Receipt
                  </th>
                  :
                  <></>
                  }
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 font-extrabold text-black">
                  <th scope="row" className="py-4 px-6 text-black font-extrabold whitespace-nowrap dark:text-white">
                  Engineering
                  </th>
                  <td className="py-4 px-6 text-center">
                    {paymentDateEngg}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {paymentMethodEngg}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {amountEngg}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {transactionIdEngg}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {statusEngg}
                  </td>
                  {
                    statusEngg === "Success" ?
                    <td className="py-4 px-6 text-center">
                    <button onClick={()=>{setFullscreenXlModalEngg(!fullscreenXlModalEngg)}}><MDBIcon className='text-red-700 text-3xl' far icon="file-pdf" /> <br /> Download</button>
                  </td>
                  :
                  null
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </> :
          null
      }
      {
        fullscreenXlModalEngg ? <PaymentReceiptsEngg/>:<></>
      }
      {
        paymentDatePCM ? <>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-black uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6 text-center">
                    Application Form
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Date
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Method
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Amount
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction ID
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction Status
                  </th>
                  {
                    statusPCM === "Success" ?
                    <th scope="col" className="py-3 px-6 text-center">
                    Payment Receipt
                  </th>
                  :
                  null
                  }
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 font-extrabold text-black">
                  <th scope="row" className="py-4 px-6 font-medium text-black font-extrabold whitespace-nowrap dark:text-white">
                    PCM
                  </th>
                  <td className="py-4 px-6 text-center">
                    {paymentDatePCM}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {paymentMethodPCM}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {amountPCM}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {transactionIdPCM}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {statusPCM}
                  </td>
                  {
                    statusPCM === "Success" ?
                    <td className="py-4 px-6 text-center">
                    <button onClick={()=>{setFullscreenXlModalPCM(!fullscreenXlModalPCM)}}><MDBIcon className='text-red-700 text-3xl' far icon="file-pdf" /> <br /> Download</button>
                  </td>
                  :
                  null
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </> :
          null
      }
      {
        fullscreenXlModalPCM ? <PaymentReceiptsPCM/>:null
      }







      {
        paymentDatePCB ? <>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-black uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6 text-center">
                    Application Form
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Date
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Method
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Amount
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction ID
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction Status
                  </th>
                  {
                    statusPCB === "Success" ?
                    <th scope="col" className="py-3 px-6 text-center">
                    Payment Receipt
                  </th>
                  :
                  null
                  }
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 font-extrabold text-black">
                  <th scope="row" className="py-4 px-6 font-medium text-black font-extrabold whitespace-nowrap dark:text-white">
                    PCB
                  </th>
                  <td className="py-4 px-6 text-center">
                    {paymentDatePCB}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {paymentMethodPCB}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {amountPCB}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {transactionIdPCB}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {statusPCB}
                  </td>
                  {
                    statusPCB === "Success" ?
                    <td className="py-4 px-6 text-center">
                    <button onClick={()=>{setFullscreenXlModalPCB(!fullscreenXlModalPCB)}}><MDBIcon className='text-red-700 text-3xl' far icon="file-pdf" /> <br /> Download</button>
                  </td>
                  :
                  null
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </> :
          null
      }
      {
        fullscreenXlModalPCB ? <PaymentReceiptsPCB/>:null
      }





      {
        paymentDateOther ? <>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-black uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6 text-center">
                    Application Form
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Date
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment Method
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Amount
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction ID
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Transaction Status
                  </th>
                  {
                    statusOther === "Success" ?
                    <th scope="col" className="py-3 px-6 text-center">
                    Payment Receipt
                  </th>
                  :
                  null
                  }
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b text-black font-extrabold dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="py-4 px-6  text-black font-extrabold whitespace-nowrap dark:text-white">
                    Other
                  </th>
                  <td className="py-4 px-6 text-center">
                    {paymentDateOther}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {paymentMethodOther}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {amountOther}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {transactionIdOther}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {statusOther}
                  </td>
                  {
                    statusOther === "Success" ?
                    <td className="py-4 px-6 text-center">
                    <button onClick={()=>{setFullscreenXlModalOther(!fullscreenXlModalOther)}}><MDBIcon className='text-red-700 text-3xl' far icon="file-pdf" /> <br /> Download</button>
                  </td>
                  :
                  <></>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </> :
          null
      }
      {
        fullscreenXlModalOther ? <PaymentReceiptsOther/>:null
      }

    </div>
  )
}

export default MyPayments