import React from 'react'

function RegistrationVideo({videos}) {
    console.log("videos")
    return (
        <div style={{width:'95%',marginTop:'1.2rem',margin:'auto'}}>
            <div className="ratio ratio-16x9">
                <iframe
                style={{borderRadius:'15px'}}
                    src={videos}
                    title="YouTube video"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    )
}

export default RegistrationVideo