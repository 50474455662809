import React,{useState,useEffect} from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import UpperNavbar from '../../Dashboard/UpperNavbar';
import { useNavigate } from 'react-router-dom'
function Step3() {
  const navigate = useNavigate()
  const [addressLine1,setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [pincode, setPincode] = useState('')
  const [studentData, setStudentData] = useState({})
// console.log(addressLine1)

  //        USER AUTHENTICATION            //
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
        if(data.pincode){
          setPincode(data.pincode)
          setAddressLine1(data.addressLine1)
          setAddressLine2(data.addressLine2)
          setState(data.state)
          setCity(data.city)
        }
        else{
          
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  //       UPDATE ADDRESS DETAILS OF USER
  async function updateAddress() {
    // event.preventDefault()

    const response = await fetch('/adress-update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        addressLine1,
        addressLine2,
        city,
        state,
        pincode
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          // alert('changes successfully')
          // setCentredModal(false)
          navigate('/online-pcbe-fillApplication')
        }
        else {
          alert('Error')
          // setCentredModal(false)
        }
      })
  }
  useEffect(() => {
    userAuthenticationData()
  }, [])
  return (
    <div className='paymentPage'>
      <UpperNavbar />
      <h2 className='applicationCSS4 text-center pt-2 text-black font-thin mt-4' style={{ fontWeight: '600',fontSize:'22px',color:'#0e2e2e' }}>UG Application Form 2023 (PCB) </h2>
      <div className='applicationCSS3'>
        <img className='applicationCSS2 fluid' style={{margin:'auto'}} src="https://ucarecdn.com/e853544e-4a83-4196-9e8d-4fe7520ca2f4/step3.png" alt="" />
      </div>








      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form applicationCSS'>
        <form className="row g-3">
          <div className="col-md-12 ">
            <label for="inputEmail4" className="form-label text-black font-bold">Address Line 1</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input style={{ outline: 'none' }} type="text" value={addressLine1} onChange={(e)=>{setAddressLine1(e.target.value)}} className="form-control shadow-none text-black" id="inputEmail4" />
          </div>
          <div className="col-md-12">
            <label for="inputPassword4" className="form-label text-black font-bold">Address Line 2</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" className="form-control shadow-none text-black" value={addressLine2} onChange={(e)=>{setAddressLine2(e.target.value)}} id="inputPassword4" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">Country</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input disabled placeholder='Enter Your City' type="text" className="cursor-not-allowed form-control shadow-none text-black" value={studentData.country} onChange={(e)=>{setCity(e.target.value)}} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">City</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Enter Your City' type="text" className="form-control shadow-none text-black" value={city} onChange={(e)=>{setCity(e.target.value)}} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">State</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" placeholder='Enter your State' className="form-control shadow-none text-black" value={state} onChange={(e)=>{setState(e.target.value)}} id="inputCity" />
          </div>
          <div className="col-md-3">
            <label for="inputCity" className="form-label text-black font-bold">Pincode</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Enter Your Pincode' type="phone" className="form-control shadow-none text-black" value={pincode} onChange={(e)=>{setPincode(e.target.value)}} id="inputCity" />
          </div>

        </form>
          <div className="col-12 flex justify-between flex-row-reverse mt-2">
            <button disabled={!addressLine1 || !addressLine2 || !city || !state || !pincode} onClick={updateAddress} className="btn btn-primary">Save & Continue</button>
            <button onClick={() => { navigate('/online-pcbt-fillApplication') }}  className="btn btn-primary">Back</button>
          </div>
      </div>
    </div>
  )
}

export default Step3



