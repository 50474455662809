import React, { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom'
import AllApplication from "./Components/AllApplication";
import Contact from "./Components/Contact";
import Dashboard from "./Components/Dashboard";
import LandingPage from './Components/Landing'
import MultiStepForm from "./Components/MultiStepForm";
import MyPayments from "./Components/MyPayments";
import Profile from "./Components/Profile";
import Registration from "./Components/Registration";
import Schedule from "./Components/Schedule";
// import AllStep from "./Pages/Dashboard/AllStep";
import Logout from "./Pages/Dashboard/Logout";
import ScheduledYourExam from "./Pages/Dashboard/ScheduledYourExam";
import PaymentThankYou from "./Pages/MultiStepForm/PaymentThankYou";
import PaymentThankYouEngg from "./Pages/MultiStepForm/Engineering/PaymentThankYou";
import PaymentThankYouPcm from "./Pages/MultiStepForm/PCM/PaymentThankYou";
import PaymentThankYouPcb from "./Pages/MultiStepForm/PCB/PaymentThankYou";
import PaymentThankYouOther from "./Pages/MultiStepForm/Other/PaymentThankYou";
import Step1 from "./Pages/MultiStepForm/Step1";
import RankCard from "./Components/RankCard";
import EnggStep1 from './Pages/MultiStepForm/Engineering/Step1'
import EnggStep2 from './Pages/MultiStepForm/Engineering/Step2'
import EnggStep3 from './Pages/MultiStepForm/Engineering/Step3'
import EnggStep4 from './Pages/MultiStepForm/Engineering/Step4'
import EnggStep5 from './Pages/MultiStepForm/Engineering/Step5'
import PCMStep1 from './Pages/MultiStepForm/PCM/Step1'
import PCMStep2 from './Pages/MultiStepForm/PCM/Step2'
import PCMStep3 from './Pages/MultiStepForm/PCM/Step3'
import PCMStep4 from './Pages/MultiStepForm/PCM/Step4'
import PCMStep5 from './Pages/MultiStepForm/PCM/Step5'
import PCBStep1 from './Pages/MultiStepForm/PCB/Step1'
import PCBStep2 from './Pages/MultiStepForm/PCB/Step2'
import PCBStep3 from './Pages/MultiStepForm/PCB/Step3'
import PCBStep4 from './Pages/MultiStepForm/PCB/Step4'
import PCBStep5 from './Pages/MultiStepForm/PCB/Step5'
import OtherStep1 from './Pages/MultiStepForm/Other/Step1'
import OtherStep2 from './Pages/MultiStepForm/Other/Step2'
import OtherStep3 from './Pages/MultiStepForm/Other/Step3'
import OtherStep4 from './Pages/MultiStepForm/Other/Step4'
import OtherStep5 from './Pages/MultiStepForm/Other/Step5'
import Step2 from "./Pages/MultiStepForm/Step2";
import Step3 from "./Pages/MultiStepForm/Step3";
import Step4 from "./Pages/MultiStepForm/Step4";
import Step5 from "./Pages/MultiStepForm/Step5";
import Step6 from "./Pages/MultiStepForm/Step6";
import Otp from "./Pages/Registration/Otp";
import Trial from "./Pages/Registration/Trial";
import About from "./Pages/LandingPage/About";
import Syllabous from "./Pages/LandingPage/Syllabous";
import SampleQuestion from "./Pages/LandingPage/SampleQuestion";
import ContactUs from "./Pages/LandingPage/ContactUs";
import Eligibility from "./Pages/LandingPage/Eligibility";
import RefferalRegister from './Pages/Registration/RefferalRegister';
import RefundRequest from "./Pages/LandingPage/RefundRequest";
import RefundLogin from "./Pages/LandingPage/refund/LoginForm";
import RefundStudentData from "./Components/RefundStudentData";
import CollegeLogin from './Pages/collegePortal/Login'
import CollegeDashboard from './Pages/collegePortal/Home'
import SeatDetails from "./Pages/collegePortal/SeatDetails";
import Step1Testing from './Components/applicationStages/Step1'
import MbaApplication from "./Pages/MultiStepForm/MbaApplication";
import Testing from "./Pages/MultiStepForm/Testing";
import Nri from "./Pages/LandingPage/Nri";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/pdf" element={pdf}/> */}
        <Route path="/syllabous" element={<Syllabous />} />
        <Route path="/eligibility" element={<Eligibility />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/sample-question-paper-2023" element={<SampleQuestion />} />
        <Route path="/registration" element={<Registration />} />
   
        <Route path="/registration/:id" element={<RefferalRegister />} />
        <Route path="/otp-verify" element={<Otp />} />
        <Route path="/trial" element={<Trial />} />
        <Route path="/student-dashboard" element={<Dashboard />} />
        <Route path="/all-application" element={<AllApplication />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/my-payments" element={<MyPayments />} />
        <Route path="/my-profile" element={<Profile />} />
        {/* <Route path="/fillApplication" element={<MultiStepForm/>}/> */}
        <Route path="/fillApplication-step1" element={<Step1 />} />
        <Route path="/online-engg-1-fillApplication" element={<EnggStep1 />} />
        <Route path="/online-engg-2-fillApplication" element={<EnggStep2 />} />
        <Route path="/online-engg-3-fillApplication" element={<EnggStep3 />} />
        <Route path="/online-engg-4-fillApplication" element={<EnggStep4 />} />
        <Route path="/online-engg-5-fillApplication" element={<EnggStep5 />} />
        <Route path="/online-pcm-o-fillApplication" element={<PCMStep1 />} />
        <Route path="/online-pcm-t-fillApplication" element={<PCMStep2 />} />
        <Route path="/online-pcm-h-fillApplication" element={<PCMStep3 />} />
        <Route path="/online-pcm-e-fillApplication" element={<PCMStep4 />} />
        <Route path="/online-pcm-r-fillApplication" element={<PCMStep5 />} />
        <Route path="/online-pcbo-fillApplication" element={<PCBStep1 />} />
        <Route path="/online-pcbt-fillApplication" element={<PCBStep2 />} />
        <Route path="/online-pcbh-fillApplication" element={<PCBStep3 />} />
        <Route path="/online-pcbe-fillApplication" element={<PCBStep4 />} />
        <Route path="/online-pcbr-fillApplication" element={<PCBStep5 />} />
        <Route path="/online-all-other-program-fillApplication" element={<OtherStep1 />} />
        <Route path="/online-all-ther-program-fillApplication" element={<OtherStep2 />} />
        <Route path="/online-all-her-program-fillApplication" element={<OtherStep3 />} />
        <Route path="/online-all-er-program-fillApplication" element={<OtherStep4 />} />
        <Route path="/online-all-r-program-fillApplication" element={<OtherStep5 />} />
        <Route path="/fillApplication-step2" element={<Step2 />} />
        <Route path="/fillApplication-step3" element={<Step3 />} />
        <Route path="/fillApplication-step4" element={<Step4 />} />
        <Route path="/fillApplication-step5" element={<Step5 />} />
        <Route path="/fillApplication-step6" element={<Step6 />} />
        <Route path="/application-patyment-fee-successful/:program" element={<PaymentThankYou />} />
        <Route path="/application-engg-patyment-fee-successful" element={<PaymentThankYouEngg />} />
        <Route path="/application-pcm-patyment-fee-successful" element={<PaymentThankYouPcm />} />
        <Route path="/application-pcb-patyment-fee-successful" element={<PaymentThankYouPcb />} />
        <Route path="/application-other-patyment-fee-successful" element={<PaymentThankYouOther />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/schedule-you-exam" element={<Schedule />} />
        <Route path='/student/rank-card' element={<RankCard />} />
        <Route path='/refund-request' element={<RefundRequest />} />
        <Route path='/refund-login' element={<RefundLogin />} />
        <Route path='/refund-student/data' element={<RefundStudentData />} />
        <Route path='/institution/login' element={<CollegeLogin />} />
        <Route path='/institution/dashboard/home' element={<CollegeDashboard />} />
        <Route path='/institution/dashboard/seat-details' element={<SeatDetails />} />
        <Route path='/step1' element={<Step1Testing />} />
        <Route path='/mba' element={<MbaApplication />} />
        <Route path='/student-dashboard/:application' element={<MbaApplication />} />
        <Route path='/test' element={<Testing />} />
        <Route path='/student-dashboard/:program/form' element={<Testing />} />
        <Route path='/nri' element={<Nri />} />
      </Routes>
    </>
  );
}

export default App;
