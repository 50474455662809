import React, { useState, useEffect } from 'react'
import LeftBar from './LeftBar'
// import CentreBar from './CentreBar'
import RightSideBar from './RightSideBar'
import newicon from './img/newicon.gif'
import './css/own.css'
import { useNavigate, Link } from 'react-router-dom'
import Header from './Header'
import Marque from './Marque'
import styled from 'styled-components'
import Navbar from './refund/Navbar'
import RefundRequestForm from './refund/RefundForm'
import Preview from './refund/Preview'
function RefundRequest() {
    const navigate = useNavigate()
    const [studentData, setStudentData] = useState({})
    // console.log(studentData)
    const authirazation = async () => {
        try {
          const res = await fetch("/refund/authorization-student", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            credentials: "include",
          });
          const data = await res.json();
          // console.log(data)
          setStudentData(data);
          IsRefundRequest(data._id)
          if (!res.status === 401) {
            const error = new Error(res.server);
            throw error;
          }
        } catch (error) {
          // console.log("unouthorized")
          navigate("/refund-login");
        }
      };

    const [refundStudent, setRefundStudent] = useState({})
    console.log(refundStudent)
    console.log(refundStudent)
    const IsRefundRequest = async (id) => {
        fetch(`/refund/student/data/${id}`)
            .then(response => response.json())
            .then(data => {
                setRefundStudent(data)
                // console.log(data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    // console.log(studentData)
    useEffect(() => {
        authirazation()
    }, [])

    return (
        <RefundForm>
            <Navbar data={studentData}/>
            {/* <Header />
            <Marque /> */}
            <section className="contentsection mt-2" id="skipcontent">
                <div className="container-fluid">
                    <div className="row">

                        {/* <!-- LEFT SIDEBAR START HERE --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            {/* <LeftBar /> */}
                        </div>
                        {/* </div> <!-- LEFT SIDEBAR END HERE --> */}


                        {/* <!-- INTRO SECTION START HERE --> */}
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="mb-4">
                                <div className="mb-4 pagecontentpara LandingPara">
                                {
                                    refundStudent.accountNo ? <Preview />:
                                    <RefundRequestForm data={studentData} onClick={authirazation}/>
                                }
                                    {/* <Preview /> */}
                                </div>
                            </div>


                        </div>
                        {/* </div> <!-- INTRO SECTION END HERE --> */}


                        {/* <!-- RIGHT SIDEBAR START HERE --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            {/* <RightSideBar /> */}
                        </div>
                        {/* <!-- RIGHT SIDEBAR END HERE --> */}

                    </div>
                </div>
            </section>


        </RefundForm>
    )
}

const RefundForm = styled.main`
.form-outline{
    border: 1px solid gray;
}
.form-label{
    outline: none;
    border: none;
}
`

export default RefundRequest