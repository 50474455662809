import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const RefundStudentData = () => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };
    const [phase, setPhase] = useState(1)
    const [data, setData] = useState([])
    const refundStudentDataFetch = (studentPhase) => {
       
        setOpen(true)
        axios.get(`/refund/student/phase-${studentPhase}`).then((response) => {
            console.log(response.data)
            setData(response.data)
            setPhase(studentPhase)
            setOpen(false)
            // setPhase(response.data)
        }).catch((err)=>{
            console.log(err)
            setOpen(false)
        })
    }

    const columns = [
        {
            name: 'Application Number',
            selector: row => row.applicationNo,
        },
        {
            name: 'Student Name',
            selector: row => row.studentName,
        },
        {
            name: 'Type of Refund',
            selector: row => row.refundOf,
        },
        {
            name: 'Bank Name',
            selector: row => row.bankName,
        },
        {
            name: 'Account Holder Name',
            selector: row => row.accoundHolderName,
        },
        {
            name: 'Account Number',
            selector: row => row.accountNo,
        },
        {
            name: 'IFSC Code',
            selector: row => row.ifscCode,
        },
        {
            name: 'Passbook',
            selector: row => <a target='__blank' href={`https://aicee.in/download/${row.passbook}`}>View</a>,
        },
        {
            name: 'Payment Slip',
            selector: row => <a target='__blank' href={`https://aicee.in/download/${row.paymentSlip}`}>View</a>,
        },
    ];


    useEffect(() => {
        refundStudentDataFetch(1)
    }, [])

    const PhaseChange = (e)=>{
        // e.PreventDefault()
        
        refundStudentDataFetch(e)
    }
    return (
        <div>
            <h1 className='text-center p-5'>Refund Request from Phase {phase}  Student </h1>
            <select onChange={(e)=>{refundStudentDataFetch(e.target.value)}} value={phase} style={{border:'1px solid black', padding:'1rem',marginLeft:'1rem'}} className="custom-select custom-select-lg mb-3">
                <option selected>Open this select Phase</option>
                <option value="1">Phase One</option>
                <option value="2">Phase Two</option>
                <option value="3">Phase Three</option>
                {/* <option value="3">Three</option> */}
            </select>
            <hr />
            {
                data.length >= 1 && <DataTable
                    columns={columns}
                    data={data}
                />
            }
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
     
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </div>
    )
}

export default RefundStudentData