const examination = {
    mba:{
        program:"MBA / PGDM",
        branchName:["MBA", "PGDM"],
        branchTitle:"Program Intrested",
        exmaSlot:[
            "31st Jan, 2025 - 01:00 PM To 03:00 PM",
            "2nd Feb, 2025 – 04:00 PM To 06:00 PM"
        ],
        lof:"GRADUATION"
    },
    engg:{
        program:"ENGINEERING",
        exmaSlot:[
            "31st Jan, 2025 - 01:00 PM TO 03:00 PM",
            "1st Feb , 2025 - 01:00 PM TO 03:00 PM",
            "2nd Feb , 2025 - 01:00 PM TO 03:00 PM"
        ],
        branchTitle:"Branch Preferences",
        branchName:[
            "Computer Science Engg",
            "Computer Science Engg with sepecialization (like AI, Data Science, ML or any other)",
            "Electronics & Communication Engg",
            "Electronics & Telecommunication Engg",
            "Electrical Engg",
            "Electrical & Electronics Engg",
            "Mechanical Engg",
            "Civil Engg",
            "Automobile Engg",
            "Aerospace Engg",
            "Any Other"
        ],
        lof:"PCM"
    },
    pcb:{
        program:"PCB",
        exmaSlot:[
            "2nd Feb, 2025 - 04:00 PM TO 06:00 PM"
        ],
        branchTitle:"Program Intrested",
        branchName:[
            "B.Pharmacy",
            "Bsc.Nursing",
            "Bio –Technology",
            "Agriculture ( Bsc.Agriculture )", "BPT ( Bachelor of physiotherapy )"
    
        ],
        lof:"PCB"

    },
    pcm:{
        program:"PCM",
        exmaSlot:[
            "1st Feb , 2025 - 01:00 PM TO 03:00 PM",
            "2nd Feb , 2025 - 01:00 PM TO 03:00 PM"
        ],
        branchTitle:"Program Intrested",
        branchName:[

            "Bsc.Biotechnology", "B. Tech Biotechnology",
            "B.Pharmacy",
            "Aeronautical", "Aerospace Engineering",
            "B.Tech Agriculture ", "Bsc. Agriculture"
    
        ],
        lof:"PCM"
    },
    other:{
        program:"OTHER",
        exmaSlot:[
            "2nd Feb, 2025 - 04:00 PM TO 06:00 PM"
        ],
        branchTitle:"Program Intrested",
        branchName:[
            "BBA", "BCA",
            "B. Design",
            "BA LLB", "BBA LLB", "BCA LLB", "B.COM LLB",
            "Mass Communication & Journalism ( BJMC )",
            "B.A", "B.Com", "B.Sc"
        ],
        lof:"Other"
    },
}

module.exports = examination