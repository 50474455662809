import React, { useState, useEffect } from 'react'
import Countdown from "react-countdown";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {
    MDBCard, MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBIcon,
    MDBInput,
    MDBCheckbox,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalFooter,
    MDBCardHeader,
    MDBCardBody,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody
} from 'mdb-react-ui-kit';
import ApplicationStep from './ApplicationStep';
import ApplicationStages from './ApplicationStages';
// import ApplicationImage from './ApplicationImage';
import './Registration.css'
// import aicee2023logo from '../../Images/aicee2023logo.png'
import aicee2023logo from '../../Pages/LandingPage/img/logo1.png'
// import step from '../../Images/step.png'
import banner from '../../Images/banner.webp'
import RegistrationVideo from './RegistrationVideo';
import newgif from '../../Images/new.gif'
import Footer from './Footer';
// import ReCAPTCHA from "react-google-recaptcha";
import Header from '../../Pages/Registration/Header'
// const recaptchaRef = React.createRef();
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom'
import OTPVerification from './OtpVerification';


function Registration() {
    const userIds = useParams();
    console.log(userIds.id)
    const [showOtp, setShowOtp] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [video, setVideo] = useState('https://aicee.s3.ap-south-1.amazonaws.com/videos/AICEE%201.mp4')

    const [justifyActive, setJustifyActive] = useState('tab2');
    const navigate = useNavigate()
    // console.log(justifyActive)
    const handleJustifyClick = (value) => {
        if (value === justifyActive) {
            return;
        }

        setJustifyActive(value);
    };


    const submited = async () => {
        setShowOtp(false);
    }

    const [studentName, setStudentName] = useState('')
    const [country, setCountry] = useState('')
    const [gender, setGender] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [emailId, setEmailId] = useState('')
    const [program, setProgram] = useState("")
    const [user_id, setUser_id] = useState('')
    // console.log(datareg.message)
    // console.log(studentName, country, gender, mobileNo, emailId)
    async function registrationUser(event) {
        event.preventDefault()
        setOpen(true)
        await fetch('/api/v2/user/registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: studentName,
                country,
                gender,
                mobileNo,
                email: emailId,
                program
            })
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data: " + JSON.stringify(data));
                setOpen(false)
                if (data.status == 200 || data.status == 201) {
                    toast.success(data.message)
                    setUser_id(data.data.user_id)
                    // setOpen(false)
                    setShowOtp(true)
                    // setDatareg(data)
                    // setStudentUserId(data.data.user_id)
                    // senMobileOtp()
                }
                else {

                    toast.error(data.message)
                    // console.log(data)
                }
            })
            .catch((err) => {
                // alert(err.response.message)
                toast.error(err.response.message)
            })


    }

    // checked or not
    const [checked, setChecked] = useState(false)
    const handleChange = () => {
        setChecked(!checked)
    }


    /// login start
    const [loginEmail, setLoginEmail] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    async function loginUser(event) {
        event.preventDefault()
        if (!loginEmail || !loginPassword) {
            alert('invalid credentials')
        }
        else {
            const response = await fetch('/online-student/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailId: loginEmail,
                    password: loginPassword
                })
            })
            const data = await response.json()

            if (data.success) {
                navigate('/student-dashboard')

            }
            else {
                alert(data.message)
            }
            //   console.log(data)
        }
    }
    // login end

    //   FORGET PASSWORD
    const [centredModal, setCentredModal] = useState(false);
    const toggleShow = () => setCentredModal(!centredModal);
    const [forgotPassword, setForgotPassword] = useState('')
    async function forgotPasswordOfStudent(event) {
        event.preventDefault()
        setOpen(true)
        if (!forgotPassword) {
            alert('invalid credentials')
            setOpen(false)
        }
        else {
            const response = await fetch('/api/v2/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailId: forgotPassword

                })
            })
            const data = await response.json()
            console.log(data)
            if (data.status == 200) {
                setCentredModal(false)
                setForgotPassword('')
                alert(data.message)
                setOpen(false)
            }

            else {
                setForgotPassword('')
                alert('Please Enter Registered Email Id')
                setOpen(false)
            }

            //   console.log(data)
        }

    }

    const [mobileOtp, setMobileOtp] = useState(Array(6).fill(''));
    const [showPhoneResend, setShowPhoneResend] = useState(false);
    const [showEmailResend, setShowEmailResend] = useState(false);
    const [emailOtp, setEmailOtp] = useState(Array(6).fill(''));
    const [mobileVerified, setMobileVerified] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [countdownEmail, setCountdownEmail] = useState(120);
    const [countdownPhone, setCountdownPhone] = useState(120);
    const [canResendPhone, setCanResendPhone] = useState(false);
    const [canResendEmail, setCanResendEmail] = useState(false);
    const [emailVerifiedSuccess, setEmailVerifiedSuccess] = useState(false);
    const [mobileVerifiedSuccess, setMobileVerifiedSuccess] = useState(false);

    const [state, setState] = useState({ toggle: false });

    // // When updating toggle, ensure the object exists
    // setState(prevState => ({
    //   ...prevState, 
    //   toggle: !prevState.toggle
    // }));

    useEffect(() => {
        if (countdownEmail > 0) {
            const timer = setInterval(() => {
                setCountdownEmail((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            setCanResendEmail(true);
        }
    }, [countdownEmail]);

    useEffect(() => {
        if (countdownPhone > 0) {
            const timer = setInterval(() => {
                setCountdownPhone((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            setCanResendPhone(true);
        }
    }, [countdownPhone]);

    const handleChangeMobile = (index, value) => {
        if (/^\d?$/.test(value)) {
            const newOtp = [...mobileOtp];
            newOtp[index] = value;
            setMobileOtp(newOtp);
            if (value && index < 5) document.getElementById(`mobile-${index + 1}`).focus();
        }
    };

    const handleChangeEmail = (index, value) => {
        if (/^\d?$/.test(value)) {
            const newOtp = [...emailOtp];
            newOtp[index] = value;
            setEmailOtp(newOtp);
            if (value && index < 5) document.getElementById(`email-${index + 1}`).focus();
        }
    };

    const resendOtpEmail = async (e) => {
        e.preventDefault();
        await axios.post('/api/v2/resend-otp/email', {
            user_id,
        }).then((response) => {
            toast.success(response.data.message);
            setCountdownEmail(120);
            setCanResendEmail(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    };

    const resendOtpPhone = async (e) => {
        e.preventDefault();
        await axios.post('/api/v2/resend-otp/phone', {
            user_id,
        }).then((response) => {
            toast.success(response.data.message);
            setCountdownPhone(120);
            setCanResendPhone(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    };

    const verifyOtpPhone = async (e) => {
        try {
            e.preventDefault();
            await axios.post('/api/v2/verify/phone-otp', {
                user_id,
                otp: mobileOtp.join('')
            }).then((response) => {
                console.log("response", response);
                toast.success(response.data.message)
                if (response.data.message == 'Login Credentials has been sent on you registered phone or email') {
                    submited()
                    toast.success(response.data.message)
                }
                setMobileVerified(true)
                setShowPhoneResend(true)
                setMobileVerifiedSuccess(true)
            }).catch((error) => {
                console.log("error", error);
                toast.error(error.response.data.message)
            });
        } catch (error) {
            console.log(error);
            toast.error("Internal Server Error")
        }
    }

    const verifyOtpEmail = async (e) => {
        try {
            e.preventDefault();
            await axios.post('/api/v2/verify/email-otp', {
                user_id,
                otp: emailOtp.join('')
            }).then((response) => {
                console.log("response", response);
                toast.success(response.data.message)
                if (response.data.message == 'Login Credentials has been sent on you registered phone or email') {

                    submited()
                    toast.success(response.data.message)
                }
                setEmailVerified(true)
                setShowEmailResend(true)
                setEmailVerifiedSuccess(true)
            }).catch((error) => {
                console.log("error", error);
                toast.error(error.response.data.message)
            });
        } catch (error) {
            console.log(error);
            toast.error("Internal Server Error")
        }
    }

    const submitOtpPage = ()=>{
        setShowOtp(!showOtp)
        setJustifyActive('tab1')
    }
    return (
        <div className='RegistrationCardContainer'>
            <ToastContainer />
            <div className='RegistrationCard'>
                <div className='RegistrationCard2'>
                    <MDBCard >
                        <div className='RegistrationCard3'>
                            <center className='RegistrationCard4'><img className='fluid' src={aicee2023logo} alt="aicee" /></center>

                            <div className='RegistrationCard5'><p>ALL India Combined Entrance Examination</p>
                                <div className='mt-1'>
                                    <marquee style={{ marginTop: '-15px', color: 'white', color: 'black', fontWeight: 'bolder', textTransform: 'capitalize', fontWeight: '500', fontFamily: 'Open Sans, sans-serif' }} behavior="" direction="" ><span style={{ display: 'flex' }}><span>AICEE 2025 Application Open Now</span> <sup><img src={newgif} alt="gif" /></sup></span></marquee>
                                </div>
                            </div>


                        </div>
                        <hr />

                        <MDBContainer fluid className="p-4 my-5">

                            <MDBRow style={{ marginTop: '-5rem' }}>

                                <MDBCol style={{ border: '1px solid #BDBDBD', borderRadius: '5px' }} col='6' md='5'>
                                    <MDBTabs style={{ backgroundColor: '#04124f', width: '100%', padding: '0px' }} className='mb-3 d-flex  '>
                                        {
                                            justifyActive == "tab2" ?
                                                <>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', backgroundColor: 'white', color: '#17a2b8' }} onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                                            New User
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', color: 'white' }} onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                                            Registered User Login
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                </> :
                                                <>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', color: 'white' }} onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                                            New User
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', backgroundColor: 'white', color: '#17a2b8' }} onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                                            Login
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                </>
                                        }
                                    </MDBTabs>
                                    <MDBTabsContent >

                                        <MDBTabsPane show={justifyActive === 'tab1'}>



                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="envelope" /> Email ID<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput onChange={(e) => { setLoginEmail(e.target.value) }} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='text' />
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="key" /> Password<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput onChange={(e) => { setLoginPassword(e.target.value) }} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='password' />

                                            <div style={{ textAlign: 'right' }}>

                                                <span className='cursor-pointer' onClick={toggleShow}>Forgot password?</span>
                                            </div>

                                            <MDBBtn style={{ backgroundColor: '#04124f' }} onClick={loginUser} className="mb-4 w-100">Sign in</MDBBtn>


                                        </MDBTabsPane>

                                        <MDBTabsPane show={justifyActive === 'tab2'}>


                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: '700', fontSize: 'inherit', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="user" /> Candidate Name<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput value={studentName} onChange={(e) => setStudentName(e.target.value)} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' type='text' placeholder='Your Full Name as Per Your Marksheet' />
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: '700', color: 'black', fontFamily: 'Open Sans, sans-serif', fontSize: 'inherit' }}> <MDBIcon fas icon="globe-africa" /> Country<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>

                                            <select onChange={(e) => setCountry(e.target.value)} style={{ width: '100%', border: 'none', border: '1px solid #BDBDBD', borderRadius: '5px', color: '#676767', padding: '0.4rem', marginBottom: '0.8rem' }} name="" id="">
                                                <option style={{ border: 'none' }} value="">Select Country</option>
                                                <option style={{ border: 'none' }} value="India">India</option>
                                                <option style={{ border: 'none' }} value="Nepal">Nepal</option>
                                            </select>
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="bullseye" /> Gender<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <select onChange={(e) => setGender(e.target.value)} style={{ width: '100%', border: 'none', border: '1px solid #BDBDBD', borderRadius: '5px', color: '#676767', padding: '0.4rem', marginBottom: '0.8rem' }} name="" id="">
                                                <option style={{ border: 'none' }} value="">Select Gender</option>
                                                <option style={{ border: 'none' }} value="Male">Male</option>
                                                <option style={{ border: 'none' }} value="Female">Female</option>
                                                <option style={{ border: 'none' }} value="Transgender">Transgender</option>
                                            </select>
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="tasks" /> Proramme<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <select value={program} onChange={(e) => setProgram(e.target.value)} style={{ width: '100%', border: 'none', border: '1px solid #BDBDBD', borderRadius: '5px', color: '#676767', padding: '0.4rem', marginBottom: '0.8rem' }} name="" id="">
                                                <option style={{ border: 'none' }} value="">Select Programme</option>
                                                <option style={{ border: 'none' }} value="MBA / PGDM">MBA / PGDM</option>
                                                <option style={{ border: 'none' }} value="Engineering ( B-Tech / B.E )">Engineering ( B-Tech / B.E )</option>
                                                <option style={{ border: 'none' }} value="Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology )">Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology )</option>
                                                <option style={{ border: 'none' }} value="B.Pharmacy">B.Pharmacy</option>
                                                <option style={{ border: 'none' }} value="Agriculture ( Bsc. Agriculture / B-Tech Agriculture )">Agriculture ( Bsc. Agriculture / B-Tech Agriculture )</option>
                                                <option style={{ border: 'none' }} value="Aeronautical & Aerospace Engineering">Aeronautical & Aerospace Engineering</option>
                                                <option style={{ border: 'none' }} value="Bsc Datascience">Bsc Datascience</option>
                                                <option style={{ border: 'none' }} value="B.Pharmacy">B.Pharmacy</option>
                                                <option style={{ border: 'none' }} value="Bsc.Nursing">Bsc.Nursing</option>
                                                <option style={{ border: 'none' }} value="Bsc.Agriculture">Bsc.Agriculture</option>
                                                <option style={{ border: 'none' }} value="Bio-Tech ( Bsc.BioTech / B-Tech.BioTech)">Bio-Tech ( Bsc.BioTech / B-Tech.BioTech)</option>
                                                <option style={{ border: 'none' }} value="BBA">BBA</option>
                                                <option style={{ border: 'none' }} value="BCA">BCA</option>
                                                <option style={{ border: 'none' }} value="B.Design">B.Design</option>
                                                <option style={{ border: 'none' }} value="B.Design">B.Design</option>
                                                <option style={{ border: 'none' }} value="LLB (BA / B.Com / B.sc / BBA / BCA)">LLB (BA / B.Com / B.sc / BBA / BCA)</option>
                                                <option style={{ border: 'none' }} value="Mass Communication & Journalism">Mass Communication & Journalism</option>

                                            </select>

                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="phone-alt" /> Mobile No<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span><span className='text-[0.8rem]'>(without adding +91 or 0)</span>
                                            </label>

                                            <MDBInput value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='phone' />
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="envelope" /> Email ID<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput value={emailId} onChange={(e) => setEmailId(e.target.value)} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='text' />

                                            <div className='d-flex justify-content-center mb-2'>
                                                <MDBCheckbox value={checked} onChange={handleChange} name='flexCheck' id='flexCheckDefault' label='' /><span style={{ fontSize: '0.8rem', fontWeight: 'bolder', color: 'black' }}> I authorize AICEE to use my mobile number / Email Id/ connected social media platform overriding DND status.</span>
                                            </div>

                                            <MDBBtn disabled={checked == false} onClick={registrationUser} style={{ backgroundColor: '#04124f', fontFamily: 'Open Sans, sans-serif' }} className="mb-4 w-100">Register</MDBBtn>

                                        </MDBTabsPane>

                                    </MDBTabsContent>

                                </MDBCol>

                                <MDBCol col='6' md='7' className=''>

                                    <img style={{ width: '150%', height: '80%', borderRadius: '15px' }} src='https://aicee.s3.ap-south-1.amazonaws.com/AICEE REGISTRATION BANNER.jpg' className="img-fluid" alt="img" />



                                </MDBCol>

                            </MDBRow>

                        </MDBContainer>
                        <div className='registrationStep'>
                            <ApplicationStep />
                        </div>
                        {/* <img style={{ width: '100%', marginTop: '-5rem' }} src={step} alt="" /> */}
                    </MDBCard>



                </div>
                <div className='mt-4 applicationStages'>
                    <div><RegistrationVideo videos={video} /></div>
                    <div style={{ marginTop: '-0.3rem' }}><ApplicationStages /></div>
                </div>
                <div className='grid grid-rows-2 m-auto text-center  mt-4 mb-4  grid-flow-col gap-5 sm:grid-rows-1 pl-4 pr-4'>
                    <div onClick={() => { setVideo('https://aicee.s3.ap-south-1.amazonaws.com/videos/AICEE%201.mp4') }} className='p-2 text-white rounded-lg  cursor-pointer ' style={{ backgroundColor: '#04124f' }}><MDBIcon fab icon="youtube" /> AICEE Video 1</div>
                    <div onClick={() => { setVideo('https://aicee.s3.ap-south-1.amazonaws.com/videos/AICEE%202.mp4') }} className='p-2 text-white rounded-lg  cursor-pointer ' style={{ backgroundColor: '#04124f' }}><MDBIcon fab icon="youtube" /> AICEE Video 2</div>
                    <div onClick={() => { setVideo('https://counselling.aicee.in/api/v1/download/AICEE%20NEW%203.mp4') }} className='p-2 text-white rounded-lg cursor-pointer ' style={{ backgroundColor: '#04124f' }}> <MDBIcon fab icon="youtube" /> AICEE Video 3</div>
                    <div onClick={() => { setVideo('https://counselling.aicee.in/api/v1/download/AICEE%20NEW%204.mp4') }} className='p-2 text-white rounded-lg cursor-pointer ' style={{ backgroundColor: '#04124f' }}> <MDBIcon fab icon="youtube" /> AICEE Video 4</div>
                    {/* <div className='p-2 text-white rounded-lg cursor-pointer ' style={{ backgroundColor: '#04124f' }}> <MDBIcon fab icon="youtube" /> AICEE Video 5</div> */}
                </div>
            </div>
            <div><Footer /></div>
            {/* ***********************************   OTP Verification Page     **************************** */}
            {
                showOtp &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                    {/* This div creates the blur and dimmed background */}
                  

                    <MDBCard className="otpCard bg-white w-full max-w-xl mx-xxl p-2 rounded-lg shadow-lg z-50">
                        <MDBCardHeader className="text-center text-white font-bold" style={{ backgroundColor: '#04124f' }}>
                            OTP Verification
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div className="mb-3">
                                <label className="form-label font-bold text-black">Enter Mobile OTP</label>
                                <div className="flex justify-around gap-5">
                                    <div className="flex space-x-2 mb-4">
                                        {mobileOtp.map((digit, index) => (
                                            <input
                                                key={index}
                                                id={`mobile-${index}`}
                                                type="text"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) => handleChangeMobile(index, e.target.value)}
                                                className="w-8 h-8 text-center text-xl border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                            />
                                        ))}
                                    </div>
                                    <div className="flex justify-around gap-3">
                                        {mobileVerifiedSuccess === false ? (
                                            <MDBBtn className="h-10" onClick={verifyOtpPhone}>
                                                Verify OTP
                                            </MDBBtn>
                                        ) : (
                                            <p className="text-green-700 font-bold text-md">Verified</p>
                                        )}
                                    </div>
                                </div>
                                {!showPhoneResend && (
                                    <div className="mt-[-20px] text-[12px]">
                                        {canResendPhone ? (
                                            <button
                                                onClick={resendOtpPhone}
                                                className="text-blue-500 font-semibold hover:underline"
                                            >
                                                Resend OTP
                                            </button>
                                        ) : (
                                            <p className="text-gray-500">
                                                Resend available in {Math.floor(countdownPhone / 60)}:{String(countdownPhone % 60).padStart(2, '0')}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                            {/* Email OTP Section */}
                            <div className="mb-3">
                                <label className="form-label font-bold text-black">Enter Email OTP</label>
                                <div className="flex justify-around gap-5">
                                    <div className="flex space-x-2 mb-4">
                                        {emailOtp.map((digit, index) => (
                                            <input
                                                key={index}
                                                id={`email-${index}`}
                                                type="text"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) => handleChangeEmail(index, e.target.value)}
                                                className="w-8 h-8 text-center text-xl border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                            />
                                        ))}
                                    </div>
                                    <div className="flex justify-around gap-3">
                                        {emailVerifiedSuccess === false ? (
                                            <MDBBtn className="h-10" onClick={verifyOtpEmail}>
                                                Verify OTP
                                            </MDBBtn>
                                        ) : (
                                            <p className="text-green-700 font-bold text-md">Verified</p>
                                        )}
                                    </div>
                                </div>
                                {!showEmailResend && (
                                    <div className="mt-[-20px] text-[12px]">
                                        {canResendEmail ? (
                                            <button
                                                onClick={resendOtpEmail}
                                                className="text-blue-500 font-semibold hover:underline"
                                            >
                                                Resend OTP
                                            </button>
                                        ) : (
                                            <p className="text-gray-500">
                                                Resend available in {Math.floor(countdownEmail / 60)}:{String(countdownEmail % 60).padStart(2, '0')}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="mt-5 mb-3">
                                <ul className="list-disc space-y-1 text-justify">
                                    <li className="text-red-500 font-bold text-[12px]">
                                        Enter OTP & click on the Verify button to verify your mobile & Email OTP.
                                    </li>
                                    <li className="text-red-500 font-bold text-[12px]">
                                    Please wait till 60 Seconds before regenerating Mobile or Email OTP
                                    </li>
                                    <li className="text-red-500 font-bold text-[12px]">
                                        Always check your Spam box or Promotional messages if you are not getting the OTP in your inbox. Also, mark the emails as 'not spam' to ensure future emails arrive in your inbox.
                                    </li>
                                </ul>
                            </div>
                            <center><MDBBtn onClick={submitOtpPage}>Submit</MDBBtn></center>

                        </MDBCardBody>
                    </MDBCard>
                </div>
            }

            {/* **********************   LOADER START   ************************ */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}

            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* **********************   LOADER END   ************************ */}

            {/* ***********************************   OTP Verification Page     **************************** */}


            {/* FORGET PASSWORD */}

            <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader style={{ backgroundColor: '#04124f', fontWeight: 'bolder' }} className='text-white font-bold'>
                            <MDBModalTitle >Forgot Password</MDBModalTitle>
                            <MDBBtn className='btn-close' color='white' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput label='Please enter your Registered Email Address' value={forgotPassword} onChange={(e) => { setForgotPassword(e.target.value) }} id='typeURL' type='url' />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Close
                            </MDBBtn>
                            <MDBBtn onClick={forgotPasswordOfStudent}>Sent</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>




        </div>
    )
}

export default Registration