import React, { useState, useEffect } from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function Step2({ data, setActiveStep,title,getUpdate }) {
  const navigate = useNavigate()
  const [dob, setDob] = useState('')
  const [guardianName, setGuardianName] = useState('')
  const [guardianNumber, setGuardianNumber] = useState()
  const [category, setCategory] = useState('')
  const [religion, setReligion] = useState('')
  const [ews, setEws] = useState('')
  const [studentData, setStudentData] = useState({})
  const [motherName, setMotherName] = useState('')
  const [alternateNo, setAlternateNo] = useState()
  const [open, setOpen] = React.useState(false);
  const [bloodGroup, setBloodGroup] = useState('')
  const [aadhaarNo, setAadhaarNo] = useState()
  // {dob,fatherName,motherName,guardianNo,alternateNo,bloodGroup,category,religion,ews,aadhaarNo,userId:req.userID}

  // Personal Details
  async function personalDetails(event) {
    event.preventDefault()
    
     const res = await fetch('/api/v2/personal-details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(
        {dob,fatherName:guardianName,motherName,guardianNo:guardianNumber,alternateNo,bloodGroup,category,religion,ews,aadhaarNo}
      )
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.success){
          alert(data.message)
          setActiveStep(3)
          getUpdate()
        }
        else{
          alert(data.message)
        }
      })
  }
  // Personal Details Update
  async function personalDetailsUpdate() {
    
    
     const res = await fetch(`/api/v2/personal-details/${data.personalDetails._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(
        {dob,fatherName:guardianName,motherName,guardianNo:guardianNumber,alternateNo,bloodGroup,category,religion,ews,aadhaarNo}
      )
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.success){
          alert(data.message)
          setActiveStep(3)
          getUpdate()
        }
        else{
          alert(data.message)
        }
      })
  }

  const nextStep = ()=>{
    if(data.ews){
      setActiveStep(3)
    }
  }
  useEffect(() => {
    if (data.personalDetails) {
      setEws(data.personalDetails.ews)
      setCategory(data.personalDetails.category)
      setGuardianName(data.personalDetails.guardianName)
      setGuardianNumber(data.personalDetails.guardianNo)
      setReligion(data.personalDetails.religion)
      setDob(data.personalDetails.dob)
      setAlternateNo(data.personalDetails.alternateNo)
      setMotherName(data.personalDetails.motherName)
      setBloodGroup(data.personalDetails.bloodGroup)
      setAadhaarNo(data.personalDetails.aadhaarNo)
      setGuardianName(data.personalDetails.fatherName)
    }
  }, [data])
  return (
    <div className='paymentPage '>
      <h2 className='applicationCSS4 text-center pt-2 text-black font-bold'>{title}</h2>
      <div className='applicationCSS3'>
        <img className='fluid applicationCSS2' style={{ margin: 'auto' }} src="https://ucarecdn.com/f738618e-56a5-46ba-a686-f10d49aa7ea3/step2.png" alt="" />
      </div>






      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form applicationCSS'>
        <form className="row g-3 shadow-none ">
          <div className="col-md-6 ">
            <label for="inputEmail4" className="form-label text-black text-bold font-bold">DOB</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input style={{ outline: 'none' }} type="date" value={dob} onChange={(e) => { setDob(e.target.value) }} className="shadow-none form-control text-black" id="inputEmail4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Father's Name</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Fathers Name' type="text" className="form-control text-black  shadow-none" value={guardianName} onChange={(e) => { setGuardianName(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-4">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Mother's Name</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Mothers Name' type="text" className="form-control text-black  shadow-none" value={motherName} onChange={(e) => { setMotherName(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-4">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Guardian's Phone Number</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Guardian Phone Number' type="phone" className="form-control text-black  shadow-none" value={guardianNumber} onChange={(e) => { setGuardianNumber(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-4">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Applicant Aadhaar Number</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input placeholder='Applicant Aadhaar Number' type="phone" className="form-control text-black  shadow-none" value={aadhaarNo} onChange={(e) => { setAadhaarNo(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Blood Group</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select id="inputState" className="form-select text-black shadow-none" value={bloodGroup} onChange={(e) => { setBloodGroup(e.target.value) }}>
              <option value='' selected>--Select--</option>
              <option value='A+'>A+</option>
              <option value='A-'>A-</option>
              <option value='AB+'>AB+</option>
              <option value='AB-'>AB-</option>
              <option value='O+'>O+</option>
              <option value='O-'>O-</option>
              <option value='AB'>B-</option>
              <option value='B+'>B+</option>
              <option value='Not Aware'>Not Aware</option>
              
            </select>
          </div>
          <div className="col-md-4">
            <label for="inputCity" className="form-label text-black text-bold font-bold">Alternate Phone number</label>
            {/* <span className='text-red-600 text-xl font-bold'>*</span> */}
            <input placeholder='Alternate Phone Number' type="phone" className="form-control text-black shadow-none" value={alternateNo} onChange={(e) => { setAlternateNo(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Category</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select id="inputState" className="form-select text-black shadow-none" value={category} onChange={(e) => { setCategory(e.target.value) }}>
              <option value='' selected>--Select--</option>
              <option value='SC'>SC</option>
              <option value='ST'>ST</option>
              <option value='OBC'>OBC</option>
              <option value='General'>General</option>
            </select>
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Religion</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select id="inputState" className="form-select text-black shadow-none" value={religion} onChange={(e) => { setReligion(e.target.value) }}>
              <option value='' selected>--Select--</option>
              <option value='Hindu'>Hindu</option>
              <option value='Muslim'>Muslim</option>
              <option value='Sikh'>Sikh</option>
              <option value='Any Other'>Any Other</option>
            </select>
          </div>


          <div className="col-md-6">
            <label for="inputState" className="form-label text-black text-bold font-bold">Whether belong to economically weaker section <span className='text-red-600 text-xl font-bold'>*</span></label>
            <select id="inputState" className="form-select text-black shadow-none" value={ews} onChange={(e) => { setEws(e.target.value) }}>
              <option value='' selected>--Select--</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
            </select>
          </div>


        </form>
        <div className="col-12 flex justify-between flex-row-reverse mt-2">
          <button onClick={data.personalDetails ? personalDetailsUpdate : personalDetails} className="btn btn-primary">Save & Continue</button>
          <button onClick={() => { setActiveStep(1) }} className="btn btn-primary">Back</button>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Step2



