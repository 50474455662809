import React from 'react'
import Registrations from '../Pages/Registration/Registration'

function Registration() {
  return (
    <div>
      <Registrations/>
    </div>
  )
}

export default Registration