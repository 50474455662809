import React,{useState,useEffect} from 'react'
import {MDBIcon} from 'mdb-react-ui-kit'
import PaymentReceipt from './PaymentReceipt';
const PaymentTable = ({data,program,title}) => {
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  return (
    <div><div className="overflow-x-auto relative pl-2">
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs  uppercase dark:bg-gray-700 dark:text-gray-400 bg-gray-500]" style={{backgroundColor:'#042e2e',color:'white'}}>
        <tr>
          <th scope="col" className="py-3 px-6 text-start">
            Application Form
          </th>
          <th scope="col" className="py-3 px-6 text-center">
            Transaction Status
          </th>
          <th scope="col" className="py-3 px-6 text-center">
            Payment Receipt
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 font-extrabold text-black">
          <th scope="row" className="py-4 px-6 text-black font-extrabold whitespace-nowrap dark:text-white">
          {title}
          </th>
          <td className="py-4 px-6 text-center">
           Success
          </td>
          <td className="py-4 px-6 text-center">
            <button 
            onClick={()=>{setFullscreenXlModal(!fullscreenXlModal)}}
            ><MDBIcon className='text-red-700 text-3xl' far icon="file-pdf" /> <br /> Download</button>
          </td>
        </tr>
      </tbody>
    </table>

    {
        fullscreenXlModal && <PaymentReceipt modal={fullscreenXlModal} setModal={setFullscreenXlModal} data={data} program={program} />
    }
  </div></div>
  )
}

export default PaymentTable