import React, { useState, useEffect } from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import UpperNavbar from '../../Dashboard/UpperNavbar';
function Step1() {
  const navigate = useNavigate()
  const [studentData, setStudentData] = useState({})

  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
      })
      .catch(error => {
        console.log(error)
      })
  }


  useEffect(() => {
    userAuthenticationData()
  }, [])
  return (
    <div className='paymentPage'>
      <UpperNavbar />
      <h2 className='applicationCSS4 text-center pt-2 text-black font-thin mt-4' style={{ fontWeight: '600',fontSize:'22px',color:'#0e2e2e' }}>UG Application Form 2023 (OTHER)</h2>
      <div className='applicationCSS3'>
        <img className='applicationCSS2 fluid' style={{margin:'auto'}} src="https://ucarecdn.com/ff44cac1-946f-4b5b-a537-2ea57fcff3a8/step1.png" alt="" />
      </div>








      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form applicationCSS'>

        <form className="row g-3 text-black" style={{ fontWeight: 'bold', color: 'black' }} >
          <div className="col-md-6 text-black">
            <label for="inputEmail4" className="form-label text-black font-bold">Name Of Candidate</label><span className='text-red-600 text-xl'>*</span>
            <input disabled style={{ outline: 'none' }} type="text" value={studentData.studentName} className="form-control shadow-none text-black cursor-no-drop" id="inputEmail4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black">Mobile No</label><span className='text-red-600 text-xl'>*</span>
            <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" value={studentData.mobileNo} id="inputPassword4" />
          </div>
          <div className="col-6">
            <label for="inputAddress" className="form-label text-black">Email ID</label><span className='text-red-600 text-xl'>*</span>
            <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress" value={studentData.emailId} placeholder="1234 Main St" />
          </div>
          <div className="col-6">
            <label for="inputAddress2" className="form-label text-black">Gender</label><span className='text-red-600 text-xl'>*</span>
            <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress2" value={studentData.gender} placeholder="Apartment, studio, or floor" />
          </div>
          <div style={{ width: '99%', margin: 'auto', border: '1px solid black', marginTop: '1rem', backgroundColor: '#0e2e2e', borderRadius: '5px' }} className='text-white font-thin p-1'>

            <i className='fa fa-info-circle text-xl' />&nbsp; &nbsp;<span className=''>Instructions</span>
            <li className='pl-10 text-sm'>All fields marked with <span className='text-red-700 text-2xl font-bold'>*</span> are mandatory to be filled.</li>
            <li className='pl-10 text-sm'>Passport sized photograph with maximum size up to 100 KB and signature with maximum size up to 50 KB.</li>
            {/* <li className='pl-10 text-sm'>After successful submission of the application form, an application number will be generated.</li> */}
            <li className='pl-10 text-sm'>If you face any technical issues while filling application, please contact us on 9031863216 or write us a mail on aicee.info@gmail.com.</li>
          </div>
          <div className="col-12 flex flex-row-reverse" style={{}}>
            <button onClick={() => { navigate('/online-all-ther-program-fillApplication') }} type="submit" className="btn btn-primary">Save & Continue</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Step1



