import React from 'react'
import Sidebar from '../../Components/collegePortal/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../Components/collegePortal/Navbar';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AddProgramme from '../../Components/collegePortal/AddProgramme';
import DeleteIcon from '@mui/icons-material/Delete';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SeatDetails = () => {


  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const editHandleClose = () => {
    setEditOpen(false);
  };
  const editHandleClickOpen = () => {
    setEditOpen(true);
  };
  return (
    <div className='bg-[#f7f7fc]' style={{ overflow: 'auto', position: 'relative' }}>
      <Navbar />
      <Box height={80} />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <HomeStyled className='' style={{ overflow: 'scroll' }}>

          <center className='headerName'>Programme wise Seat and fee Details</center>
          <div className='flex justify-end p-3'>
            <div><Button variant="outlined" onClick={handleClickOpen}>Add Programme</Button></div>
            <div></div>
          </div>
          {/* // table start */}
          <div className='table-responsive' style={{ height: "70vh" }}>
            <table class="table caption-top ">
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th scope="col">Program</th>
                  <th scope="col">Branch</th>
                  <th scope="col">Seat</th>
                  <th scope="col">First Year Fee</th>
                  <th scope="col">Total Program Fee</th>
                  <th scope="col" >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <th scope="row">1</th>
                  <td>2023148456</td>
                  <td>Testing</td>
                  <td>Engineering</td>
                  <td>25000</td>

                  <td>
                    <ModeEditOutlineIcon className='text-green-600 cursor-pointer' onClick={editHandleClickOpen}></ModeEditOutlineIcon>
                    &nbsp; &nbsp;<DeleteIcon className='text-red-600 cursor-pointer' onClick={editHandleClickOpen}></DeleteIcon>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          {/* // table end */}

        </HomeStyled>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AddProgramme handleClose={handleClose} heading="Add Programme and Seat Details. "/>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={editOpen}
        onClose={editHandleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AddProgramme handleClose={editHandleClose} heading="Edit Programme and Seat Details. "/>
      </Dialog>
    </div>
  )
}


const HomeStyled = styled.main`
    /* *{
        overflow: auto;
        position: relative;
    } */
    .headerName{
        font-size:25px;
        text-align: center;
        color:white;
        font-weight: bolder;
        width:200vh;
        padding: 5px;
        overflow: hidden;
        background-color: #3d3939;
        
    }
    @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .headerName{
        font-size:25px;
        text-align: center;
        color:#3d3939;
        font-weight: bolder;
        width:80vh;
        padding: 5px;
    }
}


`
export default SeatDetails