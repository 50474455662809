import React from 'react'
import calendar from './img/calendar.png'
import solid from './img/link-solid.svg'
import newicon from './img/newicon.gif'
import pdf from './img/pdf.png'
import {Link} from 'react-router-dom'

function LeftBar() {
  return (
    <div>
        <div className="boxdesignCurrentEvents">



<h5 className="currentEventHeading" style={{ display: 'flex' }}>
    Current Events
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img src={calendar}
        width="10%" />
</h5>
<nav id="sidebar">
    <ul className="list-unstyled components">
        <li style={{ listStyle: 'none' }} className="CE_List">
            <Link to="/about"  className="parentLink">About AICEE </Link>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List">
            <a href='https://aicee.s3.ap-south-1.amazonaws.com/AICEE Important dates.pdf' target='_black'  className="parentLink"> Important Dates
            </a>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List nav-item dropdown">
            <Link className="parentLink nav-link  " id="navbardrop" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                to='/syllabous'>Syllabus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
            <div className="dropdown-menu" aria-labelledby="navbardrop" id="myDropdown">
                <a className="dropdown-item sublink" href="doc/SYLLABUS FOR 10TH LEVEL STUDENT.pdf"
                    > 10th </a>
                <a className="dropdown-item sublink" href="doc/SYLLABUS FOR 12TH LEVEL STUDENT.pdf"
                    > 12th </a>
                <a className="dropdown-item sublink" href="doc/gradsyllabus.pdf" > Graduation
                </a>
            </div>
        </li>
        {/* <li style={{ listStyle: 'none' }} className="CE_List">
            <Link to="/sample-question-paper-2023"  className="parentLink"> AICEE Sample Question Papers
                2023 (Phase 1). </Link>
        </li> */}
        <li style={{ listStyle: 'none' }} className="CE_List">
            <a href='https://aicee.s3.ap-south-1.amazonaws.com/List of All  Examination Centres.pdf' target='_black'  className="parentLink"> Exam centre ( For
                Centre Based Examination Mode Only ) </a>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List">
            <a href='https://aicee.s3.ap-south-1.amazonaws.com/IMPORTANT INSTRUCTIONS.pdf' target='_blank'  className="parentLink">Important
                Instructions </a>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List">
            <a href='https://aicee.s3.ap-south-1.amazonaws.com/IMPORTANT INSTRUCTIONS FOR NRI.pdf' target='_blank'  className="parentLink">Important Instructions for NRI / Foreign Candidates </a>
        </li>
    </ul>
</nav>
</div>
<div className="boxdesignImpLinks">
<h5 className="impHeading">
    Important Links&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={solid} width="5%" />
</h5>
<nav id="sidebar">
    <ul className="list-unstyled components">

        {/* <li style={{listStyle: 'none'}} >
            <a style={{display:'flex',justifyContent:'space-around'}}  href="/refund.php" target='blank'>AICEE Refund Request Application. <sup><img
                src={newicon} alt='new' /></sup></a>
        </li> */}

        {/* <li style={{ listStyle: 'none' }}>
            <a href="/doc/Dates & Instructions.pdf" target='blank'>Counselling Update : Dates &
                Instructions.(Click here)</a>
        </li> */}

        {/* <li style={{ listStyle: 'none' }}>
            <a href="https://counselling.aicee.in" target='blank'>AICEE Phase 1&2 Result Link.(click
                here)</a>
        </li> */}

        <li style={{ listStyle: 'none' }}>
            <a style={{display:'flex',justifyContent:'space-around'}} href='https://aicee.s3.ap-south-1.amazonaws.com/Programs Offered  Through AICEE 2025.pdf' target="_blank" 
                className="parentLink">PRORGRAMS OFFERED THROUGH AICEE - 2025 (LEVEL WISE) <sup><img
                    src={pdf} style={{ width: "20px" }} /></sup></a>
        </li>
        <li style={{ listStyle: 'none' }}>
            <a style={{display:'flex',justifyContent:'start'}} href='https://aicee.s3.ap-south-1.amazonaws.com/AICEE - 2025 INFORMATION BROCHURE.pdf' target="_blank" 
                className="parentLink">Information Brochure 2025 <sup><img
                    src={pdf} style={{ width: "20px" }} /></sup></a>
        </li>
        {/* <li style={{ listStyle: 'none' }}>
            <a style={{display:'flex',justifyContent:'start'}} href="https://counselling.aicee.in/spot/student/login" target="_blank" 
                className="parentLink">AICEE 2023 Spot Round Counseling Portal </a>
        </li> */}

    </ul>
</nav>
</div>
    </div>
  )
}

export default LeftBar