import React from 'react'
import { useNavigate } from 'react-router-dom'
function Step1({ data, setActiveStep,title }) {

    const navigate = useNavigate()

    const { name, email, gender, mobileNo } = data


    return (
        <div className='paymentPage'>

            <h2 className='applicationCSS4 text-center pt-2 text-black font-bold'>{title} </h2>
            <div className='applicationCSS3'>
                <img className='fluid applicationCSS2' src="https://ucarecdn.com/ff44cac1-946f-4b5b-a537-2ea57fcff3a8/step1.png" alt="" />
            </div>
            {/* ********************************   form                **************************** */}
            <div className='p-4 bg-white bootstrap-form applicationCSS'>

                <form className="row g-3 text-black" style={{ fontWeight: 'bold', color: 'black' }} >
                    <div className="col-md-6 text-black">
                        <label for="inputEmail4" className="form-label text-black font-bold">Name Of Candidate</label><span className='text-red-600 text-xl'>*</span>
                        <input disabled style={{ outline: 'none' }} type="text" value={name} className="text-capitalize form-control shadow-none text-black cursor-no-drop" id="inputEmail4" />
                    </div>
                    <div className="col-md-6">
                        <label for="inputPassword4" className="form-label text-black">Mobile No</label><span className='text-red-600 text-xl'>*</span>
                        <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" value={mobileNo} id="inputPassword4" />
                    </div>
                    <div className="col-6">
                        <label for="inputAddress" className="form-label text-black">Email ID</label><span className='text-red-600 text-xl'>*</span>
                        <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress" value={email} placeholder="1234 Main St" />
                    </div>
                    <div className="col-6">
                        <label for="inputAddress2" className="form-label text-black">Gender</label><span className='text-red-600 text-xl'>*</span>
                        <input disabled type="text" className="form-control shadow-none text-black cursor-no-drop" id="inputAddress2" value={gender} placeholder="Apartment, studio, or floor" />
                    </div>
                    <div style={{ width: '99%', margin: 'auto', border: '1px solid black', marginTop: '1rem', backgroundColor: '#0e2e2e', borderRadius: '5px' }} className='text-white font-thin p-1'>

                        <i className='fa fa-info-circle text-xl' />&nbsp; &nbsp;<span className=''>Instructions</span>
                        <li className='pl-10 text-sm'>All fields marked with <span className='text-red-700 text-2xl font-bold'>*</span> are mandatory to be filled.</li>
                        <li className='pl-10 text-sm'>Passport sized photograph with maximum size up to 100 KB and signature with maximum size up to 50 KB.</li>
                        {/* <li className='pl-10 text-sm'>After successful submission of the application form, an application number will be generated.</li> */}
                        <li className='pl-10 text-sm'>If you face any technical issues while filling application, please contact us on +91 7488661870 or write us a mail on info@aicee.in or aicee.info@gmail.com .</li>
                    </div>
                    <div className="col-12 flex flex-row-reverse" style={{}}>
                        <button onClick={() => { setActiveStep(2) }} className="btn btn-primary">Save & Continue</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Step1



