
import React, { useState, useEffect } from 'react';
function Captcha({captcha,handleChange}) {
    

    return (
        <div class="container">

            <div class="row mt-3">
                <div class="col-md-4">
                </div>

                <div class=" flex " style={{ justifyContent: 'space-between' }}>
                    <div >
                        <div class="form-group row">
                            <input type="text" id="inputType" className="form-control" placeholder="Enter Captcha"
                                name="username" onChange={handleChange} autocomplete="off" style={{ width: '10rem',outLine:'none',textTransform:'none' }}
                            />
                            {/* <button type="button" id="succesBTN" onClick={onSubmit} class="btn btn-primary ml-1">Verify Captcha</button> */}

                        </div>
                    </div>
                    <div style={{marginTop:'-17px'}}>

                        <h4 id="captcha" style={{ marginTop: "1.3rem", marginLeft: "25px", position: "absolute",textTransform:'none' }}>{captcha}</h4>


                        <div class="form-group row" style={{ width: '10rem' }}>
                            <img style={{height:'2.5rem'}} src="https://png.pngtree.com/thumb_back/fh260/background/20210216/pngtree-graph-chat-background-image-image_562455.jpg" className="mt-3 mb-3" height="50" />
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}
export default Captcha;