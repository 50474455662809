import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import UpperNavbar from './UpperNavbar'
import { MDBContainer, MDBCard, MDBRow, MDBCol, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import AllApplication from './AllApplication';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import DashboardCard from '../../Components/Dashboard/DashboardCard';
function Dashboard() {
    //    STUDENT DATA
    const [studentdata, setData] = useState({})
    const [noApplication, setNoApplication] = useState('')
    const [mba, setMba] = useState(0)
    const [engg, setEngg] = useState(0)
    const [pcm, setPcm] = useState(0)
    const [pcb, setPcb] = useState(0)
    const [other, setOther] = useState(0)
    const [mbaCompleted, setMbaCompleted] = useState(0)
    const [enggCompleted, setEnggCompleted] = useState(0)
    const [pcmCompleted, setPcmCompleted] = useState(0)
    const [pcbCompleted, setPcbCompleted] = useState(0)
    const [otherCompleted, setOtherCompleted] = useState(0)
    const [open, setOpen] = React.useState(true);
    const [noOfApplication, setNoOfApplication] = useState(mba + engg)
    // console.log(studentdata)
    const userAuthenticationData = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                setData(data)
                if (data.MBAApplicationNo) {

                    if (data.MBACompleted) {
                        setMbaCompleted(1)
                    }
                    else {
                        setMba(1)
                    }
                }
                else {

                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const userAuthenticationData2 = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                // setData(data)
                if (data.EnggApplicationNo) {

                    if (data.EnggCompleted) {
                        setEnggCompleted(1)
                    }
                    else {
                        setEngg(1)
                    }
                }
                else {

                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const userAuthenticationData3 = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                // setData(data)
                if (data.PCMApplicationNo) {

                    if (data.PCMCompleted) {
                        setPcmCompleted(1)
                    }
                    else {
                        setPcm(1)
                    }
                }
                else {

                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const userAuthenticationData4 = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                // setData(data)
                if (data.PCBApplicationNo) {

                    if (data.PCBCompleted) {
                        setPcbCompleted(1)
                        setOpen(false)
                    }
                    else {
                        setPcb(1)
                        setOpen(false)
                    }
                }
                else {
                    setOpen(false)
                }
                // setOpen(false)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const userAuthenticationData5 = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                // setData(data)
                if (data.OtherApplicationNo) {

                    if (data.OtherCompleted) {
                        setOtherCompleted(1)
                    }
                    else {
                        setOther(1)
                    }
                }
                else {

                }
            })
            .catch(error => {
                console.log(error)
            })
    }



    useEffect(() => {
        userAuthenticationData()

    }, [])
    useEffect(() => {
        userAuthenticationData2()

    }, [])
    useEffect(() => {
        userAuthenticationData3()

    }, [])
    useEffect(() => {
        userAuthenticationData4()

    }, [])
    useEffect(() => {
        userAuthenticationData5()
    }, [])

    return (
        <div className='paymentPage'>
            <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', width: '100%' }}>
                <div className='' style={{ width: '100%' }}>
                    <div><UpperNavbar data={studentdata}/></div>

                    
                    <div className='flex'>
                        <div><SideBar /></div>
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <div style={{ backgroundColor: 'white', width: '100%', height: '90vh', overflow: 'scroll' }}>
                                <h3 className=' pl-3 pr-3 text-black text-2xl' style={{ fontWeight: '500' }}>Dashboard</h3>
                                <hr />
                                <DashboardCard data={studentdata}/>
                                <div className='mt-4'>
                                    <AllApplication data={studentdata} />
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Dashboard