import React from 'react'

const Navbar = (props) => {
    
    return (
        <div>
            <>
                {/* Navbar */}
                <nav className="navbar navbar-expand-lg navbar-light bg-[#042e2e]">
                    {/* Container wrapper */}
                    <div className="container">
                        {/* Navbar brand */}
                        <a className="navbar-brand me-2" href="https://mdbgo.com/">
                            <img
                                src="https://ucarecdn.com/c048ae5b-1a85-481d-9ba1-0dfb344701af/AICEE2023LOGOPNG.png"
                                height={16}
                                width={130}
                                alt="MDB Logo"
                                loading="lazy"
                                style={{ marginTop: "-1px" }}
                            />
                        </a>
                        {/* Toggle button */}
                        <img
                            src={props.data && props.data.photo}
                            class="rounded-circle border border-white"
                            height="35"
                            width='35'
                            alt="Black and White Portrait of a Man"
                            loading="lazy"
                        />





                    </div>
                    {/* Container wrapper */}
                </nav>
                {/* Navbar */}
            </>

        </div>
    )
}

export default Navbar