import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Widget } from '@uploadcare/react-widget'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const Qualification = ({ data, onchange, value, route }) => {

    const navigate = useNavigate()
    const [photo, setPhoto] = useState('')
    const [signature, setSignature] = useState('')
    const [updatedList, setUpdatedList] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [tenthBoard, setTenthBoard] = useState('')
    const [twelveBoard, setTwelveBoard] = useState('')
    const [gradBoard, setGradBoard] = useState('')
    const [tenthTotal, setTenthTotal] = useState('')
    const [twelveTotal, setTwelveTotal] = useState('')
    const [gradTotal, setGradTotal] = useState('')
    const [tenthObtained, setTenthObtained] = useState('')
    const [twelveObtained, setTwelveObtained] = useState('')
    const [gradObtained, setGradObtained] = useState('')
    const [checked, setChecked] = useState(false)
    const [preview, setPreview] = useState(false)
    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShow = () => setOptSmModal(!optSmModal);

    console.log('photo', photo)
    console.log('signature', signature)
    //       UPDATE PHOTO AND SIGNATURE OF USER
    async function updatePhotoSignature() {
        // event.preventDefault()

        const response = await fetch('/document-update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: studentData._id,
                photo,
                signature
            })
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
                if (data.message == "changes successfully") {
                    // alert('changes successfully')
                    // setCentredModal(false)
                    navigate('/fillApplication-step6')
                }
                else {
                    alert('Error')
                    // setCentredModal(false)
                }
            })
    }


    //   DOCUMENT UPLOAD THROUGH UPLOADCARE.Com

    // PHOTO

        const uploadFileChange = async (file) => {
            console.log("file change: ", file);
            const formData = new FormData(); // Create a new FormData object
            formData.append('file', file); // Append the file to the FormData object
        
            try {
                const response = await axios.post('/api/v2/file-upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type
                    },
                });
                console.log("response:", response);
                toast.success(response.data.message);
                setPhoto(response.data.data);
                onchange.setPhoto(response.data.data)
              
            } catch (error) {
                console.error(error);
                toast.error(error.response?.data?.message || "An error occurred");
            }
        };
    // SIGNATURE
    const uploadFileChange2 = async (file) => {
        console.log("file change: ", file);
        const formData = new FormData(); // Create a new FormData object
        formData.append('file', file); // Append the file to the FormData object
    
        try {
            const response = await axios.post('/api/v2/file-upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type
                },
            });
            console.log("response:", response);
            toast.success(response.data.message);
            setSignature(response.data.data);
            onchange.setSignature(response.data.data)
            
        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };
    
    const uploadFileSelect2 = (file) => {
        console.log(`file changed ${file}`)
        setUpdatedList(false)
        if (file) {
            file.progress((info) => console.log("file progress: ", info.progress))
            file.done((info) => setUpdatedList(true))
        }
    }
    useEffect(() => {
        if (data.educationDetails) {
            setPhoto(data.educationDetails.photo)
            console.log("photo........", data.photo)
            setSignature(data.educationDetails.signature)
            setGradBoard(data.educationDetails.gradBoard)
            setTenthBoard(data.educationDetails.tenthBoard)
            setTwelveBoard(data.educationDetails.twelveBoard)
            setTenthObtained(data.educationDetails.tenthObtained)
            setTwelveObtained(data.educationDetails.twelveObtained)
            setGradObtained(data.educationDetails.gradObtained)
            setTenthTotal(data.educationDetails.tenthTotal)
            setTwelveTotal(data.educationDetails.twelveTotal)
            setGradTotal(data.educationDetails.gradTotal)
        }
    }, [data])
    return (
        <div className="overflow-x-auto relative">
        <ToastContainer />
            <div>
                <p className='font-bold text-black'>Academic Information</p>
                <p className='font-bold text-black text-[14px]'>
                    {/* Higher Secondary Academic Details <span className='text-red-600'>*</span> */}
                </p>
                <table className='border border-black text-[14px] w-[100%] overflow-x-scroll'>
                    <thead>
                        <tr className='border border-black'>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Qualification
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                School/Institute name
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Board
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Result Status
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Year Of Passing
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Obtained Percentage / CGPA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border text-center font-bold border-black'>
                                10th <sup><span className='text-red-600 '>*</span></sup>
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.tenthSchool} onChange={(e) => { onchange.setTenthSchool(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' placeholder="Eg: xyz School" type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.tenthBoard} onChange={(e) => { onchange.setTenthBoard(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none ' placeholder='Eg: C.B.S.E Board' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <select value={value.tenthResultStatus} onChange={(e) => { onchange.setTenthResultStatus(e.target.value) }} name="" id="" className='border w-[100%] p-[8px] outline-none border-black'>
                                    <option value="">--Select--</option>
                                    <option value="Awaited">Awaited</option>
                                    <option value="Declared">Declared</option>
                                </select>
                            </td>
                            <td className='border  p-2 border-black'>
                                <input value={value.tenthYOP} onChange={(e) => { onchange.setTenthYOP(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.tenthPer} onChange={(e) => { onchange.setTenthPer(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                        </tr>
                        <tr>
                            <td className='border text-center font-bold border-black'>
                                12th {route == "mba" && <sup><span className='text-red-600 '>*</span></sup>}
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelveSchool} onChange={(e) => { onchange.setTwelveSchool(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' placeholder="Eg:xyz School / College" type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelveBoard} onChange={(e) => { onchange.setTwelveBoard(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none' placeholder='Eg: C.B.S.E Board' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <select name="" id="" value={value.twelveResultStatus} onChange={(e) => { onchange.setTwelveResultStatus(e.target.value) }} className='border w-[100%] p-[8px] outline-none border-black'>
                                    <option value="">--Select--</option>
                                    <option value="Awaited">Awaited</option>
                                    <option value="Declared">Declared</option>
                                </select>
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelveYOP} onChange={(e) => { onchange.setTwelveYOP(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelvePer} onChange={(e) => { onchange.setTwelvePer(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                        </tr>
                        {
                            route == 'mba' &&

                            <tr>
                                <td className='border text-center font-bold border-black'>
                                    Graduation
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradSchool} onChange={(e) => { onchange.setGradSchool(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' placeholder="Ex:xyz School / College" type="text" />
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradBoard} onChange={(e) => { onchange.setGradBoard(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-uppercase' type="text" />
                                </td>
                                <td className='border p-2 border-black'>
                                    <select name="" id="" value={value.gradResultStatus} onChange={(e) => { onchange.setGradResultStatus(e.target.value) }} className='border w-[100%] p-[8px] outline-none border-black'>
                                        <option value="">--Select--</option>
                                        <option value="Awaited">Awaited</option>
                                        <option value="Declared">Declared</option>
                                    </select>
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradYOP} onChange={(e) => { onchange.setGradYOP(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradPer} onChange={(e) => { onchange.setGradPer(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className="grid sm:flex justify-between mt-4" style={{ width: '100%' }}>
    <div className="col-md-5 gap-2">
        <div>
            <p className="text-[14px] text-black font-bold">Upload Your Recent Passport Size Photograph <sup className="text-red-600 text-[16px]">*</sup></p>
            <div className="mt-[-10px] border border-black text-black p-1 flex items-center justify-start">
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => uploadFileChange(e.target.files[0])}
                    className=""
                    id="photo-upload"
                />
                {/* <label
                    htmlFor="photo-upload"
                    className="cursor-pointer w-full h-32 flex items-center justify-center border-2 border-dashed border-gray-400 hover:border-gray-600 transition"
                >
                    <span className="text-gray-500">Drag & Drop or Click to Upload Photo</span>
                </label> */}
            </div>
            <span className="text-[12px] text-blue-600">Max Upload Limit 100 KB</span>
        </div>
        {photo && (
            <div>
                <img
                    style={{ width: '150px', height: '150px', margin: 'auto', marginTop: '1rem' }}
                    className="border border-black p-2"
                    src={photo}
                    alt="photo"
                />
            </div>
        )}
    </div>
    <div className="col-md-5 gap-4">
        <div>
            <p className="text-[14px] text-black font-bold">Upload Your Signature <sup className="text-red-600 text-[16px]">*</sup></p>
            <div className="mt-[-10px] border border-black text-black p-1 flex items-center justify-start">
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => uploadFileChange2(e.target.files[0])}
                    className=""
                    id="signature-upload"
                />
            </div>
            <span className="text-[12px] text-blue-600">Max Upload Limit 50 KB</span>
        </div>
        {signature && (
            <div style={{ width: '150px', height: '150px', margin: 'auto', verticalAlign: 'center', marginTop: '1rem' }} className="border border-black">
            <img 
                        style={{ width: '150px', height: '150px', margin: 'auto' }} 
                        src={signature} 
                        alt="Uploaded Signature" 
                    />
            </div>
        )}
    </div>
</div>

        </div>
    )
}

export default Qualification