import * as React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import ApplicationDetails from './ApplicationDetails';
import DocumentDetails from './DocumentDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StudentDetails = (props) => {
    return (
        <div> <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{backgroundColor:'#3d3939'}}>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={props.handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Application Details
                </Typography>
                <Button autoFocus color="inherit" onClick={props.handleClose}>
                    save
                </Button>
            </Toolbar>
        </AppBar>
           <div style={{padding:'10px'}}>
           <Alert variant="filled" severity="success">All Details that Student fill in Application. </Alert>
           <ApplicationDetails/>
           <Alert variant="filled" severity="info" style={{marginTop:'10px'}}>Document Details. </Alert>
           <DocumentDetails/>
           </div>
            </div>
    )
}

export default StudentDetails