import React, { useState, useEffect } from 'react'
import Profiles from '../Pages/Dashboard/Profile';
import SideBar from '../Pages/Dashboard/SideBar';
import UpperNavbar from '../Pages/Dashboard/UpperNavbar';
import '../Pages/Dashboard/Dashboard.css'
import { useNavigate } from 'react-router-dom'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import AllApplication from '../Pages/Dashboard/AllApplication';

function Profile() {
  const navigate = useNavigate()
  const [studentData, setStudentData] = useState({})
  const [centredModal, setCentredModal] = useState(false);
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  // console.log(password)
  // console.log(confirmPassword)
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
      })
      .catch(error => {
        console.log(error)
      })
  }


  useEffect(() => {
    userAuthenticationData()
  }, [])

  //    PASSWORD CHANGES

  async function changPassword() {
    // event.preventDefault()
    if (password == confirmPassword) {
      // setOpen(true)
      const response = await fetch('/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password
        })
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data)
          if (data.success) {
            alert(data.message)
            setCentredModal(false)
            setPassword("")
            setConfirmPassword("")
          }
          else {
            alert(data.message)
            setCentredModal(false)
          }
        })
    }
    else {
      alert('password does not match')
    }

  }
  return (
    <div className='paymentPage'>
      {/* <MDBBtn onClick={toggleShow}>Vertically centered modal</MDBBtn> */}

      <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className='text-black font-bold'>Change Password</MDBModalTitle>
              {/* <MDBBtn className='btn-close' color='none' ></MDBBtn> */}
            </MDBModalHeader>
            <MDBModalBody>
              <div class="mb-3">
                <label for="formGroupExampleInput" class="form-label text-black font-bold">New Password</label>
                <input type="password" class="form-control text-black" onChange={(e) => { setPassword(e.target.value) }} value={password} id="formGroupExampleInput" />
              </div>
              <div class="mb-3">
                <label for="formGroupExampleInput2" class="form-label text-black font-bold">Confirm New Password</label>
                <input type="text" class="form-control text-black" onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} id="formGroupExampleInput2" />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button className='pl-5 pt-1 pb-1 pr-5 rounded-lg ' onClick={() => { setCentredModal(false) }} style={{ backgroundColor: 'red', color: 'white' }}>
                Cancel
              </button>
              <MDBBtn onClick={() => { changPassword() }}>Save changes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <div style={{ height: '100vh', overflow: 'scroll initial', width: '100%' }}>
        <div>
          <UpperNavbar data={studentData}/>

        </div>
        <div className='flex ' style={{ height: '90vh' }}>
          <SideBar />
          <div className='mt-2' style={{ backgroundColor: '#f1f3fa', width: '100%', height: '90vh', overflow: 'scroll' }}>
            <div className='flex justify-between items-center'><h3 className='pt-3 pl-3 pr-3 text-black text-2xl font-bold' style={{ fontWeight: 'bolder' }}>My Profiles</h3>
              <button onClick={() => { setCentredModal(true) }} className='bg-blue text-white p-2 text-sm font-bold' style={{ backgroundColor: '#04124f', borderRadius: '7px' }}>Change Password</button>
            </div>
            <hr />
            <Profiles data={studentData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile