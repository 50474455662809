import React, { useState, useEffect } from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import UpperNavbar from '../../Dashboard/UpperNavbar';
import { useNavigate } from 'react-router-dom'
function Step2() {
  const navigate = useNavigate()
  const [dob, setDob] = useState('')
  const [guardianName, setGuardianName] = useState('')
  const [guardianNumber, setGuardianNumber] = useState('')
  const [category, setCategory] = useState('')
  const [religion, setReligion] = useState('')
  const [ews, setEws] = useState('')
  const [studentData, setStudentData] = useState({})
  const [motherName,setMotherName] = useState('')
  const [alternateNo,setAlternateNo] = useState('')
  //        USER AUTHENTICATION            //
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
        if(data.ews){
          setEws(data.ews)
          setCategory(data.category)
          setGuardianName(data.guardianName)
          setGuardianNumber(data.guardianNumber)
          setReligion(data.religion)
          setDob(data.dob)
          setAlternateNo(data.alternateNo)
          setMotherName(data.motherName)
        }
        else{

        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  //       UPDATE PERSONAL DETAILS OF USER
  // console.log(studentData._id, dob, guardianName, guardianNumber, category, religion, ews)
  async function updateAddress() {
    // event.preventDefault()

    const response = await fetch('/personal-details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: studentData._id,
        dob,
        guardianName,
        guardianNumber,
        category,
        religion,
        ews,
        motherName,
        alternateNo
      })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.message == "changes successfully") {
          // alert('changes successfully')
          // setCentredModal(false)

          navigate('/online-pcbh-fillApplication')
        }
        else {
          // alert('Error')
          // setCentredModal(false)
        }
      })
  }
  useEffect(() => {
    userAuthenticationData()
  }, [])





  return (
    <div className='paymentPage'>
      <UpperNavbar />
      <h2 className='applicationCSS4 text-center pt-2 text-black font-thin mt-4' style={{ fontWeight: '600',fontSize:'22px',color:'#0e2e2e' }}>UG Application Form 2023 (PCB) </h2>
      <div className='applicationCSS3'>
        <img className='applicationCSS2 fluid' style={{margin:'auto'}} src="https://ucarecdn.com/f738618e-56a5-46ba-a686-f10d49aa7ea3/step2.png" alt="" />
      </div>






      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form applicationCSS'>
        <form className="row g-3 shadow-none">
          <div className="col-md-6 ">
            <label for="inputEmail4" className="form-label text-black text-bold font-bold">DOB</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input style={{ outline: 'none' }} type="date" value={dob} onChange={(e) => { setDob(e.target.value) }} className="shadow-none form-control text-black" id="inputEmail4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Father's Name</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" className="form-control text-black  shadow-none" value={guardianName} onChange={(e) => { setGuardianName(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Mother's Name</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" className="form-control text-black  shadow-none" value={motherName} onChange={(e) => { setMotherName(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-6">
            <label for="inputPassword4" className="form-label text-black text-bold font-bold">Guardian's Phone Number</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="text" className="form-control text-black  shadow-none" value={guardianNumber} onChange={(e) => { setGuardianNumber(e.target.value) }} id="inputPassword4" />
          </div>
          <div className="col-md-4">
            <label for="inputCity" className="form-label text-black text-bold font-bold">Alternate Phone number</label><span className='text-red-600 text-xl font-bold'>*</span>
            <input type="number" className="form-control text-black shadow-none" value={alternateNo} onChange={(e) => { setAlternateNo(e.target.value) }} id="inputCity" />
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Category</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select id="inputState" className="form-select text-black shadow-none" value={category} onChange={(e) => { setCategory(e.target.value) }}>
              <option value='' selected>--Select--</option>
              <option value='SC'>SC</option>
              <option value='ST'>ST</option>
              <option value='OBC'>OBC</option>
              <option value='General'>General</option>
            </select>
          </div>
          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Religion</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select id="inputState" className="form-select text-black shadow-none" value={religion} onChange={(e) => { setReligion(e.target.value) }}>
              <option value='' selected>--Select--</option>
              <option value='Hindu'>Hindu</option>
              <option value='Muslim'>Muslim</option>
              <option value='Sikh'>Sikh</option>
              <option value='Any Other'>Any Other</option>
            </select>
          </div>

          <div className="col-md-4">
            <label for="inputState" className="form-label text-black text-bold font-bold">Whether belong to economically weaker section <span className='text-red-600 text-xl font-bold'>*</span></label>
            <select id="inputState" className="form-select text-black shadow-none" value={ews} onChange={(e) => { setEws(e.target.value) }}>
              <option value='' selected>--Select--</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
            </select>
          </div>


        </form>
        <div className="col-12 flex justify-between flex-row-reverse mt-2">
          <button disabled={!dob || !guardianName || !guardianNumber || !category || !religion || !ews} onClick={updateAddress} className="btn btn-primary">Save & Continue</button>
          <button onClick={() => { navigate('/online-pcbo-fillApplication') }} className="btn btn-primary">Back</button>
        </div>
      </div>
    </div>
  )
}

export default Step2



