import React, { useState, useEffect } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import UpperNavbar from './UpperNavbar';
import { NavLink } from 'react-router-dom';
import { MDBIcon } from 'mdb-react-ui-kit'
import aicee2023logo from '../../Images/aicee2023logo1.png'
import { useNavigate } from 'react-router-dom'
function SideBar() {

  const [studentData, setStudentData] = useState({});
  const navigate = useNavigate();
  const authirazation = async () => {
    try {
      const res = await fetch("/authorization-student", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await res.json();
      // console.log(data)
      setStudentData(data);

      if (!res.status === 401) {
        const error = new Error(res.server);
        throw error;
      }
    } catch (error) {
      // console.log("unouthorized")
      navigate("/");
    }
  };
  // console.log(studentData)
  useEffect(() => {
    authirazation();
  }, []);

  return (

    <CDBSidebar className='paymentPage' textColor="#fff" style={{ backgroundColor: '#042e2e', width: '5rem' }}>
      <CDBSidebarHeader style={{ padding: "", textDecoration: 'none',textAlign:'center'
      // ,marginTop:'-5rem' 
    
    }} prefix={<i style={{margin:'auto',marginLeft:'-13rem'}}  className="fa fa-bars fa-large "></i>} ></CDBSidebarHeader>
      {/* <CDBSidebarContent className=""> */}
      <CDBSidebarMenu>
        <NavLink exact to="/student-dashboard" activeClassName="activeClicked">
          <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
        </NavLink>
        {/* <NavLink exact to="/all-application" activeClassName="activeClicked">
          <CDBSidebarMenuItem style={{ marginTop: '-0.8rem' }} icon="edit">All Application</CDBSidebarMenuItem>
        </NavLink> */}
        
        <NavLink exact to="/my-profile" activeClassName="activeClicked">
          <CDBSidebarMenuItem style={{ marginTop: '-0.8rem' }} icon="user-alt">My Profile</CDBSidebarMenuItem>
        </NavLink>
        <NavLink exact to="/student-dashboard" activeClassName="activeClicked">
          <CDBSidebarMenuItem style={{ marginTop: '-0.8rem' }} icon="comment">My Queries</CDBSidebarMenuItem>
        </NavLink>
        <NavLink exact to="/my-payments" activeClassName="activeClicked">
          <CDBSidebarMenuItem style={{ marginTop: '-0.8rem' }} icon="credit-card">My Payments</CDBSidebarMenuItem>
        </NavLink>
        
            {/* <NavLink exact to="/schedule-you-exam" activeClassName="activeClicked">
              <CDBSidebarMenuItem style={{ marginTop: '-0.8rem' }} icon="pen-fancy">Change Exam Scheduled</CDBSidebarMenuItem>
            </NavLink> */}
          
        <NavLink exact to="/contact-us" activeClassName="activeClicked">
          <CDBSidebarMenuItem style={{ marginTop: '-0.8rem' }} icon="phone-square">Contact Us</CDBSidebarMenuItem>
        </NavLink>





      </CDBSidebarMenu>
      {/* </CDBSidebarContent> */}

      <CDBSidebarFooter style={{ textAlign: 'center' }}>
        <div
          style={{
            padding: '20px 5px',
          }}
        >
          {/* Sidebar Footer */}
          {/* <div>
              <MDBIcon fas icon="sign-out-alt" /> &nbsp; Logout
              </div> */}
        </div>
      </CDBSidebarFooter>
    </CDBSidebar>



  )
}

export default SideBar