import React from 'react'

function Contact() {
    return (
        <div className="body flex-grow-1 px-3 paymentPage">
            <div style={{ width: '100%' }} className="studentDashboardContainer">
                <span style={{ fontSize: '2rem', fontWeight: '400' }}>Contact Information!</span><br />

                <span style={{ fontSize: '1rem', fontWeight: '400' }}>For any query regarding the application submission . You can contact us below</span>
                <hr />
                <span style={{ fontSize: '1rem', fontWeight: '400' }}>E-mail :- info@aicee.in , aicee.info@gmail.com
</span><br />
                <span style={{ fontSize: '1rem', fontWeight: '400' }}>Phone No :- 7488661870</span><br />
                <br />
                <span style={{ fontSize: '1rem', fontWeight: '400' }}>(Mon - Sat :- 10:00AM to 06:00 PM)</span>
            </div>
        </div>
    )
}

export default Contact