import React from 'react'
import Sidebar from '../../Components/collegePortal/Sidebar'
import Box from '@mui/material/Box';
import Navbar from '../../Components/collegePortal/Navbar';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import StudentDetails from '../../Components/collegePortal/StudentDetails';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Home = () => {


    // modal control
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    return (
        <div className='bg-[#f7f7fc]' style={{overflow:'auto',position:'relative'}}>
            <Navbar />
            <Box height={80} />
            <Box sx={{ display: 'flex' }}>
                <Sidebar />
                <HomeStyled className='' style={{overflow:'scroll'}}>

                    <center className='headerName'>Student Allotment Details</center>

                    {/* // table start */}
                    <div className='table-responsive' style={{height:"70vh"}}>


                        <table class="table caption-top ">
                           
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th scope="col">Sl No</th>
                                    <th scope="col">Application No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Course</th>
                                    <th scope="col">Dues</th>
                                    <th scope="col">Email ID</th>
                                    <th scope="col">Phone No</th>
                                    <th scope="col" >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th scope="row">1</th>
                                    <td>2023148456</td>
                                    <td>Testing</td>
                                    <td>Engineering</td>
                                    <td>25000</td>
                                    <td>testing@gmail.com</td>
                                    <td>9132323232</td>
                                    <td><Button variant="contained" style={{ backgroundColor: '#3d3939' }} onClick={handleClickOpen}>View Details</Button></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    {/* // table end */}

                </HomeStyled>
            </Box>

            <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <StudentDetails handleClose={handleClose}/>
      </Dialog>
        </div>
    )
}


const HomeStyled = styled.main`
    /* *{
        overflow: auto;
        position: relative;
    } */
    .headerName{
        font-size:25px;
        text-align: center;
        color:#3d3939;
        font-weight: bolder;
        width:200vh;
        padding: 5px;
        overflow: hidden;
    }
    @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .headerName{
        font-size:25px;
        text-align: center;
        color:#3d3939;
        font-weight: bolder;
        width:80vh;
        padding: 5px;
    }
}


`
export default Home