import React,{useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
function Logout() {
    const navigate = useNavigate()
    const handleLogout = async () => {
        try {
            const res = await fetch('/users-logouts',
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    credentials: "include"
                }
            )
                .then((res) => {
                    navigate('/', { replace: true })
                    if (res.status != 200) {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
        } catch (error) {
            console.log(error)

        }
    }
    useEffect(() => {
        handleLogout()
    }, [])
  return (
    <div>
        
    </div>
  )
}

export default Logout