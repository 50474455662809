import React from 'react'
import {MDBIcon} from 'mdb-react-ui-kit'
function ApplicationStep() {
  return (
    <div className='' style={{marginTop:'-4rem'}}>
        {/* <div className='coll_step'  style={{color:'black'}}>Step 1 <br/><span style={{color:'#241d75'}}><MDBIcon fas icon="users" /></span><p style={{marginTop:'-0.2rem'}}>New user Registration</p></div><span><i class="fas fa-long-arrow-alt-right"></i></span>
        <div className='coll_step'  style={{color:'black'}}>Step 2 <br/><span style={{color:'#241d75'}}><MDBIcon fas icon="key" /></span><p style={{marginTop:'-0.2rem'}}>Login for Registered users</p></div><span><i class="fas fa-long-arrow-alt-right"></i></span>
        <div className='coll_step'  style={{color:'black'}}>Step 3 <br/><span style={{color:'#241d75'}}><MDBIcon fas icon="edit" /></span><p style={{marginTop:'-0.2rem'}}>Filling of online Application</p></div><span><i class="fas fa-long-arrow-alt-right"></i></span>
        <div className='coll_step'  style={{color:'black'}}>Step 4 <br/><span style={{color:'#241d75'}}><MDBIcon far icon="credit-card" /></span><p style={{marginTop:'-0.2rem'}}>Payment</p></div><span><i class="fas fa-long-arrow-alt-right"></i></span>
        <div className='coll_step' style={{color:'black'}} >Step 5 <br/><span style={{color:'#241d75'}}><MDBIcon far icon="calendar-alt" /></span><p style={{marginTop:'-0.2rem'}}>Schedule your Exam</p></div><span><i class="fas fa-long-arrow-alt-right"></i></span>
        <div className='coll_step'  style={{color:'black'}}>Step 6 <br/><span style={{color:'#241d75'}}><MDBIcon fas icon="home" /></span><p style={{marginTop:'-0.2rem'}}>Take Online Exam</p></div> */}
      <img className='fluid' src="https://ucarecdn.com/f19fa0b1-787c-4544-bf6a-40cbf8a5fa28/Screenshot8.png" alt="" />
    </div>
  )
}

export default ApplicationStep