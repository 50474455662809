import React, { useEffect, useRef, useState } from "react";
import {MDBIcon} from 'mdb-react-ui-kit'
// import "./Accordion.css";

function Accordion(props) {
    const [active, setActive] = useState(false);
    const content = useRef(null);
    const [height, setHeight] = useState("0px");

    useEffect(() => {
        // console.log("Height for ", props.title, ": ", height);
    }, [height]);

    function toggleAccordion() {
        setActive(!active);
        setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    }

    return (
        <div className="accordion__section">
            <div
                className={`aaccordion ${active ? "active" : ""}`}
                onClick={toggleAccordion}
            >
                <p className="accordion__title">{props.title}</p>
                <span style={{ marginLeft: "20px" }}>{active ? <MDBIcon style={{color:'red'}} fas icon="minus-circle" /> : <MDBIcon style={{color:'green'}} fas icon="plus-circle" />}</span>
            </div>
            <div
                ref={content}
                style={{ maxHeight: `${height}` }}
                className="accordion__content"
            >
                <div
                    className="accordion__text"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                />
            </div>
        </div>
    );
}

export default Accordion;
