import React from "react";
import Accordion from "./Accordion";
// import "./styles.css";

export default function ApplicationStages() {
  return (
    <div className="App ">
      
      <div>
        <Accordion className='rightSidebarheaderreg' title="Step 1 - New user Registration." content="
        
        <ul>
              <li>Valid e-mail address and active mobile number.</li>
              <li>Enter all the relevant details carefully.</li>
              <li>An OTP will be sent to your Registered Indian
                Mobile Number for verification.</li>
              <li>Once OTP is validated, a password will be sent to
                your registered mobile number.</li>
              <li>All the correspondence shall be made to the
                registered email address and mobile number only.</li>
            </ul>
        
        " />
        <Accordion
          title="Step 2 - Sign in for Registered users."
          content="
          <ul
          >
          <li>Login with the registered email address and the
            password received from AICEE.</li>
        </ul>
          "
        />
        <Accordion
          title="Step 3 - Filling of Online application form."
          content=" 
          <ul
              >
              <li>Fill in the relevant fields carefully.</li>
              <li>An Application Number will be generated after
                filling all details.</li>
              <li>Refer this application number in all your future
                correspondence.</li>
            </ul>
          "
        />
        <Accordion
          title="Step 4 - Schedule your Exam."
          content="
          <ul
              >
              <li> Schedule your Examination In Home Based Examination Mode or Centre Based Examination Mode.</li>
              <li>Select Your Examination Date and Slot for Home Based Examination Mode.</li>
              <li>Select Your Examination City for Centre Based Examination Mode.</li>
            
              </ul>
          "
        />
        <Accordion
          title="Step 5 - Pay Application Fee."
          content=" 
          <ul
              >
              <li>Pay Application Fee via UPI, Net Banking , Credit/ Debit Card or
                Paytm Account.</li>
              <li>Online Appliation Fee : Rs.1300</li>

              <li>Application cost is non-refundable</li>
            </ul>
          "
        />
        
        <Accordion
          title="Step 6 - Take the Exam from the Comfort of your Home"
          content=" 
          <ul
          >
          <li>Download and save the filled-in application for
            your reference and further correspondence.</li>
          <li>Applicants NEED NOT TO SEND hard copy of the filled-in
            Application Form to AICEE.</li>
        </ul>
          "
        />
      </div>
    </div>
  );
}
