import React from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
const AddProgramme = (props) => {
    return (
        <div><DialogTitle id="responsive-dialog-title">
            {props.heading}
        </DialogTitle>
            <DialogContent>
                <div className='mt-3'><form className="row g-3">
                    <div className="col-md-4">
                        <label htmlFor="inputEmail4" className="form-label">
                            Programme
                        </label>
                        <input type="email" className="form-control" id="inputEmail4" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputEmail4" className="form-label">
                            Branch
                        </label>
                        <input type="email" className="form-control" id="inputEmail4" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputEmail4" className="form-label">
                            Total Seat
                        </label>
                        <input type="email" className="form-control" id="inputEmail4" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputEmail4" className="form-label">
                        First Year Fee
                        </label>
                        <input type="email" className="form-control" id="inputEmail4" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputEmail4" className="form-label">
                        Total Program Fee
                        </label>
                        <input type="email" className="form-control" id="inputEmail4" />
                    </div>
                </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" style={{ backgroundColor: 'red' }} onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" style={{ backgroundColor: 'green' }} autoFocus>
                    Submit
                </Button>
            </DialogActions></div>
    )
}

export default AddProgramme