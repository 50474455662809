import React from 'react'
import Header from '../Pages/LandingPage/Header'
// import '../Pages/LandingPage/css/bootstrap.min.css'
// import '../Pages/LandingPage/css/font-awesome.min.css'
import '../Pages/LandingPage/css/menudropsub.css'
import '../Pages/LandingPage/css/opensans.css'
import '../Pages/LandingPage/css/opensans.css'
import '../Pages/LandingPage/css/own.css'
import '../Pages/LandingPage/css/update.css'
import Marque from '../Pages/LandingPage/Marque'
import Home from '../Pages/LandingPage/Home'
import Footer from '../Pages/Registration/Footer'
function Landing() {
  return (
    <div>
      <Header />
      <div>
        <Marque />
      </div>
      <div>
        <Home />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Landing